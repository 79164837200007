/* eslint-disable react/display-name */
import React, { Suspense, lazy } from 'react';

import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import FullPageLoadingScreen from 'components/FullPageLoadingScreen/FullPageLoadingScreen';
import Layout from 'components/Layout/Layout';
import { useFeatureFlag } from 'feature-flags';
import RouteWithContext from 'routes/components/RouteWithContext';
import ScrollToTop from 'routes/components/ScrollToTop';
import RoutesWithLayout from 'routes/RoutesWithLayout';
import { AnalyticsWrapper } from 'tracking';
import { retry } from 'utils';

import { withErrorBoundary } from './components/Error';
import {
  SubscriptionContextProvider,
  useCalculateSubscription,
} from './components/Subscription';
import { Insights, Intercom } from './components/Widgets';
import useInitializeNotificationService from './services/OneSignal/useInitializeNotificationService';
import { useAppSelector } from './store/createStore';
import { selectClientInfo, selectUserInfo } from './store/features/mainSlice';

const ModuleQuiz = lazy(() =>
  retry(() => import('pages/Quizzes/Module/Module')),
);
const LandingPage = lazy(() =>
  retry(() => import('./pages/LandingPage/LandingPage')),
);

const RiskRooms = lazy(() =>
  retry(() => import('./pages/Quizzes/RiskRooms/RiskRooms')),
);
const Login = lazy(() => retry(() => import('./pages/Login/Login')));
const PassphraseIntro = lazy(() =>
  retry(() => import('pages/PassphraseIntro/PassphraseIntro')),
);
const PassphraseFeedback = lazy(() =>
  retry(() => import('pages/PassphraseIntro/PassphraseFeedback')),
);
const Intro = lazy(() =>
  retry(() => import('pages/Learning/Course/InitialAssessment/Intro/Intro')),
);
const CultureSurveyPage = lazy(() =>
  retry(() => import('pages/CultureSurvey/CultureSurveyPage')),
);
const CompanyFeedback = lazy(() =>
  retry(() => import('pages/CultureSurvey/CompanyFeedback')),
);

const DebriefPage = lazy(() =>
  retry(() => import('pages/CultureSurvey/DebriefPage')),
);
const SurveyThankYou = lazy(() =>
  retry(() => import('pages/CultureSurvey/ThankyouPage')),
);
const IAQuiz = lazy(() =>
  retry(() => import('pages/Quizzes/InitialAssessment/InitialAssessment')),
);
const AppRedirect = lazy(() =>
  retry(() => import('routes/components/AppRedirect')),
);
const ModulePage = lazy(() =>
  retry(() => import('pages/Learning/Course/Module/Module')),
);
const EULA = lazy(() =>
  retry(() => import('pages/EndUserLicenseAgreement/EndUserLicenseAgreement')),
);
const AppRedirectLoginComplete = lazy(() =>
  retry(() => import('routes/components/AppRedirectLoginComplete')),
);
const AppWebview = lazy(() =>
  retry(() => import('routes/components/AppWebview')),
);

const ScormTest = lazy(() => retry(() => import('pages/ScormTest')));

const publicRoutes = [
  {
    path: '/login/*',
    component: Login,
  },
  {
    path: '/usb-message/*',
    component: LandingPage,
  },
  {
    path: '/phishing-message/*',
    component: LandingPage,
  },
  {
    path: '/phishtray/*',
    component: LandingPage,
  },
  {
    path: '/risk-rooms/:riskRoomId',
    component: RiskRooms,
  },
  {
    path: '/passphrase-intro/:resetToken/:resetDate',
    component: PassphraseIntro,
    title: 'Set Passphrase',
  },
  {
    path: '/scorm-test',
    component: ScormTest,
    title: 'LMS Test',
  },
];

const privateRoutes = [
  {
    path: '/questionnaire',
    component: Intro,
    title: 'label_titleIntro',
  },
  {
    path: '/survey/company-feedback',
    component: CompanyFeedback,
    title: 'label_titleCompanyFeedback',
  },
  {
    path: '/survey/:surveySlug',
    component: CultureSurveyPage,
    title: 'label_titleCultureSurvey',
  },
  {
    path: '/survey-debrief',
    component: DebriefPage,
    title: 'label_titleCultureSurveyDebrief',
  },
  {
    path: '/survey-thankyou',
    component: SurveyThankYou,
    title: 'label_titleCultureSurveyDebrief',
  },
  {
    path: '/passphrase-intro/feedback',
    component: PassphraseFeedback,
    title: 'label_titlePassphraseFeedback',
  },
  {
    path: '/passphrase-intro',
    component: PassphraseIntro,
    title: 'label_titlePassphraseIntro',
  },
  {
    path: '/quiz/ia-knowledge-questions',
    component: IAQuiz,
    title: 'label_titleInitialAssessment',
  },
  {
    path: '/module/:module',
    component: (props) => (
      <Layout withUserMenu withTopMenu {...props}>
        <ModulePage />
      </Layout>
    ),
    title: 'label_titleModule',
  },
  {
    path: '/module/:module/:section',
    component: (props) => (
      <Layout withUserMenu withTopMenu {...props}>
        <ModulePage />
      </Layout>
    ),
    title: 'label_titleModule',
  },
  {
    path: '/module-scorm/:module',
    component: (props) => (
      <Layout noHeader {...props}>
        <ModulePage scorm />
      </Layout>
    ),
    title: 'label_titleModule',
  },
  {
    path: '/module-scorm/:module/:section',
    component: (props) => (
      <Layout noHeader {...props}>
        <ModulePage scorm />
      </Layout>
    ),
    title: 'label_titleModule',
  },
  {
    path: '/quiz-scorm/:module',
    component: (props) => (
      <Layout noHeader {...props}>
        <ModuleQuiz scorm />
      </Layout>
    ),
    title: 'label_titleQuiz',
  },
  {
    path: '/quiz-scorm/:module/:questionIndex/*',
    component: (props) => (
      <Layout noHeader {...props}>
        <ModuleQuiz scorm />
      </Layout>
    ),
    title: 'label_titleQuiz',
  },
  {
    path: '/eula',
    component: EULA,
    title: 'label_titleEULA',
  },
  {
    path: '/app-redirect-login',
    component: AppRedirectLoginComplete,
  },
  {
    path: '/app-webview',
    component: AppWebview,
  },
];

const MainAppWrapper = () => {
  /**
   * Public routes contain all public content, private and php routes
   * will auto log out any unauthenticated user to prevent access.
   *
   * This is done inside the RouteWithContext component which is also
   * done for the routes in RoutesWithLayout.
   */
  const useImprovedDelegatedAccess = useFeatureFlag(
    'use-improved-delegated-access',
  );

  const featureFlagProps = {
    useImprovedDelegatedAccess,
  };

  const location = useLocation();
  const clientInfo = useAppSelector(selectClientInfo);
  const { isAdmin } = useAppSelector(selectUserInfo);
  const isAdvicePage = location.pathname.includes('/learning/advice');
  const isLoginPage = location.pathname === '/login';
  const subscriptionRecord = useCalculateSubscription();

  return (
    <SubscriptionContextProvider {...subscriptionRecord}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={React.createElement(route.component, featureFlagProps)}
          />
        ))}
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RouteWithContext title={route.title}>
                {React.createElement(route.component, featureFlagProps)}
              </RouteWithContext>
            }
          />
        ))}
        <Route path="/*" element={<RoutesWithLayout />} />
      </Routes>
      {(isAdmin || isLoginPage) && <Intercom />}
      {/* Not showing on AdvicePage as there is already a modified intercom component being rendered there. */}
      {clientInfo.isInsightsOn && !isAdmin && !isAdvicePage && <Insights />}
    </SubscriptionContextProvider>
  );
};

const MainRouter = () => {
  useInitializeNotificationService();
  return (
    <ScrollToTop>
      <AnalyticsWrapper>
        <Suspense
          fallback={
            <FullPageLoadingScreen description="loadingSpinner.loadingPage" />
          }
        >
          <Routes>
            <Route path="/app-redirect/*" element={<AppRedirect />} />
            <Route path="/*" element={<MainAppWrapper />} />
          </Routes>
        </Suspense>
      </AnalyticsWrapper>
    </ScrollToTop>
  );
};

export default withErrorBoundary(MainRouter);
