import React, { useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl';

import Button, { ButtonType } from 'components/Button';
import OverflowMenu from 'components/OverflowMenu';
import { MenuItem } from 'pages/Admin/People/People.styles';

import Translation from '../../../Content/Translation/Translation';
import { HEADER_HEIGHT_VALUE } from '../../../Layout/Header/Header.styles';
import {
  ActionItems,
  BatchActionsContainer,
  ButtonActions,
  MenuActions,
} from '../CybDataTable.styles';

export type ActionType = 'menu' | 'button';

export type BatchActionsInterface = {
  buttonType: ButtonType;
  renderIcon?: React.ElementType;
  disabled?: boolean;
  id: string;
  type: ActionType;
  label: string;
  onClick: () => void;
};

export interface BatchActionsProps {
  selectedCount?: number;
  selectedLabel?: string;
  batchActions: BatchActionsInterface[];
  allSelectedCount: number;
}

const BatchActions = ({
  selectedCount,
  selectedLabel = 'component.dataTable.batchActions.selected',
  batchActions,
  allSelectedCount,
}: BatchActionsProps) => {
  const ref = useRef(null);
  const intl = useIntl();
  const [containerProps, setContainerProps] = useState({
    position: 'initial',
    width: null,
  });

  const handleScroll = () => {
    if (ref.current) {
      /* If component reaches the header element */
      if (ref.current.getBoundingClientRect().top < HEADER_HEIGHT_VALUE) {
        const parentWidth = ref.current.clientWidth;
        setContainerProps((prevState) =>
          prevState.position === 'fixed'
            ? prevState
            : { position: 'fixed', width: parentWidth },
        );
      } else {
        setContainerProps((prevState) =>
          prevState.position === 'initial'
            ? prevState
            : { position: 'initial', width: null },
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const buttonActions = batchActions.filter(
    (action) => action.type === 'button',
  );
  const menuActions = batchActions.filter((action) => action.type === 'menu');
  const count = allSelectedCount || selectedCount;
  return count > 0 ? (
    <BatchActionsContainer ref={ref} $containerProps={containerProps}>
      <div data-testid="batch-actions">
        <div>
          <Translation id={selectedLabel} values={{ selectedCount: count }} />
        </div>
        <ActionItems>
          {buttonActions.length > 0 && (
            <ButtonActions>
              {buttonActions.map((action) => (
                <Button
                  key={action.id}
                  {...action}
                  type="button"
                  buttonType={action.buttonType}
                  onClick={() => action.onClick()}
                />
              ))}
            </ButtonActions>
          )}
          {menuActions.length > 0 && (
            <MenuActions>
              <OverflowMenu flipped>
                {menuActions.map((action) => (
                  <MenuItem
                    key={action.id}
                    id={action.id}
                    itemText={intl.formatMessage({ id: action.label })}
                    onClick={() => action.onClick()}
                    $isDanger={action.buttonType === 'danger' || false}
                  />
                ))}
              </OverflowMenu>
            </MenuActions>
          )}
        </ActionItems>
      </div>
    </BatchActionsContainer>
  ) : null;
};

export default BatchActions;
