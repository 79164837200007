import { useTheme } from 'styled-components';

import { SymposiumLevels } from 'store/features/mainSliceTypes';

export const useGetSymposiumColours = (level: SymposiumLevels) => {
  const theme = useTheme();
  switch (level) {
    case 'GUIDE':
    case 'GUIDE+':
      return {
        startColor: theme.colorPalette.ui.purple[600],
        endColor: theme.colorPalette.ui.purple[500],
      };
    case 'PHISH':
    case 'PHISH+':
      return {
        startColor: theme.colorPalette.ui.blue[800],
        midColor: theme.colorPalette.ui.cyan[900],
        endColor: theme.colorPalette.ui.cyan[500],
      };
    case 'RESPOND':
    case 'RESPOND+':
      return {
        startColor: theme.colorPalette.ui.green[800],
        endColor: theme.colorPalette.ui.green[700],
      };
    default:
      return {
        startColor: theme.colorPalette.ui.orange[600],
        endColor: theme.colorPalette.ui.orange[500],
      };
  }
};
