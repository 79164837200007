import styled from 'styled-components';

import { unit } from 'theme';

import { HEADER_HEIGHT } from '../../Layout/Header/Header.styles';

export const ACTIONS_TOOLBAR_HEIGHT = '4rem';

export const StyledNoData = styled.div`
  text-align: center;
  display: inline-block;
  padding: 8px;
  color: ${({ theme }) => theme.componentColors.bodyTextColor};
  font-size: ${({ theme }) => theme.fontSizes.m};
  h3 {
    font-weight: 600;
  }
  img {
    width: ${unit(23)};
  }
`;

export const StyledCybDataTable = styled.div`
  > div[role='heading'] {
    min-height: ${ACTIONS_TOOLBAR_HEIGHT};
    overflow: initial;
    > div:first-of-type {
      flex: none;
    }
  }
  /* Prevent truncated text on small devices for accessibility reasons */
  @media (max-width: 768px) {
    div[role='columnheader'] {
      word-break: break-word;
      > div {
        white-space: normal;
      }
  }
`;

export const ToolBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: ${ACTIONS_TOOLBAR_HEIGHT};
  width: 100%;
  margin-left: 0;
  /* Prevents table header overlapping button outline */
  margin-bottom: 2px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${unit(1)};
  flex: 0 0 auto;
  margin-left: 4px;
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) =>
      theme.componentColors.table.search.hoverColor} !important;
  }
  svg {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 1rem;
    width: 1rem;
    height: 1rem;
    fill: ${({ theme }) => theme.colorPalette.grey[800]};
    pointer-events: none;
    transform: translateY(-50%);
  }
  input {
    background-color: transparent !important;
    padding: 0 3rem;
    border: none;
    height: 3rem;
  }
  button {
    position: absolute;
    right: 0;
    width: 3rem;
    height: 3rem;
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }
`;

export const BatchActionsContainer = styled.div<{
  $containerProps: { position: string; width: number | null };
}>`
  width: 100%;
  height: ${ACTIONS_TOOLBAR_HEIGHT};
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${({ $containerProps }) =>
      $containerProps.width ? `${$containerProps.width}px` : '100%'};
    position: ${({ $containerProps }) => $containerProps.position};
    z-index: 2;
    top: ${HEADER_HEIGHT};
    border: 2px solid
      ${({ theme }) => theme.componentColors.table.batchActions.borderColor};
    background-color: ${({ theme }) =>
      theme.componentColors.table.batchActions.backgroundColor};
    padding: ${unit(1)} ${unit(2)};
  }
`;

export const ActionItems = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonActions = styled.div`
  > button {
    margin-right: ${unit(1)};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const MenuActions = styled.div`
  margin-left: 4px;
`;
