import { useMemo } from 'react';

import { useApiData } from 'api';
import { useAppSelector } from 'store/createStore';
import { selectClientName } from 'store/features/mainSlice';

import { GroupItem } from '../../Dropdowns.types';

type GroupsData = {
  id: number;
  displayName: string;
  children: GroupsData[];
};

type GroupsReturn = {
  items: GroupItem[];
  loading?: boolean;
  error?: string;
};

const getItems = (groups: GroupsData[], clientName?: string): GroupItem[] => {
  const items: GroupItem[] = clientName
    ? [{ id: 'root', label: clientName, path: [clientName] }]
    : [];
  const createGroupList = (newList, currentGroupsList, path) => {
    currentGroupsList.forEach((group) => {
      const updatedPath = [...path, group.displayName];
      const groupData = {
        id: group.id,
        label: group.displayName,
        path: updatedPath,
      };
      newList.push(groupData);
      if (group.children?.length > 0) {
        createGroupList(newList, group.children, updatedPath);
      }
    });
    return newList;
  };

  return createGroupList(items, groups, []);
};

const useGroupsDropdownItems = (withClientAsRoot?: boolean): GroupsReturn => {
  const clientName: string = useAppSelector(selectClientName);
  const { data, loading, error } = useApiData<GroupsData[]>(
    '/api/v1/categories/r1/?fields=id,display_name,children',
    {
      cache: true,
    },
  );
  const items = useMemo(() => {
    if (data) {
      return getItems(data, withClientAsRoot ? clientName : null);
    }
    return [];
  }, [data, clientName, withClientAsRoot]);
  return { items, loading, error };
};

export default useGroupsDropdownItems;
