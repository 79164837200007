import { TextInput } from '@carbon/react';
import styled from 'styled-components';

export const Input = styled(TextInput)`
  box-shadow: none !important;
  min-width: unset !important;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colorPalette.primary[300]} !important;
    box-shadow: none !important;
  }

  .cds--label {
    color: blue;
  }
`;
