import React from 'react';

const PhishIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1601_240)">
      <path d="M8.20053 13.2329C8.76497 10.17 10.1429 6.78335 13.1228 4.32639C16.1027 1.86942 19.6802 1.15558 22.7929 1.18878C25.9056 1.22198 28.7361 1.99393 30.4626 2.74098C31.0353 2.99 31.4587 3.50463 31.5998 4.11887C31.9982 5.9533 32.2223 8.87509 31.6579 11.938C31.1017 15.0092 29.7156 18.3958 26.744 20.8528C23.7641 23.3097 20.1782 24.0153 17.0655 23.9821C15.7706 23.9655 14.5339 23.8244 13.3967 23.6086L11.2053 29.5352C10.8982 30.3652 10.0765 30.8798 9.1966 30.8051C8.31674 30.7304 7.61119 30.0664 7.46178 29.1948L6.50722 23.6584L1.25297 21.6745C0.431215 21.3591 -0.0834192 20.5374 0.00788686 19.6575C0.0991929 18.7776 0.763237 18.0721 1.63479 17.931L7.86851 16.91C7.86851 15.7562 7.97642 14.5029 8.20053 13.2329ZM25.1918 8.25255C24.5028 7.42249 23.2826 7.30628 22.4526 7.98693C21.6225 8.66758 21.5063 9.89606 22.187 10.7261C22.8759 11.5562 24.0961 11.6724 24.9261 10.9917C25.7562 10.3028 25.8724 9.0826 25.1918 8.25255Z" />
    </g>
    <defs>
      <clipPath id="clip0_1601_240">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhishIcon;
