import * as Sentry from '@sentry/browser';

import createSentryMiddleware from 'sentryMiddleware';

const sentry = () => {
  const serverData = (window as any).__data__ || {};
  if (serverData.config) {
    Sentry.init({
      dsn: serverData.config.sentry,
      integrations: [
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration({
          blockAllMedia: true,
        }),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      sendDefaultPii: true,
      ignoreErrors: [
        'Network Error',
        'Not enough storage is available to complete this operation.',
        'Object Not Found Matching Id',
        'ResizeObserver loop limit exceeded',
        'Failed to fetch dynamically imported module',
        'ResizeObserver loop completed with undelivered notifications',
        'BadRequestError',
        'NetworkError',
        'NotFoundError',
        'OfflineError',
        'Failed to fetch',
        'network error',
        'cancelled',
        'Load failed',
      ],
      normalizeDepth: 10,
      beforeSend(event) {
        let firstFilename =
          event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename;
        const functionCheck =
          event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.function;
        if (functionCheck === 'sentryWrapped') {
          firstFilename =
            event.exception?.values?.[0]?.stacktrace?.frames?.[1]?.filename;
        }
        // Ignore errors from third party scripts such as onetrust
        // Check if firstFileName is not null and type of string
        if (firstFilename && typeof firstFilename === 'string') {
          if (
            firstFilename.includes('onetrust.com') ||
            firstFilename.includes('otSDKStub') ||
            firstFilename.includes('otBannerSdk')
          ) {
            return null;
          }
        }
        return event;
      },
    });
  }
  return createSentryMiddleware(Sentry);
};

export default sentry;
