import React from 'react';

import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { useApiData, useApiPost } from 'api';
import Button from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { FallbackErrorComponent } from 'components/Error';
import { FormButtonBlock } from 'components/Form/Form.styles';
import { PageHeader } from 'components/Headers';
import { InlineNotification } from 'components/Notifications';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import { Wrapper } from 'components/Wrappers';

import AddExistingGroupTable from './components/AddExistingGroupTable';
import {
  AddExistingGroupContainer,
  ContentContainer,
} from './components/GroupManagement.styles';
import { TabOrder } from './components/GroupOverview';
import { Group } from './GroupManagement.types';

export default function AddExistingGroups() {
  const { groupId } = useParams<'groupId'>();
  const intl = useIntl();
  const navigate = useNavigate();
  const [convertToSubGroups, { error: convertToSubGroupsError }] = useApiPost(
    `/api/v1/categories/r1/${groupId}/convert-to-subgroups/`,
  );

  const [selectedGroups, setSelectedGroups] = React.useState<Array<Group>>([]);
  const { data, loading, error } = useApiData(
    `/api/v1/categories/r1/${groupId}/`,
  );
  const convertToArrayOfIds = (group: Group) => {
    return group.id;
  };

  const onSave = async (arrayOfIds: Array<Group>) => {
    await convertToSubGroups({
      subgroups: arrayOfIds.map(convertToArrayOfIds),
    });
    await navigate(
      `/settings/group-management/${groupId}?selected=${
        TabOrder.SubGroups
      }&successMessage=${intl.formatMessage({
        id: 'page.groupManagement.subgroup.add.existing.submit.success',
      })}`,
    );
  };

  const onCancel = () => {
    navigate(
      `/settings/group-management/${groupId}?selected=${TabOrder.SubGroups}`,
    );
  };

  const groupLoading = loading || !data;
  if (groupLoading) return <LoaderBlock />;
  if (error) {
    return <FallbackErrorComponent error={error} />;
  }
  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            label: 'label_groupManagement',
            backTo: '/settings/group-management',
          },
          {
            label: data.displayName,
            dynamicLabel: true,
            backTo: `/settings/group-management/${groupId}`,
          },
          {
            label:
              'page.groupManagement.subgroup.add.existing.breadcrumb.title',
            backTo: null,
          },
        ]}
      />
      <Wrapper
        id="create-sub-groups-section"
        title={
          <Translation
            id="page.groupManagement.subgroup.add.existing.title"
            values={{ groupName: data.displayName }}
          />
        }
        dynamicTitle
      >
        <AddExistingGroupContainer>
          <ContentContainer>
            <Translation
              id="page.groupManagement.subgroup.add.existing.description"
              values={{ displayName: data.displayName }}
            />
          </ContentContainer>
          {convertToSubGroupsError && (
            <InlineNotification
              type="error"
              data-testid="convert-to-subgroups-error"
              titleLabel={intl.formatMessage({
                id: 'page.createNotification.errorNotification.title',
              })}
              subtitleLabel={intl.formatMessage({
                id: 'page.groupManagement.subgroup.add.existing.submit.error',
              })}
            />
          )}
          <AddExistingGroupTable
            groupId={groupId}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
          />
        </AddExistingGroupContainer>
        <FormButtonBlock>
          <Button buttonType="danger--secondary" onClick={onCancel}>
            <Translation id="label_cancel" />
          </Button>
          <Button
            id="submit-button"
            onClick={() => onSave(selectedGroups)}
            disabled={selectedGroups.length === 0}
          >
            <Translation id="page.groupManagement.subgroup.add.existing.submit.title" />
          </Button>
        </FormButtonBlock>
      </Wrapper>
    </>
  );
}
