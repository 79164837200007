import { v4 as uuidv4 } from 'uuid';

import { Group } from './GroupManagement.types';
import { GroupsTypes } from './GroupManagementTable';

export const DOTS = '....';

export const flatData = (
  groupData: Array<Group>,
  flatGroupObject: Record<string, Group>,
) => {
  const groups: Array<Group> = [];
  const addGroup = (data: Array<Group>, isChildren: boolean) => {
    data.forEach((group, index) => {
      // only add group when it's part of the first level parent groups children list
      if (flatGroupObject[group.id] !== undefined) {
        if (isChildren && index === 2) {
          groups.push(group);
          groups.push({
            id: uuidv4(),
            displayName: DOTS,
            clientId: group.clientId,
            managedByAdSync: false,
            usersCount: null,
            isProtected: false,
            creationMethod: undefined,
            parentId: group.parentId,
            children: [],
          });
        } else {
          groups.push(group);
        }
      }
      if (group.children && group.children.length > 0) {
        addGroup(group.children, true);
      }
    });
  };

  addGroup(groupData, false);
  return groups;
};

export const GroupsTypeConfig: Record<GroupsTypes, { text: string }> = {
  all: {
    text: 'page.groupManagement.table.group.type.filter.all',
  },
  sso: {
    text: 'page.groupManagement.table.group.type.filter.sso',
  },
  manual: {
    text: 'page.groupManagement.table.group.type.filter.manual',
  },
  scim: {
    text: 'page.groupManagement.table.group.type.filter.sync',
  },
};

export const isGroupsType = (value: string): value is GroupsTypes => {
  return ['all', 'manual', 'sso', 'scim'].includes(value);
};
