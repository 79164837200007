import styled from 'styled-components';

import { InputWrapper } from 'components/Form/Inputs/Inputs.styles';
import { unit } from 'theme';

import { Heading } from '../../Text';

export const PageHeaderRoot = styled.div<{ $hasNoSubscription?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${unit(3)};
  margin-top: ${({ $hasNoSubscription }) =>
    $hasNoSubscription ? unit(4) : '0'};
`;

export const TitleAndFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${unit(1)};
`;

export const FilterContainer = styled.div`
  background: transparent;
  display: flex;
  flex: 1;
  label {
    margin: ${unit(2)} ${unit(3)} ${unit(2)} 0;
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
  .cds--list-box {
    background: ${({ theme }) => theme.colorPalette.core.white};
    min-width: 500px;
  }
  ${InputWrapper} {
    width: auto;
  }
`;

const maxWidthMediaQuery = '@media only screen and (max-width: 700px)';

export const DeprecatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;

  ${maxWidthMediaQuery} {
    flex-direction: column;
    padding: 0;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.625rem;
  color: ${({ theme }) => theme.colorPalette.grey[500]};

  ${maxWidthMediaQuery} {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  margin-top: ${unit(2)};
`;

export const PageTitle = styled.h1<{ $hideTitle?: boolean }>`
  font-size: 1.625rem;
  font-weight: 600;
  color: ${({ theme }) => theme.componentColors.pageTitleColor};
  display: flex;
  ${({ $hideTitle }) =>
    $hideTitle &&
    `
    //Hide the title visually, but keep it accessible for screen readers
    font-size: 0;
    position: absolute;
    `}
`;

export const PageSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.componentColors.pageSubTitleColor};
`;

export const SkeletonDropdownContainer = styled.div`
  margin-right: ${unit(4)};
  margin-top: 25px;
  flex: 1 1 0;
  min-width: 0;
`;

export const DropdownContainer = styled.div`
  margin-right: ${unit(4)};
  flex: 1 1 0;
  min-width: 0;
`;

export const DemoLabelHeading = styled(Heading)`
  color: ${({ theme }) => theme.colorPalette.grey[700]};
`;

export const ActionLinkContainer = styled.div`
  display: flex;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: 600;
  svg {
    color: ${({ theme }) => theme.colorPalette.primary[300]};
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;
