import React, { useMemo } from 'react';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useApiPost } from 'api';
import Button from 'components/Button';
import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { Checkbox } from 'components/Form/Inputs';
import Label from 'components/Form/Inputs/Label/Label';
import {
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'components/Modal';
import { InlineNotification } from 'components/Notifications';
import { unit } from 'theme';

import {
  CheckboxWrapper,
  HeaderSubTitle,
  StyleInputText,
} from './CreateLinkModal.styles';

const initialValues: {
  name: string;
  url: string;
  addToFavourites: boolean;
} = {
  name: '',
  url: '',
  addToFavourites: true,
};

const CreateLinkModal = ({
  open,
  closeModal,
  refetch,
  setShowSuccessNotification,
  initialURLValue,
  addingFavourite,
}: {
  open: boolean;
  closeModal: () => void;
  refetch: () => void;
  setShowSuccessNotification?: (value: boolean) => void;
  initialURLValue?: string;
  addingFavourite?: boolean;
}) => {
  const intl = useIntl();
  const [
    postCreateReportLink,
    { loading: createLinkLoading, error: createLinkError },
  ] = useApiPost(`/api/v1/reports/report-link/r1/`);
  const [
    postCreateReportFavourite,
    { loading: favouriteLoading, error: favouriteError },
  ] = useApiPost(`/api/v1/reports/report-favourites/r1/`);

  if (initialURLValue) {
    initialValues.url = initialURLValue;
  }

  const headerText = useMemo(
    () =>
      addingFavourite ? (
        <Translation id="page.reports.allReports.addToFavourites" />
      ) : (
        <Translation id="page.reports.allReports.addNewReport" />
      ),
    [addingFavourite],
  );

  const subtitle = useMemo(
    () =>
      addingFavourite ? (
        <>
          <Translation id="page.reports.allReports.addToFavourites.subtitle" />{' '}
          <Link to="/reports/favourites">
            <Translation id="page.reports.allReports.seeYourFavourites" />
          </Link>
        </>
      ) : (
        <Translation id="page.reports.allReports.addNewReport.subtitle" />
      ),
    [addingFavourite],
  );

  return (
    <ComposedModal
      data-testid="create-report-link-modal"
      aria-labelledby="create-report-link-modal-header"
      open={open}
      onClose={closeModal}
      selectorPrimaryFocus="#name-text-input"
    >
      <ModalHeader id="create-report-link-modal-header" title={headerText}>
        <HeaderSubTitle>{subtitle}</HeaderSubTitle>
      </ModalHeader>
      <ModalBody hasForm>
        <FlexBox flexDirection="column" gap={unit(1)}>
          {createLinkError && (
            <InlineNotification
              type="error"
              titleLabel={intl.formatMessage({
                id: 'common.error',
              })}
              subtitleLabel={intl.formatMessage({
                id: 'page.reports.allReports.addNewReport.error.subtitle',
              })}
            />
          )}
          {favouriteError && (
            <InlineNotification
              type="error"
              titleLabel={intl.formatMessage({
                id: 'common.error',
              })}
              subtitleLabel={intl.formatMessage({
                id: 'page.reports.allReports.addToFavourites.error.subtitle',
              })}
            />
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              // As we accept a URL with or without protocol, we need to
              // add the protocol if it is missing
              if (!values.url.startsWith('http')) {
                values.url = `https://${values.url}`;
              }
              // format URL to be path only
              const url = new URL(values.url);

              const formValues = {
                description: values.name,
                pathname: url.pathname + url.search,
              };

              const response = await postCreateReportLink({ ...formValues });

              actions.setSubmitting(false);
              if (!createLinkLoading && !createLinkError) {
                if (values.addToFavourites) {
                  await postCreateReportFavourite({
                    report_link_id: response.id,
                  });
                }
                actions.resetForm();
                refetch();
                if (setShowSuccessNotification)
                  setShowSuccessNotification(true);
                closeModal();
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(
                  intl.formatMessage({ id: 'common.formError.fieldRequired' }),
                )
                .max(
                  255,
                  intl.formatMessage(
                    { id: 'common.formError.maxLength' },
                    { maxLength: 255 },
                  ),
                ),
              url: Yup.string()
                .required(
                  intl.formatMessage({ id: 'common.formError.fieldRequired' }),
                )
                .matches(
                  /^(https:\/\/)?([a-zA-Z0-9-]+\.)*cybsafe\.com(\/.*)?$/,
                  intl.formatMessage({
                    id: 'page.reports.allReports.addToFavourites.url.error',
                  }),
                )
                .max(
                  255,
                  intl.formatMessage(
                    { id: 'common.formError.maxLength' },
                    { maxLength: 255 },
                  ),
                ),
            })}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {(formProps) => (
              <Form id="add-report-link-form">
                <Label id="name-label" label="common.name" inline>
                  <StyleInputText
                    id="name"
                    name="name"
                    data-testid="add-report-link-name-input"
                    aria-label={intl.formatMessage({
                      id: 'common.name',
                    })}
                    onChangeHandler={(value) =>
                      formProps.setFieldValue('name', value)
                    }
                    invalid={!!formProps.errors.name}
                    invalidText={formProps.errors.name}
                    value={formProps.values.name}
                    type="text"
                  />
                </Label>
                <Label id="url-label" label="common.url" inline>
                  <StyleInputText
                    id="url"
                    name="url"
                    data-testid="add-report-link-url-input"
                    aria-label={intl.formatMessage({
                      id: 'common.url',
                    })}
                    onChangeHandler={(value) =>
                      formProps.setFieldValue('url', value)
                    }
                    invalid={!!formProps.errors.url}
                    invalidText={formProps.errors.url}
                    value={formProps.values.url}
                    type="text"
                  />
                </Label>
                {!addingFavourite && (
                  <CheckboxWrapper>
                    <Checkbox
                      id="addToFavourites"
                      defaultChecked={true}
                      onChange={(e, { checked }) =>
                        formProps.setFieldValue('addToFavourites', checked)
                      }
                      labelText={
                        <Translation id="page.reports.allReports.addToFavourites" />
                      }
                    />
                  </CheckboxWrapper>
                )}
              </Form>
            )}
          </Formik>
        </FlexBox>
      </ModalBody>
      <ModalFooter
        loadingStatus={
          createLinkLoading || favouriteLoading ? 'active' : 'inactive'
        }
      >
        <Button buttonType="modal--secondary" onClick={closeModal}>
          <Translation id="common.cancel" />
        </Button>
        <Button type="submit" form="add-report-link-form">
          <Translation id="common.add" />
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default CreateLinkModal;
