import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { useApiPost } from 'api';
import { InlineNotification } from 'components/Notifications';

import RemoveGroupModal from '../components/RemoveGroupModal';
import { RemoveModalProps } from '../GroupManagement.types';

import { RemoveGroupErrorNotification } from './RemoveGroupErrorNotification';

const flattenGroupsWithChildren = (formattedItems, items) => {
  items?.forEach((item) => {
    const newItem = {
      id: item.id,
      displayName: item.displayName,
      children: item.children,
    };
    formattedItems.push(newItem);
    if (newItem.children?.length > 0) {
      flattenGroupsWithChildren(formattedItems, newItem.children);
    }
  });
};

export const RemoveModal = ({
  removeGroupState,
  groupsList,
}: RemoveModalProps) => {
  const intl = useIntl();
  const [
    removeGroupsPost,
    { loading: removeGroupLoading, error: removeGroupError },
  ] = useApiPost('/api/v1/categories/r1/delete/');
  const [removeGroup, setRemoveGroup] = removeGroupState;
  const [params] = useSearchParams();
  const groupName = params.get('groupName');

  const allGroups = useMemo(() => {
    const groups = [];
    flattenGroupsWithChildren(groups, groupsList);
    return groups;
  }, [groupsList]);

  return (
    <>
      <RemoveGroupModal
        open={removeGroup.displayModal}
        groupsToRemove={allGroups.filter((g) =>
          removeGroup.groupsToRemove.includes(g.id),
        )}
        onRequestClose={() => {
          setRemoveGroup({ ...removeGroup, displayModal: false });
        }}
        onRequestSubmit={async () => {
          try {
            await removeGroupsPost({
              categories: removeGroup.groupsToRemove,
            });
            setRemoveGroup({
              displayModal: false,
              groupsToRemove: [],
              success: true,
              error: false,
            });
          } catch (e) {
            setRemoveGroup({
              displayModal: false,
              groupsToRemove: removeGroup.groupsToRemove,
              success: false,
              error: true,
            });
          }
        }}
        loading={removeGroupLoading}
      />
      {groupName && (
        <InlineNotification
          type="success"
          titleLabel={intl.formatMessage({
            id: 'label_addGroupSuccessTitle',
          })}
          subtitleLabel={intl.formatMessage({
            id: 'label_addGroupSuccessSubtitle',
          })}
        />
      )}
      {removeGroup.success && (
        <InlineNotification
          type="success"
          titleLabel={intl.formatMessage({
            id: 'label_removeGroupSuccessTitle',
          })}
          subtitleLabel={intl.formatMessage({
            id: 'label_removeGroupSuccessSubtitle',
          })}
        />
      )}
      {removeGroup.error && (
        <RemoveGroupErrorNotification
          error={removeGroupError}
          protectedGroupsRemoval={removeGroup.groupsToRemove.filter((id) =>
            groupsList
              .filter((group) => group.isProtected)
              .map((group) => group.id)
              .includes(id),
          )}
        />
      )}
    </>
  );
};
