import { ReactNode } from 'react';

import styled from 'styled-components';

import { breakpoint, unit } from 'theme';

export const WRAPPER_PADDING = unit(3);

export const Container = styled.div<{
  $noPadding?: boolean;
  $removeBorder?: boolean;
}>`
  background-color: ${({ theme }) =>
    theme.componentColors.card.backgroundColor};
  border: 1px solid ${({ theme }) => theme.componentColors.card.borderColor};
  border-radius: 5px;
  margin-top: ${unit(3)};
  margin-bottom: ${unit(3)};
  padding: ${WRAPPER_PADDING};
  box-shadow: none;

  ${breakpoint('mobile')} {
    padding: ${unit(1.5)};
  }

  ${({ $noPadding }) =>
    $noPadding &&
    `
  padding: 0px !important;
  border: 1px solid var(--cds-layer);
  `}

  ${({ $removeBorder }) =>
    $removeBorder &&
    `
  border: none;
  `}
`;

interface TitleInterface {
  $subtitle?: string | ReactNode;
}

export const Title = styled.h2<TitleInterface>`
  font-size: ${(props) => props.theme.headings.xs};
  font-weight: 600;
  ${({ $subtitle }) => !$subtitle && `margin-bottom: ${unit(0.5)};`}
`;

export const SubTitle = styled.p<{ $withoutMargin?: boolean }>`
  font-size: ${(props) => props.theme.fontSizes.m};

  ${({ $withoutMargin }) => !$withoutMargin && `margin-bottom: ${unit(2)};`}
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const RightContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
