import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import shallowequal from 'shallowequal';

import { useUpdateEffect } from 'utils/hooks';

import { Options } from './ApiData';
import ApiDataContext from './ApiDataContext';

export default function useApiData<T = any>(
  endpoint:
    | (string | null | undefined)
    | ((arg0: { getData: any }) => Promise<T>),
  _options: Options = {
    cache: false,
    reduxAction: null,
    dontAppendClientId: false,
    dontAppendLocale: false,
  },
) {
  type StateModel = {
    data: T | null;
    error: any;
    loading: boolean;
  };

  const INITIAL_STATE: StateModel = {
    data: null,
    error: null,
    loading: true,
  };

  const reducer = (
    state: StateModel,
    action: { type: string } & Partial<StateModel>,
  ) => {
    switch (action.type) {
      case 'REQUEST_START': {
        return {
          ...INITIAL_STATE,
          data: state.data, // keep current data if there is any
        };
      }

      case 'REQUEST_SUCCESS': {
        return {
          data: action.data,
          error: null,
          loading: false,
        };
      }

      case 'REQUEST_ERROR': {
        return {
          data: null,
          error: action.error,
          loading: false,
        };
      }

      default:
        // TODO
        throw new Error();
    }
  };

  // Because the options object is created inside a render method it will
  // change on every render. We need to wrap it in state and then only change it
  // if the values inside the options object actually changes.
  const [options, setOptions] = useState(_options);
  useEffect(() => {
    if (!shallowequal(options, _options)) {
      setOptions(_options);
    }
  }, [options, _options]);
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    loading: !!endpoint, // default to true if there is an endpoint
  });
  const apiData = useContext(ApiDataContext);
  useUpdateEffect(() => {
    if (!!endpoint) {
      dispatch({
        type: 'REQUEST_START',
      });
    }
  }, [endpoint]);
  const fetchDataWrapper = useCallback(() => {
    // If no endpoint then bail out
    if (!endpoint) {
      return;
    }

    let getDataResponse;

    if (typeof endpoint === 'string') {
      getDataResponse = apiData.getData(endpoint, options);
    } else if (typeof endpoint === 'function') {
      // Call function
      getDataResponse = endpoint({
        getData: apiData.getData.bind(apiData),
      });

      // Check if the response looks like a Promise
      if (typeof getDataResponse.then !== 'function') {
        throw new Error(
          '`endpoint` function passed to `useApiData` must return a Promise.',
        );
      }
    } else {
      throw new Error(
        '`useApiData` received an unexpected type for `endpoint`. Expected a string or a function',
      );
    }

    getDataResponse
      .then((data) => {
        dispatch({
          type: 'REQUEST_SUCCESS',
          data,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch({
          type: 'REQUEST_ERROR',
          error,
        });
      });
  }, [endpoint, apiData, options]);

  function refetch() {
    dispatch({
      type: 'REQUEST_START',
    });
    fetchDataWrapper();
  }

  useEffect(() => {
    fetchDataWrapper();
  }, [fetchDataWrapper]);
  return { ...state, refetch };
}
