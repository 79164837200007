import React, { useCallback, useMemo, useState } from 'react';

import { Add, Subtract } from '@carbon/icons-react';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useApiData, useApiPost } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { FallbackErrorComponent } from 'components/Error';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import { useDataTableState } from 'components/Table/CybDataTable';
import { useAppSelector } from 'store/createStore';
import { selectClientName } from 'store/features/mainSlice';
import { usePrevious } from 'utils/hooks';

import { PeopleTablesContainer } from './GroupManagement.styles';
import PeopleTable from './PeopleTable';

const createQueryString = (isClient, pageSize, pageNumber, search, groupId) => {
  const queryParams: any = {
    is_archived: false,
    fields: 'id,fullname,email',
    page: pageNumber,
    page_size: pageSize,
  };
  if (search && search !== '') queryParams.search = search;
  if (isClient) queryParams.exclude_groups = groupId;
  else queryParams.groups = groupId;
  return qs.stringify(queryParams);
};

const generateRowData = (usersData) => {
  if (usersData) {
    return usersData.results.map((d) => ({
      id: d.id.toString(),
      email: d.email,
      name: d.fullname,
    }));
  }
  return [];
};

const INITIAL_PAGINATION = {
  pageSize: 20,
  page: 1,
  searchValue: '',
};

const PeopleTab = ({
  groupName,
  managedByADSyncOrSSO,
  updateOverviewSection,
}) => {
  const intl = useIntl();
  const { groupId } = useParams<'groupId'>();
  const clientName = useAppSelector(selectClientName);

  const [clientTableState, setClientTableState] =
    useDataTableState(INITIAL_PAGINATION);

  const [groupTableState, setGroupTableState] =
    useDataTableState(INITIAL_PAGINATION);

  const [addUsersToGroup, { data: addedUsers, error: addUsersError }] =
    useApiPost(`/api/v1/categories/r1/${groupId}/add-users/`);
  const [
    removeUsersFromGroup,
    { data: removedUsers, error: removeUsersError },
  ] = useApiPost(`/api/v1/categories/r1/${groupId}/remove-users/`);

  const [selectedClientUsers, setSelectedClientUsers] = useState([]);
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const prevAddedUsers = usePrevious(addedUsers);
  const prevRemovedUsers = usePrevious(removedUsers);
  const addedUsersLength = addedUsers?.length;
  const removedUsersLength = removedUsers?.length;
  const prevAddedUsersLength = prevAddedUsers?.length;
  const prevRemovedUsersLength = prevRemovedUsers?.length;

  const {
    data: clientUsers,
    loading: clientUsersLoading,
    error: clientUsersError,
  } = useApiData(
    useCallback(
      async ({ getData }) => {
        if (clientTableState.searchValue !== '') {
          setClientTableState((prev) => ({ ...prev, page: 1 }));
        }

        if (
          prevAddedUsersLength !== addedUsersLength ||
          prevRemovedUsersLength !== removedUsersLength
        ) {
          setClientTableState((prev) => ({ ...prev, page: 1 }));
        }

        const queryString = createQueryString(
          true,
          clientTableState.pageSize,
          clientTableState.page,
          clientTableState.searchValue,
          groupId,
        );
        const clientUsers = await getData(`/api/v1/users/r4/?${queryString}`);
        return clientUsers;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        addedUsersLength,
        removedUsersLength,
        prevAddedUsersLength,
        prevRemovedUsersLength,
        groupId,
        clientTableState.searchValue,
        clientTableState.pageSize,
        clientTableState.page,
      ],
    ),
  );
  const {
    data: groupUsers,
    loading: groupUsersLoading,
    error: groupUsersError,
  } = useApiData(
    useCallback(
      async ({ getData }) => {
        let pageNum = groupTableState.page;

        if (groupTableState.searchValue !== '') {
          pageNum = 1;
        }

        if (
          prevAddedUsersLength !== addedUsersLength ||
          prevRemovedUsersLength !== removedUsersLength
        ) {
          pageNum = 1;
        }

        const queryString = createQueryString(
          false,
          groupTableState.pageSize,
          pageNum,
          groupTableState.searchValue,
          groupId,
        );
        const groupUsers = await getData(`/api/v1/users/r4/?${queryString}`);
        return groupUsers;
      },
      [
        groupTableState,
        addedUsersLength,
        removedUsersLength,
        prevAddedUsersLength,
        prevRemovedUsersLength,
        groupId,
      ],
    ),
  );

  const columns = [
    {
      id: 'people',
      name: <Translation id="label_people" />,
      selector: (row) => row.name,
      wrap: true,
    },
    {
      id: 'email',
      name: <Translation id="label_email" />,
      selector: (row) => row.email,
    },
  ];

  const rowData = useMemo(() => generateRowData(clientUsers), [clientUsers]);
  const groupRowData = useMemo(() => generateRowData(groupUsers), [groupUsers]);

  if (
    (!clientUsers && clientUsersLoading) ||
    (!groupUsers && groupUsersLoading)
  ) {
    return <LoaderBlock />;
  }

  if (clientUsersError || groupUsersError) {
    return (
      <FallbackErrorComponent error={clientUsersError || groupUsersError} />
    );
  }

  return (
    <>
      <PeopleTablesContainer managedByADSyncOrSSO={managedByADSyncOrSSO}>
        {!managedByADSyncOrSSO && (
          <>
            <div className="people-table">
              <PeopleTable
                data={rowData}
                columns={columns}
                totalUsers={clientUsers.count}
                loading={clientUsersLoading}
                paginationTotalRows={clientUsers.count}
                placeholder="label_searchByNameOrEmail"
                searchValue={clientTableState.searchValue}
                onSearchSubmit={(value) =>
                  setClientTableState((prev) => ({
                    ...prev,
                    searchValue: value,
                  }))
                }
                paginationPerPage={clientTableState.pageSize}
                onPageChange={(page) =>
                  setClientTableState((prev) => ({ ...prev, page: page }))
                }
                onRowSelect={({ selectedRows }) =>
                  setSelectedClientUsers(selectedRows)
                }
                iconLabel={intl.formatMessage(
                  {
                    id: 'label_peopleInClient',
                  },
                  {
                    clientName,
                  },
                )}
                headingLabel="label_allPeople"
                batchActions={[
                  {
                    id: 'add',
                    type: 'button',
                    buttonType: 'primary',
                    label: 'label_addToGroup',
                    renderIcon: Add,
                    onClick: async () => {
                      const users = selectedClientUsers.map((user) => user.id);
                      try {
                        await addUsersToGroup({
                          users,
                        });

                        if (!addUsersError) {
                          updateOverviewSection();
                        }
                      } catch {
                        // do nothing
                      }
                    },
                  },
                ]}
              />
            </div>
          </>
        )}
        <div className="people-table">
          <PeopleTable
            data={groupRowData}
            columns={columns}
            loading={groupUsersLoading}
            paginationTotalRows={groupUsers.count}
            placeholder="label_searchByNameOrEmail"
            searchValue={groupTableState.searchValue}
            totalUsers={groupUsers.count}
            onSearchSubmit={(value) =>
              setGroupTableState((prev) => ({ ...prev, searchValue: value }))
            }
            paginationPerPage={groupTableState.pageSize}
            onPageChange={(page) =>
              setGroupTableState((prev) => ({ ...prev, page: page }))
            }
            onRowSelect={({ selectedRows }) =>
              setSelectedGroupUsers(selectedRows)
            }
            batchActions={[
              {
                id: 'remove',
                type: 'button',
                buttonType: 'danger',
                label: 'label_removeFromGroup',
                renderIcon: Subtract,
                onClick: async () => {
                  const users = selectedGroupUsers.map((user) => user.id);
                  try {
                    await removeUsersFromGroup({
                      users,
                    });

                    if (!removeUsersError) {
                      updateOverviewSection();
                    }
                  } catch {
                    // do nothing
                  }
                },
              },
            ]}
            iconLabel={intl.formatMessage(
              {
                id: 'label_peopleInThisGroup',
              },
              {
                groupName,
              },
            )}
            headingLabel="label_allPeopleInGroup"
          />
        </div>
      </PeopleTablesContainer>
    </>
  );
};

export default PeopleTab;
