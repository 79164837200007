import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { useAppSelector } from 'store/createStore';
import { selectBottomBarHeight } from 'store/features/configSlice';

/**
 * useIntercom: Hook for interacting with Intercom.
 *
 * @param {String} appId - The app ID to use for Intercom.
 * @param {Object} userData - The user data to use for Intercom.
 */
const useIntercom = (
  appId: any,
  userData: { email?: string; user_id?: number },
) => {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const bottomBarHeight = useAppSelector(selectBottomBarHeight);

  const { email, user_id } = userData;
  const isLoginPage = location.pathname === '/login';

  useEffect(() => {
    if (!(window as any).Intercom) {
      /* intercom boilerplate */
      (function (w, d, id, s) {
        function i() {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        }

        /**
         * @type {any[]}
         */
        i.q = [];

        i.c = function (args: any) {
          i.q.push(args);
        };

        (w as any).Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appId);
    }

    return () => {
      if ((window as any).Intercom) {
        (window as any).Intercom('shutdown');
        setLoaded(false);
        (window as any).Intercom = null;
      }
    };
  }, [appId, email, user_id]);

  useEffect(() => {
    if ((window as any).Intercom) {
      (window as any).intercomSettings = {
        app_id: appId,
        vertical_padding: bottomBarHeight + 20,
        alignment: 'right',
        ...(!isLoginPage && userData),
      };
      if (loaded) {
        (window as any).Intercom('boot', (window as any).intercomSettings);
      } else {
        (window as any).Intercom('update', (window as any).intercomSettings);
      }
    }
  }, [
    appId,
    userData,
    loaded,
    location.pathname,
    isLoginPage,
    bottomBarHeight,
  ]);
};

export default useIntercom;
