import { useAppSelector } from 'store/createStore';
import {
  selectIntercomAppId,
  selectIntercomInfo,
} from 'store/features/mainSlice';
import { useIntercom } from 'utils/hooks';

const Intercom = () => {
  const intercomAppId = useAppSelector(selectIntercomAppId);
  const intercomInfo = useAppSelector(selectIntercomInfo);
  useIntercom(intercomAppId, {
    ...intercomInfo,
    user_id: Number(intercomInfo?.user_id),
  });
  return null;
};

export default Intercom;
