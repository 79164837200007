import React from 'react';

import BlockAdminContext from './BlockAdminContext';
import { initialState, reducer } from './reducer';

const BlockAdminProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <BlockAdminContext.Provider value={{ state, dispatch }}>
      {children}
    </BlockAdminContext.Provider>
  );
};

export default BlockAdminProvider;
