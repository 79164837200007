import React from 'react';

import { HeadingElement, StyledText, StyledTextProps } from '../Text.styles';

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  children: any;
  as?: HeadingElement;
  variant?: HeadingElement;
}

const Heading = ({
  children,
  as = 'h2',
  variant = 'h2',
  ...rest
}: HeadingProps) => {
  let config: Pick<StyledTextProps, '$fontSizeLevel' | '$lineHeightLevel'>;
  switch (variant) {
    case 'h1':
      config = {
        $fontSizeLevel: 'xxxl',
        $lineHeightLevel: 'xxxxl',
      };
      break;
    case 'h2':
      config = {
        $fontSizeLevel: 'xxl',
        $lineHeightLevel: 'xxxl',
      };
      break;
    case 'h3':
      config = {
        $fontSizeLevel: 'xl',
        $lineHeightLevel: 'xxl',
      };
      break;
    case 'h4':
      config = {
        $fontSizeLevel: 'lg',
        $lineHeightLevel: 'xl',
      };
      break;
    case 'h5':
      config = {
        $fontSizeLevel: 'md',
        $lineHeightLevel: 'lg',
      };
      break;
    case 'h6':
      config = {
        $fontSizeLevel: 'base',
        $lineHeightLevel: 'md',
      };
      break;
  }

  return (
    <StyledText
      as={as}
      $fontSizeLevel={config.$fontSizeLevel}
      $lineHeightLevel={config.$lineHeightLevel}
      $bold
      {...rest}
    >
      {children}
    </StyledText>
  );
};

export default Heading;
