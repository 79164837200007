import React from 'react';

import { useIntl } from 'react-intl';

import { Group } from '../GroupManagement.types';

import { SubGroup, SubGroupLink } from './GroupManagement.styles';
import { TabOrder } from './GroupOverview';

type GroupNameProps = {
  group: Group;
  isChild?: boolean;
};
const GroupName = ({ isChild, group }: GroupNameProps) => {
  const intl = useIntl();
  let toPage = `/settings/group-management/${group.id}?selected=${TabOrder.People}`;
  let label =
    (isChild ? String.fromCharCode(8627) + ' ' : '') + group.displayName;
  if (group.displayName === '....') {
    toPage = `/settings/group-management/${group.parentId}?selected=${TabOrder.SubGroups}`;
    label = `${
      isChild ? String.fromCharCode(8627) + ' ' : ''
    } ${intl.formatMessage({ id: 'common.view.more' })}....`;
  }
  return (
    <SubGroup depth={Number(isChild)}>
      <SubGroupLink useTextLiteral toPage={toPage} label={label} />
    </SubGroup>
  );
};

export default GroupName;
