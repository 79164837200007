import React from 'react';

const RespondIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.7551 0.0240031L27.3926 2.12625C27.9666 2.21934 28.4786 2.54515 28.7966 3.02611C29.1147 3.50706 29.2078 4.11214 29.0682 4.67843L25.5463 18.0211C25.3369 18.8046 24.693 19.3864 23.894 19.5183C23.095 19.6502 22.2882 19.2933 21.846 18.6184L19.5964 15.1897L14.4455 18.5641C11.6063 20.4259 10.8073 24.2425 12.6691 27.0817C13.7318 28.703 15.0351 29.1995 15.8496 29.3391C16.3228 29.4167 16.796 29.6417 17.0597 30.0373C17.4398 30.6191 17.2769 31.4026 16.6951 31.7827C16.5478 31.8835 16.3771 31.9534 16.1909 31.9689C14.8334 32.0775 8.68177 32.2326 4.70224 26.1586C1.30451 20.9534 2.7629 13.964 7.9681 10.543L13.9723 6.61001L11.7227 3.18125C11.2805 2.50636 11.2805 1.62202 11.7227 0.947131C12.1571 0.264482 12.9561 -0.100115 13.7551 0.0240031Z" />
  </svg>
);

export default RespondIcon;
