import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Wrapper } from 'components/Wrappers';
import { breakpoint, unit } from 'theme';

export const Icon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop) => ['icon'].includes(prop),
})`
  transform: rotate(90deg);
  width: ${unit(2)};
  margin-right: ${unit(1)};
`;

export const ComponentWrapper = styled(Wrapper)`
  &:hover {
    box-shadow: none;
  }
  margin: 0 0 ${unit(2)} 0;
`;

export const CategoryTitle = styled.div<{ child?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: 600;
  display: inline-block;
  width: 100%;
  overflow-wrap: break-word;
  margin-left: ${({ child }) => (child ? unit(2) : 0)};
  > a {
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
  }
`;

export const IconContainer = styled.span`
  svg {
    color: ${({ theme }) => theme.colorPalette.tones.warning};
  }
  display: flex;
  align-items: center;
  gap: ${unit(1)};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  a {
    width: 250px;
  }
`;

export const PieLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ObjectiveAndContributionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  ${breakpoint('tablet')} {
    grid-template-columns: 1fr;
  }
`;

export const ObjectiveSubjectiveWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${breakpoint('tablet')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledBehaviourLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const BehaviourTextWrapper = styled.span`
  text-decoration: underline;
`;

export const ContributionList = styled.ul`
  padding-top: 4px;
  > li {
    padding-top: 8px;
    display: flex;
    align-items: center;
    gap: ${unit(1)};
  }
  img {
    width: ${unit(3)};
    height: ${unit(3)};
  }
`;

export const ContributionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;
