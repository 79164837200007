import styled from 'styled-components';

import { InputText } from 'components/Form/Inputs';
import { unit } from 'theme';

export const HeaderSubTitle = styled.p`
  margin: ${unit(1)} 0;
`;

export const CheckboxWrapper = styled.div`
  margin-top: ${unit(1)};
`;

export const StyleInputText = styled(InputText)`
  margin-bottom: ${unit(1)};
`;
