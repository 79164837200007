import React from 'react';

import { useIntl } from 'react-intl';

import { InlineNotification } from 'components/Notifications';

export const RemoveGroupErrorNotification = ({
  error,
  protectedGroupsRemoval,
}) => {
  const intl = useIntl();
  let subtitle = 'label_removeGroupErrorSubtitle';
  if (error.status === 400 && protectedGroupsRemoval.length > 0) {
    subtitle =
      'page.groupManagement.table.deleteGroup.error.notification.subtitle';
  }
  return (
    <InlineNotification
      type="error"
      titleLabel={intl.formatMessage({
        id: 'component.notification.error.title',
      })}
      subtitleLabel={intl.formatMessage(
        {
          id: subtitle,
        },
        {
          groupsToRemove: protectedGroupsRemoval
            .map(({ groupName }) => groupName)
            .join(', '),
        },
      )}
    />
  );
};
