import { OverflowMenuItem } from '@carbon/react';
import { OverflowMenuItemProps } from '@carbon/react/lib/components/OverflowMenuItem/OverflowMenuItem';
import styled from 'styled-components';

import { Wrapper } from 'components/Wrappers';
import { unit } from 'theme';

export const PeopleCountContainer = styled.div`
  margin: ${unit(2)} 0;
  font-size: ${({ theme }) => theme.headings.s};
  font-weight: 600;

  svg {
    margin-left: ${unit(2)};
  }
`;

type MenuItemProps = OverflowMenuItemProps & {
  $isDanger?: boolean;
};

export const MenuItem = styled(OverflowMenuItem)<MenuItemProps>`
  color: ${({ $isDanger, theme }) =>
    $isDanger ? theme.colorPalette.tones.danger : 'inherit'};

  &:hover {
    color: ${({ $isDanger, theme }) =>
      $isDanger ? theme.colorPalette.tones.danger : 'inherit'};
  }
` as React.FC<MenuItemProps>;

export const StyledWrapper = styled(Wrapper)`
  ol {
    list-style: decimal;
    margin: 0px ${unit(3)};
  }
`;
