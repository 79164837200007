import React, { useState } from 'react';

import Translation from 'components/Content/Translation/Translation';
import PlatformFeedback from 'components/PlatformFeedback';
import { Text } from 'components/Text';
import { useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import { selectUserInfo } from 'store/features/mainSlice';

import LanguageDropdown from './components/LanguageDropdown';
import { FooterContainer, LinkWrapper } from './Footer.styles';

interface LanguageType {
  shortName: string;
  fullName: string;
  isrtl: number;
  direction: string;
  locale: string;
  published: boolean;
  isBeta: boolean;
  shortDisplayName: string;
}

export const getLanguageItems = (languages: Array<LanguageType>) =>
  languages
    .filter((language) => language.published || language.isBeta)
    .map((language) => ({
      id: language.shortName,
      label: `${language.fullName}${language.isBeta ? ' - BETA' : ''}`,
    }));

const Footer: React.FC = () => {
  const userInfo = useAppSelector(selectUserInfo);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const usePlatformFeedback = useFeatureFlag('use-platform-feedback-footer');
  const [showLanguageSelectionField, setShowLanguageSelectionField] =
    useState(false);
  return (
    <>
      {userInfo.id && showFeedbackForm && (
        <PlatformFeedback setShowFeedbackForm={setShowFeedbackForm} />
      )}
      <FooterContainer>
        {showLanguageSelectionField && <LanguageDropdown />}
        <LinkWrapper>
          {!showLanguageSelectionField && (
            <Text
              onClick={() => setShowLanguageSelectionField(true)}
              data-testid="show-language-selector"
            >
              <Translation id="page.login.changeLanguage" />
            </Text>
          )}
          <Text className="ot-sdk-show-settings" id="ot-sdk-btn">
            <Translation id="footer.cookieSettings" />
          </Text>
          <a
            href="https://www.cybsafe.com/website-privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            <Translation id="footer.links.privacyPolicy" />
          </a>
          {userInfo.id && usePlatformFeedback && (
            <button
              id="report-problem-with-page"
              onClick={() =>
                setShowFeedbackForm((prevShowForm) => !prevShowForm)
              }
            >
              <Translation id="footer.reportProblemWithPage.button" />
            </button>
          )}
        </LinkWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
