import { useState } from 'react';

import { useNonInitialEffect } from 'utils/hooks';

export type SortDirectionType = 'ASC' | 'DESC' | 'NONE';

export type UseDataTableStateParams = {
  /**
   * page: The page the table is currently on
   */
  page?: number;
  /**
   * pageSize: The amount of results per page of the table
   */
  pageSize?: number;
  /**
   * column: The matching string key value for the column
   */
  column?: string;
  /**
   * direction: The sort direction for sorting of results: ASC|DESC|NONE
   */
  direction?: SortDirectionType;
  /**
   * searchValue: The string value for the search
   */
  searchValue?: string;
};

const useDataTableState = (
  {
    page = 1,
    pageSize = 20,
    column,
    direction = 'DESC',
    searchValue = '',
  }: UseDataTableStateParams,
  /**
   * deps: An array of dependencies that will trigger the non initial effect and reset the page to 1
   */
  deps = [],
) => {
  const [dataTableState, setDataTableState] = useState({
    page,
    pageSize,
    column,
    direction,
    searchValue,
  });

  useNonInitialEffect(() => {
    setDataTableState((prevState) => ({
      ...prevState,
      page: 1,
    }));
  }, [...deps]);

  return [dataTableState, setDataTableState] as [
    typeof dataTableState,
    typeof setDataTableState,
  ];
};

export default useDataTableState;
