import React from 'react';

import {
  ComposedModal as CarbonComposedModal,
  ComposedModalProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@carbon/react';
import ReactDOM from 'react-dom';

export { type ComposedModalProps, ModalBody, ModalFooter, ModalHeader };

const Modal = (props: ComposedModalProps) =>
  ReactDOM.createPortal(
    <CarbonComposedModal {...props}>{props.children}</CarbonComposedModal>,
    document.getElementById('react-root') || document.body,
  );
export default Modal;
