import { useRef } from 'react';

/**
 * useIsFirstRender: Hook for checking if the component is the first render.
 *
 * @returns - True if the component is the first render, false otherwise.
 */
function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export default useIsFirstRender;
