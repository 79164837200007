import React from 'react';

import {
  ComboBox as CarbonComboBox,
  ComboBoxProps as CarbonComboBoxProps,
} from '@carbon/react';

import { Item } from '../Dropdowns.types';
import DropdownSkeleton from '../DropdownSkeleton';

type ComboBoxProps = CarbonComboBoxProps<Item> & {
  loading?: boolean;
};

const filterItems = (input) =>
  input?.item?.label.toLowerCase().includes(input?.inputValue?.toLowerCase());

const ComboBox = ({ loading, selectedItem, ...rest }: ComboBoxProps) =>
  loading ? (
    <DropdownSkeleton />
  ) : (
    <CarbonComboBox
      {...rest}
      selectedItem={selectedItem}
      shouldFilterItem={(input) => {
        /* When the filter input value is the same as the selected item, return
        the full list of items in the dropdown instead of only the select item */
        if (selectedItem && selectedItem.label === input.inputValue) {
          return true;
        }
        return filterItems(input);
      }}
    />
  );

export { type ComboBoxProps };

export default ComboBox;
