import { DependencyList, EffectCallback, useEffect } from 'react';

import useIsFirstRender from './useIsFirstRender';

/**
 * useNonInitialEffect: Hook for running a function on all renders apart from the first render.
 *
 * @param {EffectCallback} effect The `useEffect` callback function.
 * @param {DependencyList} deps An array of dependencies.
 */

function useNonInitialEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useNonInitialEffect;
