import React from 'react';

import { ButtonBaseProps, Button as CarbonButton } from '@carbon/react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Translation from '../Content/Translation/Translation';
import InlineLoading from '../InlineLoading/InlineLoading';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'danger'
  | 'danger--secondary'
  | 'danger--ghost'
  | 'modal--secondary';

type ButtonProps = Omit<ButtonBaseProps, 'kind' | 'size'> & {
  buttonType?: ButtonType;
  label?: string;
  loading?: boolean;
  loadingDescription?: React.ReactNode | string;
};

/* We don't use carbon's secondary button. We instead use their tertiary button as our secondary button.
However, to avoid confusion, we still pass the buttonType as secondary and cast it to carbon's tertiary kind.*/
export const getKind = (buttonType: ButtonType) => {
  if (buttonType) {
    if (buttonType === 'secondary') return 'tertiary';
    if (buttonType === 'danger--secondary') return 'danger--tertiary';
    if (buttonType === 'modal--secondary') return 'secondary';
    return buttonType;
  }
  return 'primary';
};

const Button = ({
  buttonType,
  disabled,
  label,
  loading,
  loadingDescription,
  children,
  ...rest
}: ButtonProps) => {
  const intl = useIntl();
  return (
    <CarbonButton
      {...rest}
      className={classNames(rest.className, { 'cds--btn--loading': loading })}
      kind={getKind(buttonType)}
      disabled={disabled || loading}
      size="md"
    >
      {loading ? (
        <InlineLoading
          className="cds--inline-loading--btn"
          status="active"
          iconDescription={intl.formatMessage({ id: 'common.loading' })}
          description={
            loadingDescription || <Translation id="component.button.loading" />
          }
        />
      ) : (
        <>{children ? children : label && <Translation id={label} />}</>
      )}
    </CarbonButton>
  );
};

export { type ButtonProps, type ButtonType };

export default Button;
