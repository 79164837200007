import React from 'react';

import { Breadcrumb as CarbonBreadcrumb } from '@carbon/react';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs, {
  BreadcrumbComponentProps,
  BreadcrumbData,
} from 'use-react-router-breadcrumbs';

import Translation from 'components/Content/Translation/Translation';

import { BreadcrumbItem } from './Breadcrumbs.styles';
import BreadcrumbsOverflowMenu from './BreadcrumbsOverflowMenu';

export interface BreadcrumbInterface {
  label: string;
  dynamicLabel?: boolean;
  backTo?: string;
}

export type BreadcrumbsProps = {
  breadcrumbs?: BreadcrumbInterface[];
};

const isOverflowItem = (index: number, breadcrumbsLength: number) =>
  index > 0 && index < breadcrumbsLength - 2;

const getOverflowItems = (breadcrumbData: BreadcrumbData<string>[]) =>
  breadcrumbData.filter((_, index) =>
    isOverflowItem(index, breadcrumbData.length),
  );

type LabelProps = BreadcrumbComponentProps<string> & { label: string };

const Label = ({ label }: LabelProps) => <Translation id={label} />;

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const location = useLocation();
  const breadcrumbData = useBreadcrumbs(
    breadcrumbs
      ? breadcrumbs.map(({ label, dynamicLabel, backTo }) => ({
          path: backTo ?? location.pathname,
          breadcrumb: dynamicLabel ? label : Label,
          props: {
            label,
          },
        }))
      : undefined,
    {
      excludePaths: !!breadcrumbs ? [] : ['/'],
      disableDefaults: !!breadcrumbs,
    },
  );

  return (
    <CarbonBreadcrumb noTrailingSlash data-testid="breadcrumbs">
      {breadcrumbData.map(({ key, match, breadcrumb }, index) => {
        if (isOverflowItem(index, breadcrumbData.length)) {
          if (index > 1) return false;
          return (
            <BreadcrumbItem key={key}>
              <BreadcrumbsOverflowMenu
                breadcrumbData={getOverflowItems(breadcrumbData)}
              />
            </BreadcrumbItem>
          );
        }
        return (
          <BreadcrumbItem
            key={key}
            href="#"
            isCurrentPage={
              location.pathname === match.pathname ||
              location.pathname === `${match.pathname}/`
            }
          >
            <Link to={match.pathname}>{breadcrumb}</Link>
          </BreadcrumbItem>
        );
      })}
    </CarbonBreadcrumb>
  );
};

export default Breadcrumbs;
