import React from 'react';

import OriginRouteContext from './OriginRouteContext';
import { initialState, reducer } from './reducer';

const OriginRouteProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <OriginRouteContext.Provider value={{ state, dispatch }}>
      {children}
    </OriginRouteContext.Provider>
  );
};

export default OriginRouteProvider;
