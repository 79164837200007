import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { useApiData, useApiFetcher, useApiPatch } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { Dropdown } from 'components/Form/Inputs/Dropdowns';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import {
  selectLocale,
  updateTranslationConfig,
} from 'store/features/configSlice';
import {
  selectUserInfo,
  setFullPageErrorScreen,
  setFullPageLoadingScreen,
  updateUserInfo,
} from 'store/features/mainSlice';
import { setTranslationsAndUpdateConfig } from 'store/features/translationsSlice';
import { getCookie } from 'utils/token';

import { getLanguageItems } from '../Footer';
import { LanguageContainer } from '../Footer.styles';

type Language = {
  shortName: string;
  fullName: string;
  isrtl: number;
  direction: string;
  locale: string;
  published: boolean;
  isBeta: boolean;
  shortDisplayName: string;
};

const languageIsBeta = (languages, language: string) => {
  const newLanguage = languages.find((lang) => lang.shortName === language);
  return newLanguage.isBeta;
};

const LanguageDropdown = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);
  const userInfo = useAppSelector(selectUserInfo);
  const [getTranslations] = useApiFetcher();
  const { data, loading, error } = useApiData('/api/v1/language/r1', {
    cache: true,
    dontAppendClientId: true,
    dontAppendLocale: true,
  });
  const [postDefaultLanguage, { error: changeDefaultLanguageError }] =
    useApiPatch(userInfo ? `/api/v1/users/r3/${userInfo.id}` : '', {
      dontAppendClientId: true,
    });

  const getTranslationsForSelectedLanguage = async (locale, direction) => {
    try {
      const response = await getTranslations(
        'GET',
        `/api/v1/core-lang/r2/?locale=${locale}`,
      );
      dispatch(setTranslationsAndUpdateConfig(locale, direction, response));
    } catch (e) {
      dispatch(setFullPageErrorScreen(true));
    }
  };

  const changeDefaultLanguage = async (language: string) => {
    dispatch(setFullPageLoadingScreen('loadingSpinner.loadingLanguage'));
    const selectedLanguage = data.filter(
      (lang: Language) => lang.shortName === language,
    );
    const languageSettings = {
      locale: language.replace('_', '-'),
      direction: selectedLanguage[0].isrtl ? 'rtl' : 'ltr',
    };
    localStorage.setItem('language', JSON.stringify(languageSettings));
    dispatch(updateTranslationConfig(languageSettings));
    if (getCookie()) {
      if (languageIsBeta(data, language)) {
        // We don't want to post the users defaultLanguage if the language is a beta language
        dispatch(updateUserInfo({ ...userInfo, defaultLanguage: language }));
      } else {
        const response = await postDefaultLanguage({
          defaultLanguage: language,
        });
        dispatch(updateUserInfo(response));
      }
      await getTranslationsForSelectedLanguage(
        languageSettings.locale,
        languageSettings.direction,
      );
    } else {
      if (selectedLanguage.length > 0) {
        await getTranslationsForSelectedLanguage(
          selectedLanguage[0].shortName.replace('_', '-'),
          selectedLanguage[0].isrtl ? 'rtl' : 'ltr',
        );
      }
    }
    dispatch(setFullPageLoadingScreen(false));
  };

  const items = useMemo(() => {
    if (data) {
      return getLanguageItems(data);
    }
    return [];
  }, [data]);

  if (error || changeDefaultLanguageError) return null;
  return (
    <LanguageContainer>
      <Dropdown
        id="language-footer"
        data-testid="language-select-footer"
        titleText={<Translation id="label_selectLanguage" />}
        label={intl.formatMessage({ id: 'label_selectLanguage' })}
        hideLabel
        items={items}
        initialSelectedItem={items.find((item) => item.id === locale)}
        selectedItem={items.find((item) => item.id === locale)}
        onChange={({ selectedItem }) => {
          changeDefaultLanguage(selectedItem.id as string);
        }}
        direction="top"
        loading={loading}
      />
    </LanguageContainer>
  );
};

export default LanguageDropdown;
