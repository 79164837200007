import React from 'react';

import { Link } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import { Modal } from 'components/Modal';

const DeleteReportFavouriteModal = ({
  open,
  onRequestClose,
  onRequestSubmit,
}) => (
  <Modal
    data-testid="delete-reportFavourite-modal"
    open={open}
    modalHeading={<Translation id="label_areYouSure" />}
    primaryButtonText={<Translation id="common.confirm" />}
    onRequestSubmit={onRequestSubmit}
    secondaryButtonText={<Translation id="common.back" />}
    onSecondarySubmit={onRequestClose}
    onRequestClose={onRequestClose}
    alert
  >
    <div>
      <Translation id="page.reportFavourite.deleteModal.subTitle" />{' '}
      <Link to="/reports/favourites">
        <Translation id="page.reports.allReports.seeYourFavourites" />
      </Link>
    </div>
  </Modal>
);

export default DeleteReportFavouriteModal;
