import React, { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { useBeforeUnload, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { onCLS, onFID, onLCP } from 'web-vitals';

import { useLogAuditEvent } from 'eventLog/hooks';
import { useAppSelector } from 'store/createStore';
import { selectClientInfo, selectUserInfo } from 'store/features/mainSlice';
import {
  identifyUser,
  setUserProperties,
  trackEvent,
  trackPageview,
} from 'tracking';
import { usePrevious } from 'utils/hooks';

interface AnalyticsWrapperProps {
  children?: React.ReactNode;
}

const trackWebVitals = (pathname, metrics, setMetrics) => {
  if (!Object.values(metrics).every((item) => item === null)) {
    trackEvent('Platform: Web Vitals', {
      location: pathname,
      ...metrics,
    });
    setMetrics({ CLS: null, LCP: null, FID: null });
  }
};

function AnalyticsWrapper({ children }: AnalyticsWrapperProps) {
  const userInfo = useAppSelector(selectUserInfo);
  const clientInfo = useAppSelector(selectClientInfo);
  const location = useLocation();
  const previousLocation = usePrevious(location.pathname);
  const logAuditEvent = useLogAuditEvent();
  const [metrics, setMetrics] = useState({ CLS: null, LCP: null, FID: null });
  const setTrack = (data) => {
    setMetrics((met) => ({ ...met, [data.name]: data.value }));
  };

  const debouncedCls = debounce(setTrack, 100);
  const debouncedLcp = debounce(setTrack, 100);
  const debouncedFid = debounce(setTrack, 100);

  useEffect(() => {
    onCLS(debouncedCls);
    onFID(debouncedFid);
    onLCP(debouncedLcp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeUnload(
    useCallback(() => {
      trackWebVitals(location.pathname, metrics, setMetrics);
    }, [location, metrics, setMetrics]),
  );

  useEffect(() => {
    trackWebVitals(previousLocation, metrics, setMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (userInfo.id) {
      // If a user exists we set a _cyb cookie to help identify users on the sales website www.cybsafe.com
      const cookies = new Cookies();
      cookies.set('_cyb', 'CYBUSER', {
        path: '/',
        domain: '.cybsafe.com',
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        secure: window.location.protocol === 'https:',
      });
    }
  }, [userInfo]);

  useEffect(() => {
    // Identify user
    if (userInfo && clientInfo) {
      identifyUser(userInfo.id, clientInfo.id);
      setUserProperties({
        clientId: clientInfo.id,
        isAdmin: userInfo.isAdmin,
        userGroup: userInfo.userGroup,
        isInternal: clientInfo.isInternal,
        isDemo: clientInfo.isDummy,
        isStructuredTrial: clientInfo.structuredTrial,
        userLimit: clientInfo.userLimit,
        staffNo: clientInfo.staffNo,
      });
    }

    // Track the page view
    trackPageview(location, userInfo.menuVersion);
  }, [location, clientInfo, userInfo]);

  useEffect(() => {
    // Create log events for reports pages
    const getReportName = (path) => {
      const strippedPath = path.replace('/reports/', '');
      return strippedPath.replace(/\//g, '.');
    };
    if (location.pathname.startsWith('/reports')) {
      logAuditEvent({
        event_type: 'report.viewed',
        event_details: {
          report: getReportName(location.pathname),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
}

export default AnalyticsWrapper;
