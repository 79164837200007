import * as Sentry from '@sentry/browser';
import isEqual from 'lodash/isEqual';
import OneSignal from 'react-onesignal';

import { convertToSnakeCase, isDevEnv, isPreProdEnv } from '../../utils';

export const initializeOneSignal = async () => {
  if (checkEnv()) {
    try {
      await OneSignal.init({
        appId: getKeyForEnv(),
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: '/static/',
        serviceWorkerUpdaterPath: '/static/',
        serviceWorkerParam: { scope: '/static/' },
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('OneSignal initialization not allowed on current env');
  }
};

export const setupTags = async (config) => {
  const { userId, client, defaultLanguage, isAdmin } = config;
  try {
    OneSignal.setExternalUserId(`${userId}`);
    const tags = {
      isAdmin: isAdmin.toString(),
      client: client.toString(),
      language: convertToSnakeCase(defaultLanguage.toLowerCase()),
    };

    const currentTags = OneSignal.getTags();
    if (!isEqual(tags, currentTags)) {
      // Delete all tags as we will resend
      Object.keys(currentTags).forEach(async (key) => {
        await OneSignal.deleteTag(key);
      });
    }

    // Resync the tags
    await OneSignal.sendTags(tags);
    OneSignal.showNativePrompt();
  } catch (err) {
    Sentry.captureException(err);
  }
};

const checkEnv = (): boolean => {
  // Notifications are enabled for dev on 127, preprod and prod. Test env notifications are not initialised.
  if (isDevEnv() && window.location.href.includes('127.0.0.1')) {
    return true;
  }
  if (isPreProdEnv()) {
    return true;
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.href.includes('app.cybsafe.com')
  ) {
    return true;
  }
  return false;
};

const getKeyForEnv = (): string => {
  if (isDevEnv()) {
    return 'b7aae446-9b54-400a-a474-e0489f04cd1f';
  }
  if (isPreProdEnv()) {
    return '49134e35-f6fd-4323-9a6c-70476d8f1771';
  }
  return '5fbb06f0-8ea2-430a-beac-1812f9affb3a';
};

export const getNotificationPermission = async () => {
  try {
    return await OneSignal.getNotificationPermission();
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const getSubscriptionStatus = async () => {
  try {
    return await OneSignal.getSubscription();
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const setSubscriptionStatus = async (status: boolean) => {
  try {
    return await OneSignal.setSubscription(status);
  } catch (err) {
    Sentry.captureException(err);
  }
};
