import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage/session';

import configReducer from './features/configSlice';
import mainReducer from './features/mainSlice';
import settingsReducer from './features/settingsSlice';
import themeReducer from './features/themeSlice';
import translationsReducer from './features/translationsSlice';
import sentry from './middleware/sentry';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  stateReconciler: autoMergeLevel2,
};

export const rootReducer = combineReducers({
  config: configReducer,
  main: mainReducer,
  userSettings: settingsReducer,
  theme: themeReducer,
  translations: translationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const sentryMiddleware = sentry();

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 100 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sentryMiddleware),
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

export default store;
