import React from 'react';

import { OverflowMenuItem } from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

import OverflowMenu from 'components/OverflowMenu';

type BreadcrumbsOverflowMenuProps = {
  breadcrumbData: BreadcrumbData<string>[];
};

const BreadcrumbsOverflowMenu = ({
  breadcrumbData,
}: BreadcrumbsOverflowMenuProps) => {
  const navigate = useNavigate();
  return (
    <OverflowMenu>
      {breadcrumbData.map(({ key, breadcrumb, match }) => (
        <OverflowMenuItem
          key={key}
          itemText={breadcrumb}
          onClick={() => navigate(match.pathname)}
        />
      ))}
    </OverflowMenu>
  );
};

export default BreadcrumbsOverflowMenu;
