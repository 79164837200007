import styled from 'styled-components';

import { unit } from 'theme';

export const FavouriteStarWrapper = styled.div`
  padding: 0 ${unit(1)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
