export const initialState = { originRoute: null };

export const ACTIONS = {
  UPDATE: 'UPDATE',
  CLEAR: 'CLEAR',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { originRoute: action.payload };
    case ACTIONS.CLEAR:
      return { originRoute: null };
    default:
      return { originRoute: null };
  }
};
