import isPropValid from '@emotion/is-prop-valid';
import styled from 'styled-components';

import { unit } from 'theme';

// @TODO: Improve types for css props so that you cannot pass an invalid value
// https://cybsafe.atlassian.net/browse/TECH-471

const CSSProps = {
  m: 'margin',
  p: 'padding',
  mt: 'margin-top',
  mb: 'margin-bottom',
  ml: 'margin-left',
  mr: 'margin-right',
  pt: 'padding-top',
  pb: 'padding-bottom',
  pl: 'padding-left',
  pr: 'padding-right',
  bg: 'background',
  flexDirection: 'flex-direction',
  flow: 'flex-flow',
  wrap: 'flex-wrap',
  justify: 'justify-content',
  align: 'align-items',
  aligns: 'align-self',
  gap: 'gap',
  pos: 'position',
  dis: 'display',
  l: 'left',
  r: 'right',
  t: 'top',
  b: 'bottom',
  brd: 'border',
  brdt: 'border-top',
  brdb: 'border-bottom',
  brdl: 'border-left',
  brdr: 'border-right',
  br: 'border-radius',
  c: 'color',
  f: 'fill',
  o: 'opacity',
  w: 'width',
  h: 'height',
  ov: 'overflow',
  ovx: 'overflow-x',
  ovy: 'overflow-y',
  td: 'text-decoration',
  ta: 'text-align',
  tt: 'text-transform',
  fz: 'font-size',
  fw: 'font-weight',
  lh: 'line-height',
  minw: 'min-width',
  maxw: 'max-width',
  flex: 'flex',
  flexWrap: 'flex-wrap',
  cursor: 'cursor',
};

const universalStyledProps = (props: Partial<typeof CSSProps>) => {
  let css = '';

  for (const prop in props) {
    if (prop !== 'fz' && CSSProps.hasOwnProperty(prop)) {
      css += `${CSSProps[prop]} : ${props[prop]}\n;`;
    }
  }

  return css;
};

export const Box = styled.div.withConfig({
  shouldForwardProp: (props) => isPropValid(props),
})<Partial<typeof CSSProps>>`
  ${universalStyledProps}
  ${({ fz, theme }) =>
    fz && `font-size: ${theme.fontSizes[fz] ? theme.fontSizes[fz] : fz}`};
`;

export const FlexBox = styled.div.withConfig({
  shouldForwardProp: (props) => isPropValid(props),
})<Partial<typeof CSSProps>>`
  display: flex;
  ${universalStyledProps}
  ${({ fz, theme }) =>
    fz && `font-size: ${theme.fontSizes[fz] ? theme.fontSizes[fz] : fz}`};
`;

export const Container = styled.div.withConfig({
  shouldForwardProp: (props) => isPropValid(props),
})<Partial<typeof CSSProps>>`
  display: flex;
  ${({ flow }) => `flex-flow: ${flow ?? 'row nowrap'}`};
  ${({ m }) => `margin: ${m ?? '0'}`};
  ${({ p }) => `padding: ${p ?? '0'}`};
  ${({ justify }) => `justify-content: ${justify ?? 'space-between'}`};
  ${({ align }) => `align-items: ${align ?? 'start'}`};
  @media (max-width: 800px) {
    width: 100%;
    ${({ flow }) => `flex-flow: ${flow ?? 'column nowrap'}`};
  }
  ${universalStyledProps}
`;

export const Column = styled.div.withConfig({
  shouldForwardProp: (props) => isPropValid(props),
})<
  Partial<typeof CSSProps> & {
    ws?: string;
  }
>`
  ${({ w }) => `width: ${w ?? '50%'}`};
  ${({ ml }) => `margin-left: ${ml ?? unit(1)}`};
  ${({ aligns }) => `align-self: ${aligns ?? 'auto'}`};
  ${({ ws }) => `word-spacing: ${ws ?? 'inherit'}`};
  &:first-of-type {
    margin-left: 0;
  }
  h3 {
    font-size: 1.375rem;
    line-height: 1.5;
    font-weight: 600;
    ${universalStyledProps}
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes.m};
    padding: 0;
    margin: 0;
    ${universalStyledProps}
  }
  ${universalStyledProps}
  @media (max-width: 800px) {
    width: 100%;
  }
`;
