import React from 'react';

import Translation from '../../../Content/Translation/Translation';

/* Creates a link based on the behaviours shortTitle which will redirect user to the sebDB site */
export const createBehaviourLink = (behaviourUID: string) =>
  `https://www.cybsafe.com/research/security-behaviour-database/behaviours/${behaviourUID.toLowerCase()}/`;

export const BehaviourLink = ({
  behaviours,
  behaviour,
  behaviourIndex,
  maxBehaviourDisplay,
}) => (
  <>
    <a
      href={createBehaviourLink(behaviour.sebdbUid)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {behaviour.shortTitle}
    </a>
    {behaviourIndex < maxBehaviourDisplay - 1 && behaviours.length > 1
      ? ', '
      : ' '}
  </>
);

interface BehaviourInterface {
  id: number;
  shortTitle: string;
  sebdbUid: string;
}

export interface BehavioursCellProps {
  behaviours: BehaviourInterface[];
  maxBehaviourDisplay?: number;
}

const BehavioursCell = ({
  behaviours,
  maxBehaviourDisplay = 2,
}: BehavioursCellProps) => {
  if (behaviours.length === 0) return <Translation id="common.none" />;
  const fullBehavioursListString = behaviours
    .map((b) => b.shortTitle)
    .join(', ');
  const list = [...behaviours]
    .splice(0, maxBehaviourDisplay)
    .map((b, index) => (
      <BehaviourLink
        key={b.id}
        behaviours={behaviours}
        behaviour={b}
        behaviourIndex={index}
        maxBehaviourDisplay={maxBehaviourDisplay}
      />
    ));
  if (behaviours.length > maxBehaviourDisplay) {
    const moreValue = behaviours.length - list.length;
    list.push(
      <Translation key="more" id="common.plusMore" values={{ moreValue }} />,
    );
  }
  return (
    <div title={fullBehavioursListString}>
      <span style={{ whiteSpace: 'pre-wrap' }}>{list}</span>
    </div>
  );
};

export default BehavioursCell;
