import React, { useEffect, useState } from 'react';

import qs from 'qs';
import { useIntl } from 'react-intl';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useApiPost } from 'api';
import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { createBehaviourLink } from 'components/Table/Cells/BehavioursCell/BehavioursCell';
import CybDataTable from 'components/Table/CybDataTable';
import { Heading, Text } from 'components/Text';
import { SecurityBehaviourPill } from 'pages/Notifications/Create/FormContent/CreateNotificationFormContent.styles';
import { BehaviourTextWrapper } from 'pages/Reports/Behaviours/BehaviourDetail/components/BehaviourPages.styles';
import { useAppSelector } from 'store/createStore';
import { selectSettings } from 'store/features/settingsSlice';
import { useNonInitialEffect } from 'utils/hooks';

import BehaviourFilter from './BehaviourFilter';
import {
  InheritedByParent,
  LearningCampaignsRedirect,
  Toggle,
  ToggleContainer,
} from './GroupManagement.styles';
import {
  BehaviourLink,
  BehaviourList,
  ExpandableComponent,
} from './ModulesTab.styles';

const StatusSwitch = ({
  isAssigned,
  moduleId,
  disabled,
  updateOverviewSection,
  setDisableSwitches,
}) => {
  const intl = useIntl();
  const { groupId } = useParams<'groupId'>();
  const [changeModuleAssign, { loading }] = useApiPost(
    `/api/v1/categories/r1/${groupId}/assign-module/`,
  );
  useEffect(() => {
    setDisableSwitches(loading);
  }, [loading, setDisableSwitches]);
  return (
    <ToggleContainer>
      <Toggle
        aria-label={intl.formatMessage({
          id: 'page.groupManagement.modules.table.assignedToggle.ariaLabel',
        })}
        toggled={isAssigned}
        id={`feature-toggle-${moduleId}`}
        labelA={intl.formatMessage({
          id: 'label_unassigned',
        })}
        labelB={intl.formatMessage({
          id: 'label_assigned',
        })}
        labelText=""
        disabled={disabled}
        onToggle={async (value: boolean) => {
          await changeModuleAssign({
            module: parseInt(moduleId),
            assign: value,
          });
          updateOverviewSection();
        }}
      />
    </ToggleContainer>
  );
};

const headers = [
  {
    key: 'moduleName',
    name: <Translation id="label_content" />,
    selector: (row) => row.moduleName,
    wrap: true,
  },
  {
    selector: (row) => row.contentType,
    name: <Translation id="label_contentType" />,
  },
  {
    selector: (row) => row.linkedBehaviour,
    name: <Translation id="label_linkedBehaviour" />,
  },
  {
    selector: (row) => row.status,
    name: <Translation id="label_status" />,
  },
  {
    selector: (row) => row.assignedByInheritance,
    name: '',
    wrap: true,
  },
];

const getRows = (
  modules,
  updateOverviewSection,
  disableSwitches,
  setDisableSwitches,
  intl,
) =>
  modules.map((module) => ({
    id: module.moduleId.toString(),
    moduleName: module.moduleName,
    contentType: intl.formatMessage({
      id: module.isStory ? 'label_story' : 'label_module',
    }),
    status: (
      <StatusSwitch
        isAssigned={module.assigned}
        moduleId={module.moduleId}
        disabled={module.assignedByInheritance || disableSwitches}
        updateOverviewSection={updateOverviewSection}
        setDisableSwitches={setDisableSwitches}
      />
    ),
    assignedByInheritance: module.assignedByInheritance ? (
      <InheritedByParent />
    ) : (
      ''
    ),
    linkedBehaviour: intl.formatMessage(
      { id: 'page.groupManagement.modules.table.linkedBehaviours.cell' },
      { number: module.linkedBehaviour?.length || 0 },
    ),
    linkedBehaviourList: module.linkedBehaviour,
  }));

const ModulesTab = ({ modules, updateOverviewSection }) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { learningCampaignEnabled } = useAppSelector(selectSettings);
  const [disableSwitches, setDisableSwitches] = useState(false);
  const [behaviourFilter, setBehaviourFilter] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  let filteredModules = modules;
  if (behaviourFilter) {
    filteredModules = modules.filter(
      (module) =>
        module.linkedBehaviour.filter(
          (behaviour) => behaviour.id === behaviourFilter,
        ).length > 0,
    );
  }
  useNonInitialEffect(() => {
    if (behaviourFilter) {
      searchParams.set('behaviourId', behaviourFilter);
    } else {
      searchParams.delete('behaviourId');
    }
    setSearchParams(searchParams);
  }, [behaviourFilter, location.pathname, navigate]);

  useEffect(() => {
    const behaviourId = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })?.behaviourId;
    if (behaviourId) {
      setBehaviourFilter(parseInt(behaviourId.toString(), 10));
    }
  }, [location.search]);

  return learningCampaignEnabled ? (
    <LearningCampaignsRedirect>
      <Heading as="h2" variant="h2">
        <Translation id="page.groupManagement.modules.learningCampaigns.heading" />
      </Heading>
      <Text as="p">
        <Translation id="page.groupManagement.modules.learningCampaigns.message" />
      </Text>
      <Text as="p">
        <Link to="/admin/learning-campaigns">
          <Translation id="page.groupManagement.modules.learningCampaigns.link" />
          {` >`}
        </Link>
      </Text>
    </LearningCampaignsRedirect>
  ) : (
    <>
      <BehaviourFilter
        setBehaviourFilter={setBehaviourFilter}
        behaviourId={behaviourFilter}
      />
      <CybDataTable
        pagination={false}
        data={getRows(
          filteredModules,
          updateOverviewSection,
          disableSwitches,
          setDisableSwitches,
          intl,
        )}
        columns={headers}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandableComponent>
            <Heading as="h3" variant="h6">
              <Translation id="page.groupManagement.modules.table.linkedBehaviours" />
            </Heading>
            <BehaviourList>
              {data.linkedBehaviourList.map((item) => (
                <FlexBox align="center" key={item.id}>
                  <BehaviourLink
                    key={item.id}
                    href={createBehaviourLink(item.sebdbUid)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SecurityBehaviourPill
                      color={theme.colorPalette.primary[300]}
                    >
                      {item.sebdbUid}
                    </SecurityBehaviourPill>

                    <BehaviourTextWrapper>
                      {item.shortTitle}
                    </BehaviourTextWrapper>
                  </BehaviourLink>
                </FlexBox>
              ))}
            </BehaviourList>
          </ExpandableComponent>
        )}
      />
    </>
  );
};

export default ModulesTab;
