import React from 'react';

import { useIntl } from 'react-intl';

import { useApiData } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { Dropdown } from 'components/Form/Inputs/Dropdowns';
import { flattenArray } from 'utils';

import { FilterContainer } from './GroupManagement.styles';

const BehaviourFilter = ({ setBehaviourFilter, behaviourId }) => {
  const intl = useIntl();
  const { data } = useApiData(
    '/api/v1/security-behaviour-categories/r1/index/',
  );
  if (!data) return null;
  const items = flattenArray(
    data.results.map((behaviourCategory) =>
      flattenArray(
        behaviourCategory.behaviours.map(({ id, shortTitle }) => ({
          id,
          label: shortTitle,
        })),
      ),
    ),
  );
  items.unshift({
    id: 'all',
    label: intl.formatMessage({
      id: 'common.all',
    }),
  });
  return (
    <FilterContainer>
      <Dropdown
        items={items}
        id="behaviour-filter"
        label={<Translation id="label_behaviourFilter" />}
        hideLabel
        onChange={({ selectedItem }) => {
          setBehaviourFilter(
            selectedItem.id === 'all' ? null : selectedItem.id,
          );
        }}
        selectedItem={items.find((item) => item.id === behaviourId) || 'all'}
      />
    </FilterContainer>
  );
};

export default BehaviourFilter;
