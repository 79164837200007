import React, { useEffect, useMemo, useRef, useState } from 'react';

import merge from 'lodash/merge';
import DataTable, { TableStyles } from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { DefaultTheme, useTheme } from 'styled-components';

import Translation from 'components/Content/Translation/Translation';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';

import Button from '../../Button';

import BatchActions from './BatchActions/BatchActions';
import {
  ACTIONS_TOOLBAR_HEIGHT,
  ActionsContainer,
  StyledCybDataTable,
  StyledNoData,
  ToolBar,
} from './CybDataTable.styles';
import { CybDataTableProps, NoDataInterface } from './CybDataTable.types';
import { SearchInput } from './SearchInput/SearchInput';

const customStyles = (theme: DefaultTheme): TableStyles => ({
  responsiveWrapper: {
    style: {
      maxHeight: 'max-content',
    },
  },
  headCells: {
    style: {
      padding: '1rem',
      backgroundColor: theme.componentColors.table.header.backgroundColor,
      color: theme.componentColors.table.header.textColor,
      fontWeight: 'bold',
      fontSize: '0.875rem',
      '& > div:hover': {
        opacity: 1,
      },
    },
  },
  cells: {
    style: {
      padding: '1rem',
      fontSize: '0.875rem',
      color: theme.componentColors.bodyTextColor,
    },
  },
  headRow: {
    style: {
      backgroundColor: theme.componentColors.table.header.backgroundColor,
      borderBottomWidth: '1px',
      borderBottomColor: theme.componentColors.table.cells.borderColor,
      minHeight: '3rem',
    },
  },
  header: {
    style: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  rows: {
    style: {
      minHeight: '3rem',
      '&:not(:last-of-type)': {
        borderBottomColor: theme.componentColors.table.cells.borderColor,
      },
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        backgroundColor: theme.componentColors.table.selected.backgroundColor,
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: theme.componentColors.table.hover.backgroundColor,
      transitionProperty: 'background-color',
      transitionDuration: '0s',
    },
  },
  contextMenu: {
    style: {
      backgroundColor: theme.colorPalette.core.white,
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative',
      top: `-${ACTIONS_TOOLBAR_HEIGHT}`,
      marginBottom: `-${ACTIONS_TOOLBAR_HEIGHT}`,
      transform: 'none !important',
      willChange: 'unset',
      color: theme.componentColors.bodyTextColor,
    },
  },
});

export const defaultNoDataProps = {
  headingLabel: 'component.cybDataTable.noData.headingLabel',
  messageLabel: 'component.cybDataTable.noData.messageLabel',
};

export const NoData = ({ headingLabel, messageLabel }: NoDataInterface) => (
  <StyledNoData data-testid="no-data-message">
    <img
      src="https://cybsafe-resources.s3.eu-west-1.amazonaws.com/visual+assets/Empty+states/Emptystate1.png"
      alt=""
    />
    <h3>
      <Translation id={headingLabel} />
    </h3>
    <p>
      <Translation id={messageLabel} />
    </p>
  </StyledNoData>
);

const CybDataTable = <T,>({
  data,
  clearSelectedRows = false,
  highlightOnHover = true,
  loading = false,
  onSelectedRowsChange,
  search,
  actions,
  batchActions,
  paginationTotalRows,
  paginationServer,
  paginationServerOptions = {
    persistSelectedOnPageChange: false,
    persistSelectedOnSort: false,
  },
  pagination = true,
  additionalStyles,
  noDataProps = defaultNoDataProps,
  className,
  contextComponent = null,
  sortServer = true,
  onChangePage,
  ignoreUpdateSelectedOnInitialRender,
  ...rest
}: CybDataTableProps<T>) => {
  const ref = useRef(null);
  // isRenderingData is used to prevent onSelectedRowsChange from firing when the table is first rendered and the page is changed. This is an issue when storing data in formik state. Without this, when changing page or initially loading data, onSelectedRowsChange is called with only the visible rows that are selected, so formik state would update to that instead of persisting the selection.
  const [isRenderingData, setIsRenderingData] = useState(true);
  const intl = useIntl();
  const theme = useTheme();
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    /* This is for handling the scenario where there is no data to display. The role should be
    changed from table to none in order for the NoData element to be read out with screen readers */
    if (data && ref.current) {
      const tableEl = ref.current.querySelector('[role="table"]');
      const noDataTableEl = ref.current.querySelector('[role="none"]');
      if (tableEl && data.length === 0) {
        tableEl.role = 'none';
        return;
      }
      if (noDataTableEl && data.length > 0) {
        noDataTableEl.role = 'table';
        return;
      }
    }
  }, [data]);

  const styles = useMemo(
    () =>
      additionalStyles
        ? merge(customStyles(theme), additionalStyles)
        : customStyles(theme),
    [additionalStyles, theme],
  );

  return (
    <StyledCybDataTable ref={ref} className={className}>
      <DataTable
        data={data}
        highlightOnHover={highlightOnHover}
        pagination={pagination}
        customStyles={styles}
        theme={theme.name === 'dark' ? 'dark' : 'default'}
        progressComponent={
          <div data-testid="table-loader">
            <LoaderBlock />
          </div>
        }
        progressPending={loading}
        noDataComponent={<NoData {...noDataProps} />}
        paginationComponentOptions={{
          rowsPerPageText: intl.formatMessage({
            id: 'component.pagination.rowsPerPage',
          }),
        }}
        onSelectedRowsChange={(selected) => {
          setAllSelected(selected.allSelected);
          if (loading) {
            return;
          }
          // This is a check if we should call the onSelectedRowsChange callback. If the table is pagination on server and isRendering data we don't want to call the callback as it will call it without the persisted selected rows. So you'll end up with incorrect state.
          // If allSelected is true we skip this and want to call the callback
          if (ignoreUpdateSelectedOnInitialRender && isRenderingData) {
            setIsRenderingData(false);
            return;
          }
          onSelectedRowsChange && onSelectedRowsChange(selected);
        }}
        onChangePage={(page, totalRows) => {
          setIsRenderingData(true);
          onChangePage && onChangePage(page, totalRows);
        }}
        clearSelectedRows={clearSelectedRows}
        /* use our own custom actions functionality */
        actions={
          (search || actions) && (
            <ToolBar>
              {search && (
                <SearchInput
                  {...search}
                  onSubmit={(searchTerm) => {
                    setIsRenderingData(true);
                    search.onSubmit && search.onSubmit(searchTerm);
                  }}
                />
              )}
              {actions && (
                <ActionsContainer>
                  {Array.isArray(actions)
                    ? actions.map((action, index) => (
                        <Button key={`${action.id}-${index}`} {...action} />
                      ))
                    : actions}
                </ActionsContainer>
              )}
            </ToolBar>
          )
        }
        paginationTotalRows={paginationTotalRows}
        contextComponent={
          batchActions?.length > 0 ? (
            <BatchActions
              batchActions={batchActions}
              allSelectedCount={allSelected ? paginationTotalRows : null}
            />
          ) : (
            contextComponent
          )
        }
        sortServer={sortServer}
        paginationServer={paginationServer}
        paginationServerOptions={paginationServerOptions}
        {...rest}
      />
    </StyledCybDataTable>
  );
};

export default CybDataTable;

// NOTE: There is an issue with persisiting table selection that cannot be resolved using the react-data-table-component currently.
// The issue is if the table is used and reopened, there is no way to pass in the initial selection rows, so the onSelectedRowsChange callback is called with the visible rows that are selected, not the persisted selection.
// https://github.com/jbetancur/react-data-table-component/issues/1184
