import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useApiPost } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { InsightsStateInterface } from 'components/Widgets/Insights/Insights.types';

import FeedbackSection from './FeedbackSection';
import {
  Content,
  ContentContainer,
  ContentHeader,
  SubmissionMessage,
} from './Insights.styles';
import QuestionSection from './QuestionSection';

const InsightsContent = () => {
  const params = useParams<'module' | 'section'>();
  const finalMessageRef = useRef(null);
  const [insight, setInsight] = useState<InsightsStateInterface>({
    type: null,
    anonymous: false,
    feedback: '',
  });
  const [insightSuccessful, setInsightSuccessful] = useState(false);
  const [postInsight, { error: insightError }] = useApiPost(
    '/api/v1/insights/create/r1',
  );

  useEffect(() => {
    if (insightSuccessful || insightError) {
      finalMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [insightSuccessful, insightError]);

  const submitInsight = async () => {
    const body: any = {
      typeId: insight.type,
      isAnon: +insight.anonymous,
      content: insight.feedback,
    };
    if (params.module && params.section) {
      body.pageSlug = params.section;
    }
    try {
      await postInsight(body);
      setInsightSuccessful(true);
    } catch (e) {}
  };

  return (
    <ContentContainer data-testid="insights-content">
      <ContentHeader>
        <div>
          <p>
            <Translation id="component.insights.title" />
          </p>
          <p>
            <Translation id="component.insights.subtitle" />
          </p>
        </div>
      </ContentHeader>
      <Content data-testid="feedback-questions-section">
        <p>
          <Translation id="component.insights.intro" />
        </p>
        <QuestionSection setInsight={setInsight} />
      </Content>
      {insight.type !== null && (
        <Content data-testid="feedback-input-section">
          <FeedbackSection
            setInsight={setInsight}
            submitInsight={submitInsight}
            disableSubmitBtn={
              !insight.feedback || insightSuccessful || insightError
            }
          />
          {(insightSuccessful || insightError) && (
            <SubmissionMessage
              ref={finalMessageRef}
              data-testid={
                insightSuccessful
                  ? 'submission-message-success'
                  : 'submission-message-error'
              }
            >
              {insightSuccessful && (
                <Translation
                  id={
                    insight.anonymous
                      ? 'component.insights.submit.successful.anonymous'
                      : 'component.insights.submit.successful'
                  }
                />
              )}
              {insightError && (
                <Translation id="component.insights.submit.error" />
              )}
            </SubmissionMessage>
          )}
        </Content>
      )}
    </ContentContainer>
  );
};

export default InsightsContent;
