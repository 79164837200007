import React, { Fragment } from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import Translation from 'components/Content/Translation/Translation';

export interface MoreLinkProps extends Omit<LinkProps, 'to'> {
  toPage: string | ToPageType;
  label: string;
  useTextLiteral?: boolean;
  size?: string;
}

const Link = styled(ReactRouterLink)<Pick<MoreLinkProps, 'size'>>`
  font-size: ${({ theme, size }) =>
    size ? theme.fontSizes[size] : theme.fontSizes.s};
  color: ${({ theme }) => theme.componentColors.moreLinkColor};
`;

type ToPageType = {
  pathname: string;
  search: string;
  hash: string;
  state: Record<string, any>;
};

const MoreLink = ({
  className,
  toPage,
  size,
  useTextLiteral,
  label,
  ...rest
}: MoreLinkProps) => (
  <Link className={className} to={toPage} size={size} {...rest}>
    {useTextLiteral ? (
      label
    ) : (
      <Fragment>
        <Translation id={label} />
        {` `}
        <span aria-hidden="true">{String.fromCharCode(62)}</span>
      </Fragment>
    )}
  </Link>
);

export default MoreLink;
