import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { unit } from 'theme';

export const StyledForm = styled.form`
  > *:not(:last-child) {
    margin-bottom: ${unit(3)};
  }

  .cds--accordion__item--active {
    .cds--accordion__content {
      padding: ${unit(2)} 0px ${unit(2)} 1rem;

      > *:not(:last-child) {
        margin-bottom: ${unit(3)};
      }
    }
  }
`;

export const FormButtonBlock = styled.div<{ $singleButton?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${unit(1)};

  ${({ $singleButton }) =>
    $singleButton &&
    css`
      justify-content: flex-end;
    `}
`;

export const SinglePageFormWrapper = styled(Form)`
  margin: 0 auto;
  width: 80%;
`;

export const StyledFormContentWrapper = styled.div`
  z-index: 1;
  gap: ${unit(4)};
  display: grid;
  padding-top: ${unit(2)};

  #error-handler-notification {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Unsets max-width property on language accordion to display flags */
  h4 img {
    max-width: unset;
  }
`;
