import React, { useState } from 'react';

import { Popover, PopoverContent } from '@carbon/react';

import Button, { ButtonProps } from '../../index';

type PopoverButtonProps = ButtonProps & {
  /**
   * The child component that is visible when the button has been clicked.
   */
  children: React.ReactNode;
};

const PopoverButton = ({ children, ...rest }: PopoverButtonProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      open={open}
      onRequestClose={() => setOpen(false)}
      align="bottom"
      isTabTip
    >
      <Button aria-expanded={open} onClick={() => setOpen(!open)} {...rest} />
      <PopoverContent>{children}</PopoverContent>
    </Popover>
  );
};

export { type PopoverButtonProps };

export default PopoverButton;
