import React from 'react';

function Respond() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 193 160"
    >
      <g clipPath="url(#clip0_861_4160)">
        <path
          fill="#339898"
          d="M130.042 76.308c11.967 5.802 25.894 2.904 34.629-6.207A29.925 29.925 0 00170 62.425c4.253-8.788 3.829-18.629-.297-26.699a29.787 29.787 0 00-13.58-13.285 29.098 29.098 0 00-1.832-.814 29.711 29.711 0 00-8.525-2.066c-12.031-1.085-24.024 5.244-29.604 16.765-7.201 14.877-.988 32.776 13.877 39.984l.003-.002zm-7.141-36.718c4.183-8.64 13.176-13.383 22.195-12.57a22.25 22.25 0 016.393 1.553 22.329 22.329 0 0111.553 10.57c3.094 6.05 3.413 13.43.223 20.018a22.387 22.387 0 01-3.996 5.754c-6.548 6.832-16.991 9.004-25.963 4.655-11.145-5.403-15.804-18.825-10.405-29.977v-.003z"
        ></path>
        <path
          fill="url(#paint0_linear_861_4160)"
          d="M163.042 39.141l6.664-3.415c4.123 8.07 4.549 17.911.296 26.7a29.933 29.933 0 01-5.329 7.675l-5.402-5.189a22.476 22.476 0 003.996-5.754c3.19-6.588 2.871-13.967-.223-20.017h-.002z"
        ></path>
        <path
          fill="#94C7C4"
          d="M151.489 28.57l2.804-6.945c.615.248 1.228.521 1.832.813a29.803 29.803 0 0113.581 13.286l-6.664 3.415a22.329 22.329 0 00-10.179-9.96 22.997 22.997 0 00-1.374-.61v.002z"
        ></path>
        <path
          fill="url(#paint1_linear_861_4160)"
          d="M145.768 19.56c2.868.257 5.739.936 8.525 2.065l-2.804 6.945a22.375 22.375 0 00-6.393-1.552l.672-7.461v.002z"
        ></path>
        <path
          fill="#F8F8F8"
          d="M74.65 9.472H33.91a2.12 2.12 0 00-2.119 2.12v31.67c0 1.172.949 2.12 2.119 2.12H74.65a2.12 2.12 0 002.118-2.12v-31.67a2.12 2.12 0 00-2.118-2.12z"
        ></path>
        <path
          fill="#F0F0F0"
          d="M58.1 13.23H40.011v2.983H58.1V13.23zM63.904 24.6H50.918v2.607h12.986V24.6zM63.904 30.862H50.918v2.607h12.986v-2.607zM63.904 36.973H50.918v2.608h12.986v-2.608z"
        ></path>
        <path
          fill="url(#paint2_linear_861_4160)"
          d="M47.765 23.384h-7.669a.337.337 0 00-.337.338v4.044c0 .186.151.337.337.337h7.67a.337.337 0 00.336-.337v-4.044a.337.337 0 00-.337-.338z"
        ></path>
        <path
          fill="url(#paint3_linear_861_4160)"
          d="M47.765 29.615h-7.669a.337.337 0 00-.337.337v4.044c0 .186.151.337.337.337h7.67a.337.337 0 00.336-.337v-4.044a.337.337 0 00-.337-.337z"
        ></path>
        <path
          fill="#F0F0F0"
          d="M47.765 35.845h-7.669a.337.337 0 00-.337.337v4.044c0 .187.151.338.337.338h7.67a.337.337 0 00.336-.338v-4.044a.337.337 0 00-.337-.337z"
        ></path>
        <path
          fill="#F8F8F8"
          d="M61.86 51.371H21.12A2.12 2.12 0 0019 53.491v40.767c0 1.17.949 2.12 2.119 2.12H61.86a2.12 2.12 0 002.118-2.12V53.49a2.12 2.12 0 00-2.118-2.12z"
        ></path>
        <path
          fill="#F0F0F0"
          d="M47.776 55.192H25.07v3.745h22.707v-3.745zM57.632 68.528H25.07v14.74h32.563v-14.74zM57.844 88.014H34.569v1.507h23.275v-1.507z"
        ></path>
        <path
          fill="url(#paint4_linear_861_4160)"
          d="M51.362 88.014H25.07v1.507h26.293v-1.507z"
        ></path>
        <mask
          id="mask0_861_4160"
          style={{ maskType: 'luminance' }}
          width="33"
          height="16"
          x="25"
          y="68"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M57.632 68.528H25.07v14.74h32.563v-14.74z"
          ></path>
        </mask>
        <g mask="url(#mask0_861_4160)">
          <path
            fill="url(#paint5_linear_861_4160)"
            d="M22.682 81.4c1.332-1.087 2.827-2.176 4.545-2.224 1.549-.041 2.977.775 4.479 1.16a8.27 8.27 0 005.932-.731c2.431-1.318 4.17-3.844 6.815-4.651 1.462-.447 3.027-.308 4.556-.358a18.262 18.262 0 0011.774-4.887c.184 2.715.148 5.325.332 8.04.069 1 .11 2.095-.487 2.9-.36.485-.907.793-1.435 1.087-1.643.91 2.37 1.54.727 2.453.23.137-1.188 1.555-.923 1.509-6.999 2.701-18.716.98-26.216.898-2.889-.032-5.82.02-8.616-.718-1.078-.285-2.294-.887-2.427-1.995-.05-.415.071-.832.19-1.233.148-.504.343-.916.751-1.247l.003-.002z"
          ></path>
        </g>
        <path
          fill="#D3DAE0"
          d="M81.627 52.466h45.863a.32.32 0 01.321.321v2.665a.32.32 0 01-.321.321H81.627a.32.32 0 01-.322-.321v-2.665a.32.32 0 01.322-.321zM81.627 59.758h32.455c.178 0 .322.144.322.322v2.664a.321.321 0 01-.322.322H81.627a.32.32 0 01-.322-.322v-2.662a.32.32 0 01.322-.322v-.002zM81.627 67.053H120.3c.177 0 .321.144.321.321v2.665a.321.321 0 01-.321.322H81.627a.32.32 0 01-.322-.322v-2.662a.32.32 0 01.322-.322v-.002z"
        ></path>
        <path
          fill="#C4CBD0"
          d="M106.63 81.189h-1.626v6.836h1.626V81.19z"
        ></path>
        <path
          fill="#C4CBD0"
          d="M109.234 85.422v-1.625h-6.832v1.625h6.832z"
        ></path>
        <path
          fill="#9A8E8C"
          d="M91.316 26.952h19.294c.736 0 1.333-.597 1.333-1.334v-5.477c0-.737-.597-1.334-1.333-1.334H91.316c-.736 0-1.333.597-1.333 1.334v5.477c0 .737.597 1.334 1.333 1.334z"
        ></path>
        <path
          fill="#9A8E8C"
          d="M99.675 20.567h2.576V4.51h-2.576v16.056z"
        ></path>
        <path
          fill="#339898"
          d="M100.964 6.798a3.398 3.398 0 003.397-3.4 3.398 3.398 0 10-3.397 3.4z"
        ></path>
        <path
          fill="url(#paint6_linear_861_4160)"
          d="M65.016 58.415c0-19.208 15.56-34.778 34.757-34.778 19.196 0 34.757 15.57 34.757 34.778 0 .365-.016.723-.028 1.086.009.18.028.355.028.538v19.2c0 5.74-4.696 10.44-10.432 10.44h-48.65c-5.737 0-10.432-4.7-10.432-10.44v-19.2c0-.183.018-.358.027-.538-.011-.36-.027-.72-.027-1.086z"
        ></path>
        <path
          fill="#fff"
          d="M110.378 37.742c.241.111.554.27.786.373 1.629.723 1.968 2.303 1.346 3.344-.549.92-1.253 1.5-2.85 1.124-.145-.034-.888-.426-1.048-.465-12.614-6.608-26.393 3.133-27.124 16.302-.559 10.033 7.882 18.65 18.046 19.17 3.772.23 5.832-.422 6.577-.575 1.506-.308 2.554 1.032 2.706 1.942.128.766-.1 1.347-.46 1.824-.512.604-.854.745-1.223.852-7.673 2.21-17.538 1.006-24.47-6.797-6.868-7.733-7.859-19.623-2.37-28.33 3.745-5.938 9.81-9.633 15.706-10.534 5.074-.775 8.851-.772 14.371 1.77h.007zM118.612 44.594a2.324 2.324 0 11-4.572.833 2.324 2.324 0 014.572-.833z"
        ></path>
        <path
          fill="#fff"
          d="M113.495 76.03c-1.137-1.121-.499-2.893-.199-3.205.873-.914 1.634-1.726 3.156-3.618 1.955-2.569.87-4.771-.47-6.061-1.747-1.68-4.472-1.56-6.604.123-.417.328-.934 1.067-1.385 1.527-2.864 2.907-8.756 5.328-13.617 1.028-2.153-1.903-3.25-4.306-2.946-7.96.296-3.575 3.593-7.136 7.24-7.717.435-.068 1.301-.184 1.675-.184 1.23 0 2.371.952 2.33 2.375-.038 1.327-.797 2.23-2.344 2.352-.057.005-.196.026-.255.028-1.802.132-3.433 1.363-3.99 3.48-.195.746-.125 3.101 1.609 4.441 2.481 1.92 5.577.502 7.311-1.506 3.846-4.452 9.917-5.102 13.665-1.908 2.759 2.35 5.206 7.167 1.845 12.182-1.752 2.653-2.841 3.668-3.511 4.37-.872.914-2.471 1.27-3.506.25l-.004.003z"
        ></path>
        <path
          fill="url(#paint7_linear_861_4160)"
          d="M70.247 104.806c0-5.998 4.739-10.876 10.673-11.12l.016-.023h36.666l.016.023c5.935.244 10.674 5.122 10.674 11.12 0 1.19-.192 2.334-.536 3.41l.005.007s-3.231 13.935-10.191 23.51c-1.064 1.798-2.508 3.41-4.249 4.75-3.405 2.624-7.93 4.225-12.897 4.225-.387 0-.772-.014-1.155-.032a24.57 24.57 0 01-1.155.032c-4.966 0-9.49-1.601-12.897-4.225-1.74-1.34-3.183-2.952-4.249-4.75-6.96-9.575-10.19-23.51-10.19-23.51l.004-.007a11.107 11.107 0 01-.535-3.41z"
        ></path>
        <path
          fill="url(#paint8_linear_861_4160)"
          d="M63.76 104.523c3.218 1.712 6.68 2.065 9.48-1.34a7.317 7.317 0 001.397-6.518c-.659-2.42-2.575-3.98-4.714-5.095-1.827-.95-3.56-2.047-5.267-3.198a136.442 136.442 0 01-7.022-5.104c-1.471-1.138-2.784-2.515-4.422-3.429-1.118-.624-2.476-1.046-3.75-.69-1.223.34-2.055 1.486-2.077 2.753-.19 3.146 1.371 6.24 2.986 8.832 1.798 2.886 3.996 5.52 6.32 7.997 1.818 1.935 4.358 4.347 7.072 5.792h-.002z"
        ></path>
        <path
          fill="#428D88"
          d="M147.701 130.786l-7.489-31.24-41.302 9.913 7.49 31.241 41.301-9.914z"
        ></path>
        <path
          fill="#FAFAFA"
          d="M145.548 129.636l-6.735-28.09-38.213 9.173 6.735 28.09 38.213-9.173z"
        ></path>
        <path
          fill="#000"
          d="M106.365 130.638l-2.925-12.199-1.134.272 2.924 12.199 1.135-.272z"
          opacity="0.12"
        ></path>
        <path
          fill="#35726C"
          d="M103.965 131.224l2.41-.579-2.925-12.198-2.411.579c-1.191.285-1.888 1.637-1.556 3.018l.046.189-.125.03c-1.192.285-1.889 1.637-1.556 3.018l.435 1.819c.33 1.382 1.565 2.269 2.756 1.984l.126-.03.045.189c.331 1.382 1.565 2.269 2.757 1.984l-.002-.003zm-3.634-3.248a.616.616 0 01-.736-.456l-.77-3.214a.612.612 0 01.449-.739.617.617 0 01.736.456l.77 3.214a.612.612 0 01-.449.739z"
        ></path>
        <g fill="#829095" opacity="0.47">
          <path d="M116.896 115.185l4.085-.98a.196.196 0 00.13-.094l.002-.004c0-.003.005-.007.005-.009a.217.217 0 00.022-.16l-.988-4.124a.225.225 0 00-.265-.164l-4.085.98a.224.224 0 00-.161.267l.988 4.124a.225.225 0 00.265.164h.002zm.109-.481l-.886-3.695 3.661-.878.886 3.695-3.661.878zM124.451 113.373c1.362-.326 2.724-.655 4.085-.981a.198.198 0 00.129-.093s0-.002.003-.005l.004-.009a.214.214 0 00.023-.159c-.33-1.375-.658-2.75-.989-4.124a.223.223 0 00-.264-.164l-4.085.98a.224.224 0 00-.162.267c.331 1.374.659 2.749.989 4.123.027.117.15.192.264.165h.003zm.109-.481l-.886-3.696c1.221-.291 2.44-.586 3.661-.877l.886 3.695-3.661.878zM132.005 111.558l4.085-.98a.196.196 0 00.13-.094l.002-.004c0-.003.005-.007.005-.009a.222.222 0 00.023-.16c-.331-1.375-.659-2.749-.989-4.124a.224.224 0 00-.264-.164l-4.085.98a.225.225 0 00-.162.267c.33 1.375.658 2.749.989 4.124.027.116.15.191.264.164h.002zm.11-.481c-.297-1.231-.59-2.462-.887-3.695l3.662-.878.886 3.695-3.661.878z"></path>
        </g>
        <path
          fill="#428D88"
          d="M117.523 111.916l-.087 1.021 1.066.303-2.148 5.305.23.502 3.014-6.294-2.075-.837z"
        ></path>
        <path
          fill="#829095"
          d="M139.556 127.698l-3.488-14.549-2.042.491 3.488 14.548 2.042-.49zM131.555 129.618l-3.488-14.548-2.043.49 3.488 14.549 2.043-.491zM123.554 131.536l-3.488-14.548-2.043.49 3.488 14.549 2.043-.491z"
          opacity="0.32"
        ></path>
        <path
          fill="url(#paint9_linear_861_4160)"
          d="M135.448 100.648c-1.59-3.274-4.181-5.597-8.448-4.493a7.302 7.302 0 00-4.964 4.438c-.893 2.339-.262 4.728.808 6.887.916 1.842 1.673 3.745 2.374 5.681a137.88 137.88 0 012.671 8.25c.519 1.784.77 3.667 1.553 5.368.538 1.163 1.385 2.3 2.625 2.763 1.189.444 2.536.009 3.301-1.001 2.005-2.428 2.565-5.843 2.786-8.886.246-3.387.023-6.807-.398-10.171-.328-2.631-.962-6.073-2.304-8.836h-.004z"
        ></path>
        <path
          fill="#E2D9DE"
          d="M100.572 160.002c15.078 0 27.301-2.1 27.301-4.691 0-2.591-12.223-4.692-27.301-4.692-15.077 0-27.3 2.101-27.3 4.692s12.223 4.691 27.3 4.691z"
          opacity="0.3"
        ></path>
        <path
          fill="#E2D9DE"
          d="M100.572 158.327c9.698 0 17.559-1.351 17.559-3.016 0-1.666-7.861-3.016-17.559-3.016-9.697 0-17.558 1.35-17.558 3.016 0 1.665 7.861 3.016 17.558 3.016z"
          opacity="0.3"
        ></path>
        <path
          fill="#E2D9DE"
          d="M100.572 157.517c7.092 0 12.841-.987 12.841-2.206 0-1.219-5.749-2.207-12.841-2.207-7.091 0-12.84.988-12.84 2.207 0 1.219 5.749 2.206 12.84 2.206z"
          opacity="0.3"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_861_4160"
          x1="176.297"
          x2="156.072"
          y1="48.178"
          y2="58.898"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#309F97"></stop>
          <stop offset="0.25" stopColor="#2A8F88"></stop>
          <stop offset="0.62" stopColor="#1D6661"></stop>
          <stop offset="1" stopColor="#0D3733"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_861_4160"
          x1="154.118"
          x2="144.706"
          y1="21.309"
          y2="26.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8EB31"></stop>
          <stop offset="1" stopColor="#E5B641"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_861_4160"
          x1="39.759"
          x2="48.102"
          y1="25.744"
          y2="25.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3AAFA4"></stop>
          <stop offset="1" stopColor="#069890"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_861_4160"
          x1="39.759"
          x2="48.102"
          y1="31.974"
          y2="31.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8EB31"></stop>
          <stop offset="1" stopColor="#E5B641"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_861_4160"
          x1="25.069"
          x2="51.36"
          y1="88.769"
          y2="88.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3AAFA4"></stop>
          <stop offset="1" stopColor="#069890"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_861_4160"
          x1="21.729"
          x2="61.154"
          y1="78.46"
          y2="78.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3AAFA4"></stop>
          <stop offset="1" stopColor="#069890"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_861_4160"
          x1="153.063"
          x2="51.473"
          y1="70.377"
          y2="48.677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_861_4160"
          x1="55.844"
          x2="152.844"
          y1="166.413"
          y2="39.321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_861_4160"
          x1="68.9"
          x2="-5.39"
          y1="97.994"
          y2="57.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_861_4160"
          x1="133.813"
          x2="139.388"
          y1="128.895"
          y2="158.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <clipPath id="clip0_861_4160">
          <path fill="#fff" d="M0 0H193V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Respond;
