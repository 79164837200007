import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SubgroupLink = styled(Link)`
  color: ${({ theme }) => theme.colorPalette.grey[800]};
  font-size: ${({ theme }) => theme.fontSizes.m};
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 1rem;

  &:hover {
    background: ${({ theme }) => theme.colorPalette.grey[100]};
    cursor: pointer;
  }
`;

export const LinkList = styled.ul`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorPalette.primary[300]};
    outline-style: inset;
  }
`;

export const LinkListItem = styled.li`
  & > :first-child {
    border-bottom: 1px solid ${({ theme }) => theme.colorPalette.grey[200]};
  }
`;
