// Polyfills
import 'react-app-polyfill/ie9'; // This is required for IE 11 fetch polyfill

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/array';
import 'core-js/es/string';
import 'core-js/es/object';

import 'raf/polyfill';
import 'classlist-polyfill';
import 'array-includes';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Node.prototype.children';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Element.prototype.matches';
import 'intl';

import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store, { persistor } from './store/createStore';

const initApp = (App: any) => {
  const container = document.getElementById('react-root');
  const root = createRoot(container!);
  root.render(
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>,
  );
};

initApp(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./App').default;
    initApp(NextApp);
  });
}

/*
  Disable React Intl error messages as they clutter the console,
  translations always come after development so they are expected,
  and we can see missing messages anyway as the label is displayed.
 */
// eslint-disable-next-line
const consoleError = console.error.bind(console);

// eslint-disable-next-line no-console
console.error = (message: string, ...args: any) => {
  if (
    typeof message === 'string' &&
    (message.startsWith('[React Intl] Missing message:') ||
      message.startsWith('[React Intl] Cannot format message:') ||
      message.includes(
        'Failed prop type: Invalid prop `title` of type `object` supplied to `SideNavMenu`',
      ))
  ) {
    return;
  }

  consoleError(message, ...args);
};
