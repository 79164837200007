import React from 'react';

import { SymposiumLevels } from 'store/features/mainSliceTypes';

import SubscriptionBanner from './components/SubscriptionBanner';
import SubscriptionCallout from './components/SubscriptionCallout';

export type SubscriptionFullPageLevelHOCProps = {
  level?: SymposiumLevels;
  children: React.ReactNode;
  hasNoSubscription: boolean;
};
const SubscriptionFullPageLevelHOC = ({
  children,
  level,
  hasNoSubscription,
}: SubscriptionFullPageLevelHOCProps) => {
  if (hasNoSubscription && level) {
    return (
      <>
        <SubscriptionBanner level={level} />
        {children}
        <SubscriptionCallout level={level} />
      </>
    );
  } else {
    return <>{children}</>;
  }
};

export default SubscriptionFullPageLevelHOC;
