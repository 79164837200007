import React from 'react';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Breadcrumbs, {
  BreadcrumbInterface,
} from 'components/Breadcrumbs/Breadcrumbs';
import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import {
  SubscriptionFullPageLevelHOC,
  useSubscriptionContext,
} from 'components/Subscription';

import ReportFavouriteStar from './components/ReportFavourite/ReportFavouriteStar';
import {
  ActionLinkContainer,
  DemoLabelHeading,
  PageHeaderRoot,
  PageSubtitle,
  PageTitle,
  TitleAndFilterContainer,
  TitleContainer,
} from './PageHeader.styles';

interface PageTitleInterface {
  title: string;
  dynamic?: boolean;
}

export interface PageHeaderInterface {
  pageTitle?: string;
  dynamicTitle?: boolean;
  subtitle?: string;
  dynamicSubtitle?: boolean;
  breadcrumbs?: Array<BreadcrumbInterface>;
  $hideTitle?: boolean;
  actionLink?: React.ReactNode;
  canFavouritePage?: boolean;
}

interface ExampleDataLabelProps {
  hasNoSubscription?: boolean;
}

const DemoDataLabel = ({ hasNoSubscription }: ExampleDataLabelProps) => {
  if (hasNoSubscription) {
    return (
      <DemoLabelHeading as="h2">
        <Translation id="common.subscription.header.demo.label" />
      </DemoLabelHeading>
    );
  }

  return null;
};

const Title = ({ dynamic, title }: PageTitleInterface) =>
  dynamic ? (
    <span>{title}</span>
  ) : (
    <Translation id={title.includes('.') ? title : `label_${title}`} />
  );

const SubTitle = ({ dynamic, title }: PageTitleInterface) =>
  dynamic ? <span>{title}</span> : <Translation id={title} />;

const PageHeader = ({
  pageTitle,
  dynamicTitle,
  subtitle,
  dynamicSubtitle,
  breadcrumbs,
  $hideTitle,
  actionLink,
  canFavouritePage,
}: PageHeaderInterface) => {
  const { level, hasNoSubscription } = useSubscriptionContext();
  return (
    <SubscriptionFullPageLevelHOC
      hasNoSubscription={hasNoSubscription}
      level={level}
    >
      <PageHeaderRoot $hasNoSubscription={hasNoSubscription}>
        {breadcrumbs && (
          <FlexBox>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {!pageTitle && canFavouritePage && <ReportFavouriteStar />}
          </FlexBox>
        )}
        <TitleAndFilterContainer>
          {pageTitle && (
            <TitleContainer>
              <PageTitle $hideTitle={$hideTitle} data-testid="page-title">
                <Title title={pageTitle} dynamic={dynamicTitle} />
                {canFavouritePage && <ReportFavouriteStar />}
              </PageTitle>
              {subtitle && (
                <PageSubtitle data-testid="page-subtitle">
                  <SubTitle title={subtitle} dynamic={dynamicSubtitle} />
                </PageSubtitle>
              )}
            </TitleContainer>
          )}
          {actionLink && (
            <ActionLinkContainer>
              {actionLink}
              <FontAwesomeIcon icon={faChevronRight} />
            </ActionLinkContainer>
          )}
          {hasNoSubscription && <DemoDataLabel hasNoSubscription />}
        </TitleAndFilterContainer>
      </PageHeaderRoot>
    </SubscriptionFullPageLevelHOC>
  );
};

export default PageHeader;
