export const checkSelectedIndex = (pathname) => {
  switch (pathname) {
    case '/questionnaire':
      return 'label_welcome';

    case '/quiz/ia-knowledge-questions':
      return 'label_knowledge_check';

    case '/survey/company-feedback':
      return 'label_company_feedback';

    case '/results/ia-knowledge-questions':
    case '/survey-debrief':
      return 'label_thank_you';

    default:
      /* Culture assessment is default due to dynamic culture survey URLs */
      return 'label_culture_assessment';
  }
};

export const progressPages = (cultureQuery) => {
  switch (cultureQuery) {
    //culture on + IA on
    case 'all':
      return [
        {
          id: 'welcome-step',
          label: 'label_welcome',
        },
        {
          id: 'culture-assessment-step',
          label: 'label_culture_assessment',
        },
        {
          id: 'company-feedback-step',
          label: 'label_company_feedback',
        },
        {
          id: 'knowledge-check-step',
          label: 'label_knowledge_check',
        },
        {
          id: 'thank-you-step',
          label: 'label_thank_you',
        },
      ];
    // Culture On + Ia Off
    case 'ca':
      return [
        {
          id: 'welcome-step',
          label: 'label_welcome',
        },
        {
          id: 'culture-assessment-step',
          label: 'label_culture_assessment',
        },
        {
          id: 'company-feedback-step',
          label: 'label_company_feedback',
        },
        {
          id: 'thank-you-step',
          label: 'label_thank_you',
        },
      ];
    //culture off IA on
    case 'ia':
      return [
        {
          id: 'welcome-step',
          label: 'label_welcome',
        },
        {
          id: 'knowledge-check-step',
          label: 'label_knowledge_check',
        },
        {
          id: 'thank-you-step',
          label: 'label_thank_you',
        },
      ];
    case 'ca-standalone':
      return [
        {
          id: 'culture-assessment-step',
          label: 'label_culture_assessment',
        },
        {
          id: 'company-feedback-step',
          label: 'label_company_feedback',
        },
        {
          id: 'thank-you-step',
          label: 'label_thank_you',
        },
      ];
    default:
      return null;
  }
};

export const selectedIndex = (progressPages, pathName) =>
  progressPages &&
  progressPages.findIndex(
    (page) => page.label === checkSelectedIndex(pathName),
  );

export const getCultureSurveySlug = (useCultureSurveyV2 = true) => {
  // Since null will evaluate to a falsy value (and is the default value in the settingsSlice
  // initial state), we need to explicitly check if the argument is null and set that to
  // the desired default, i.e. true.
  if (useCultureSurveyV2 === null) {
    useCultureSurveyV2 = true;
  }
  return useCultureSurveyV2 ? 'culture-survey-v2' : 'culture-survey';
};
