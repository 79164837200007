import styled from 'styled-components';

import { InputWrapper } from 'components/Form/Inputs/Inputs.styles';
import { unit } from 'theme';

export const ScheduledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${unit(10)};

  & .cds--inline-notification__text-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  & .cds--inline-notification__subtitle div {
    display: block;
    margin-bottom: 0px;
  }

  ${InputWrapper} {
    width: auto;
  }
`;

export const NotificationWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DeliveryMethodsContainer = styled.div<{
  $isGrid: boolean;
}>`
  ${({ $isGrid }) =>
    $isGrid &&
    `
    display: grid;
    grid-template-columns: 2fr 2fr;
    `}
`;

export const ScheduledHelpText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.m};
  display: flex;
  flex-direction: column;
  gap: ${unit(1)};
`;

const Pill = styled.div`
  border-radius: 5px;
  padding: 8px 16px;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.fontSizes.l};
`;

export const LibraryAuthorPill = styled(Pill)<{ $isCustom?: boolean }>`
  background: ${({ theme }) => theme.colorPalette.ui.blue[500]};
  color: ${({ theme }) => theme.colorPalette.core.white};

  ${({ $isCustom, theme }) =>
    $isCustom &&
    `
    background: ${theme.colorPalette.ui.orange[500]};
  `}
`;
export const SecurityBehaviourPill = styled(Pill)<{
  $color?: string;
  $fit?: boolean;
}>`
  border: 1px solid
    ${({ theme, $color }) => $color || theme.colorPalette.ui.blue[500]};
  color: ${({ theme, $color }) => $color || theme.colorPalette.ui.blue[500]};
  background: ${({ theme }) => theme.colorPalette.core.ghostWhite};
  margin-right: ${unit(2)};
  display: flex;
  flex: 0 0 auto;

  ${({ $fit }) =>
    $fit &&
    `
    width: fit-content;
  `}
`;
