const identity = (x) => x;
const getUndefined = () => {
  return;
};
const getType = (action) => action.type;
const filter = () => true;

export default function createSentryMiddleware(Sentry, options: any = {}) {
  // TODO: Validate options.
  const {
    breadcrumbDataFromAction = getUndefined,
    breadcrumbMessageFromAction = getType,
    actionTransformer = identity,
    stateTransformer = identity,
    breadcrumbCategory = 'redux-action',
    filterBreadcrumbActions = filter,
    getUserContext,
    getTags,
  } = options;
  return (store) => {
    let lastAction;
    Sentry.getCurrentScope().addEventProcessor((event, _hint) => {
      const state = store.getState();
      const reduxExtra = {
        lastAction: actionTransformer(lastAction),
        state: stateTransformer(state),
      };
      event.extra = Object.assign(reduxExtra, event.extra);

      if (getUserContext) {
        event.user = getUserContext(state);
      }

      if (getTags) {
        event.tags = getTags(state);
      }

      return event;
    });
    return (next) => (action) => {
      // Log the action taken to Raven so that we have narrative context in our
      // error report.
      if (filterBreadcrumbActions(action)) {
        Sentry.addBreadcrumb({
          category: breadcrumbCategory,
          message: breadcrumbMessageFromAction(action),
          level: 'info',
          data: breadcrumbDataFromAction(action),
        });
      }

      lastAction = action;
      return next(action);
    };
  };
}
