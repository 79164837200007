import styled from 'styled-components';

import { unit } from 'theme';

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: ${unit(3)};
`;

export const LanguageContainer = styled.div`
  width: 320px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  height: 40px;
  padding-top: 24px;
  align-items: center;
  margin: 0px 16px;

  & > * {
    cursor: pointer;
    margin-right: ${unit(1.5)};
    font-size: ${({ theme }) => theme.fontSizes.s} !important;
    color: ${({ theme }) => theme.componentColors.footer.linkColor} !important;
    text-decoration: underline;
    line-height: 1rem !important;

    background: transparent !important;
    border: none !important;
    padding: 0px !important;
  }
`;
