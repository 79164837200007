import { useCallback, useContext, useReducer } from 'react';

import { Options } from './ApiData';
import ApiDataContext from './ApiDataContext';

const INITIAL_STATE = {
  data: null,
  error: null,
  success: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_START': {
      return { ...INITIAL_STATE, data: state.data, loading: true };
    }

    case 'REQUEST_SUCCESS': {
      return {
        data: action.data,
        error: null,
        success: true,
        loading: false,
      };
    }

    case 'REQUEST_ERROR': {
      return {
        data: null,
        error: action.error,
        success: null,
        loading: false,
      };
    }

    default:
      throw new Error();
  }
};

const useApiFetcher = () => {
  const apiData = useContext(ApiDataContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const fetcher = useCallback(
    (method: string, url: string, params?: any, options?: Options) => {
      dispatch({
        type: 'REQUEST_START',
      });
      return apiData
        .fetch(method, url, params, options)
        .then((data) => {
          dispatch({
            type: 'REQUEST_SUCCESS',
            data,
          });
          return data;
        })
        .catch((error) => {
          dispatch({
            type: 'REQUEST_ERROR',
            error,
          });
          throw error;
        });
    },
    [apiData],
  );
  return [fetcher, state] as [typeof fetcher, typeof state];
};

export default useApiFetcher;
