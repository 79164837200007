import React from 'react';

function Phish() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 193 160"
    >
      <g clipPath="url(#clip0_861_4222)">
        <path
          fill="url(#paint0_linear_861_4222)"
          d="M137.807 104.701c-3.372 1.794-7.005 2.165-9.942-1.404a7.655 7.655 0 01-1.464-6.826c.69-2.536 2.701-4.17 4.946-5.34 1.915-.996 3.734-2.144 5.524-3.353a142.122 142.122 0 007.364-5.349c1.546-1.192 2.921-2.635 4.638-3.591 1.174-.656 2.599-1.096 3.933-.725 1.284.356 2.155 1.558 2.18 2.885.199 3.295-1.438 6.537-3.132 9.253-1.885 3.024-4.19 5.787-6.627 8.378-1.907 2.029-4.57 4.555-7.417 6.069l-.003.003z"
        ></path>
        <path
          fill="url(#paint1_linear_861_4222)"
          d="M72.903 98.412c2.73-2.663 6.11-4.034 9.933-1.432a7.66 7.66 0 013.324 6.139c.05 2.624-1.418 4.754-3.243 6.5-1.557 1.491-2.98 3.098-4.358 4.759a143.033 143.033 0 00-5.561 7.185c-1.147 1.575-2.062 3.341-3.438 4.74-.943.956-2.184 1.776-3.57 1.792-1.332.017-2.506-.891-2.902-2.158-1.117-3.105-.459-6.669.403-9.749.959-3.427 2.393-6.717 4.003-9.885 1.258-2.478 3.104-5.645 5.409-7.891z"
        ></path>
        <path
          fill="url(#paint2_linear_861_4222)"
          d="M127.826 103.439c0-6.285.56-9.837-5.293-10.094l-.017-.026H86.352l-.016.026c-5.854.257-7.573 5.127-7.573 11.411 0 1.248-.34 3.62 0 4.747l-1.318 2.902s2.073 10.774 8.937 20.806a17.34 17.34 0 004.19 4.976c3.36 2.748 7.823 4.425 12.72 4.425.382 0 .762-.014 1.14-.032.377.02.757.032 1.14.032 4.899 0 9.36-1.677 12.719-4.425a17.385 17.385 0 004.191-4.976c6.863-10.032 10.048-24.634 10.048-24.634l-.004-.007c.34-1.128-4.707-3.886-4.707-5.131h.007z"
        ></path>
        <path
          fill="#8ACAF4"
          d="M94.592 93.419s-12.389-1.899-16.985 2.607c1.402 5.083-.208 16.448-1.111 18.78-.46 13.354.178 27.336.178 27.336s16.9 1.667 18.932 0 1.103-1.885.885-4.133c-.218-2.249-.364-12.834-.29-16.025.072-3.19.855-9.209.276-10.804-.579-1.596-3.254-14.566-1.883-17.761h-.002z"
        ></path>
        <path
          fill="#BCAA7F"
          d="M96.2 121.982c.071-3.191.854-9.209.275-10.805-.58-1.595-3.255-14.637-1.883-17.832 0 0-3.864-.57-8.076-.287 1.819.106 3.04.287 3.04.287-1.37 3.195 1.304 16.237 1.883 17.832.58 1.596-.202 7.614-.276 10.805-.072 3.191.072 13.776.29 16.024.218 2.249 1.144 2.466-.885 4.134-.484.396-1.81.604-3.549.69 3.936.129 7.695.041 8.587-.69 2.032-1.668 1.103-1.885.885-4.134-.218-2.248-.364-12.833-.29-16.024H96.2zM128.56 95.857c-1.749-3.225-6.866-3.165-9.903-2.81 2.029.303 3.929 1.085 4.865 2.81.262.482.456 5.023 4.568 9.816 0 13.241-.401 37.117-.401 37.117s-5.738.667-10.885.878c5.307.354 15.923-.878 15.923-.878s.401-23.876.401-37.117c-4.109-4.793-4.306-9.334-4.568-9.816z"
        ></path>
        <path
          fill="#BCAA7F"
          d="M91.453 138.006c-.218-2.248-.364-12.833-.29-16.024.072-3.191.855-9.209.276-10.805-.58-1.595-3.255-14.637-1.883-17.832 0 0-1.221-.18-3.04-.287-3.33.222-6.88.977-8.909 2.966 1.402 5.083-.208 16.448-1.111 18.78-.46 13.354.178 27.336.178 27.336s5.316.525 10.345.69c1.74-.086 3.065-.294 3.549-.69 2.032-1.668 1.103-1.885.885-4.134zM128.09 105.673c-4.11-4.793-4.306-9.334-4.568-9.816-.936-1.725-2.836-2.507-4.865-2.81-1.387.161-2.342.384-2.342.384 1.307 3.045-2.545 17.066-3.127 18.661-.579 1.596.033 7.614.107 10.805.071 3.191-.072 13.775-.29 16.024-.218 2.248-.975 2.466 1.054 4.133.408.336 1.408.526 2.745.614 5.147-.213 10.885-.878 10.885-.878s.401-23.876.401-37.117z"
        ></path>
        <path
          fill="#9A8E8C"
          d="M95.986 13.974l-18.44 8.533a1.354 1.354 0 00-.66 1.798l2.454 5.3c.314.68 1.12.975 1.799.66l18.44-8.533c.679-.314.975-1.119.66-1.798l-2.454-5.3a1.355 1.355 0 00-1.799-.66z"
        ></path>
        <path
          fill="#9A8E8C"
          d="M81.685 4.059L79.23 5.194l7.082 15.292 2.453-1.135-7.081-15.292z"
        ></path>
        <path
          fill="#339898"
          d="M81.466 6.8a3.563 3.563 0 001.735-4.732 3.563 3.563 0 10-6.466 2.992 3.566 3.566 0 004.731 1.74z"
        ></path>
        <path
          fill="url(#paint3_linear_861_4222)"
          d="M138.546 56.392c0-20.125-16.319-36.438-36.451-36.438-20.133 0-36.452 16.313-36.452 36.438 0 .382.016.758.028 1.137-.01.188-.028.373-.028.563V78.21c0 6.016 4.922 10.937 10.94 10.937h51.023c6.018 0 10.94-4.921 10.94-10.937V58.092c0-.19-.018-.375-.028-.563.012-.377.028-.755.028-1.137z"
        ></path>
        <path
          fill="#fff"
          d="M113.888 34.732c.254.116.581.285.824.391 1.708.758 2.064 2.413 1.411 3.506-.574.964-1.316 1.573-2.988 1.179-.153-.037-.931-.447-1.098-.489-13.23-6.924-27.682 3.281-28.449 17.078-.583 10.513 8.268 19.54 18.928 20.086 3.957.24 6.118-.443 6.899-.603 1.579-.321 2.677 1.084 2.837 2.036.135.801-.104 1.413-.482 1.91-.537.633-.894.783-1.283.894-8.047 2.316-18.392 1.054-25.661-7.123-7.202-8.102-8.24-20.56-2.486-29.679 3.929-6.222 10.29-10.094 16.472-11.036 5.321-.813 9.282-.81 15.073 1.852l.003-.002zM122.523 41.91a2.437 2.437 0 11-2.833-1.96 2.438 2.438 0 012.836 1.96h-.003z"
        ></path>
        <path
          fill="#fff"
          d="M117.156 74.848c-1.193-1.174-.521-3.031-.208-3.36.915-.957 1.712-1.809 3.308-3.789 2.05-2.69.912-4.999-.492-6.349-1.832-1.76-4.688-1.633-6.926.13-.435.345-.977 1.118-1.452 1.6-3.005 3.047-9.183 5.583-14.281 1.076-2.259-1.996-3.405-4.513-3.09-8.34.31-3.745 3.769-7.477 7.593-8.087.456-.071 1.365-.192 1.756-.192 1.29 0 2.486.998 2.444 2.49-.04 1.389-.836 2.334-2.458 2.463-.06.005-.206.026-.269.03-1.89.137-3.602 1.43-4.183 3.65-.204.78-.132 3.246 1.689 4.652 2.601 2.01 5.849.523 7.667-1.58 4.033-4.663 10.399-5.344 14.33-1.998 2.893 2.464 5.459 7.508 1.934 12.764-1.837 2.781-2.979 3.844-3.683 4.578-.915.956-2.592 1.332-3.677.262h-.002z"
        ></path>
        <path
          fill="url(#paint4_linear_861_4222)"
          d="M63.42 120.287l2.181-.736 4.318 17.293-2.678.725-3.822-17.282z"
        ></path>
        <path
          fill="#012135"
          d="M62.842 116.026c-1.123-4.849-10.475-44.832-24.237-91.6 0 0 .132-.605.547.045.818 1.286 16.15 52.989 24.92 91.414.642 2.811-.252 1.848-.778 1.128a2.702 2.702 0 01-.45-.989l-.002.002z"
        ></path>
        <path
          fill="#012135"
          stroke="#012135"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M39.205 24.51l-.6-.085s-1.45.556.186.625l.08.278s-1.48-.088-1.12-.616c.358-.528 1.294-.576 1.294-.576l.16.375zM43.29 38.263s-1.583.132-1.21.355c.23.136.496.213.496.213l.078.275s-1.475-.426-1.012-.752c.463-.327 1.557-.401 1.557-.401l.09.31zM48.36 55.26s-1.707.345-1.337.567c.23.137.558.204.558.204l.08.276s-1.576-.223-1.075-.744c.391-.41 1.69-.57 1.69-.57l.084.265v.002z"
        ></path>
        <path
          fill="url(#paint5_linear_861_4222)"
          d="M63.549 120.88l-1.05-4.976 1.881-.693 1.221 4.34-2.052 1.329z"
        ></path>
        <path
          fill="#012135"
          stroke="#012135"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M53.484 73.76s-1.909.449-1.536.669c.23.136.558.203.558.203l.08.276s-1.576-.222-1.076-.743c.392-.41 1.89-.672 1.89-.672l.084.264v.002zM59.3 96.198s-1.999.46-1.626.683c.23.136.559.204.559.204l.078.275s-1.575-.222-1.075-.743c.392-.41 1.983-.683 1.983-.683l.084.264H59.3z"
        ></path>
        <path
          fill="#012135"
          stroke="#012135"
          strokeMiterlimit="10"
          d="M62.252 111.895s-2.03.065-2.697 1.485c-.667 1.419-.09 3.346-1.415 3.591l.273 1.968s1.712-.277 1.946-3.325c.188-2.434 1.946-2.436 1.946-2.436l-.056-1.283h.003z"
        ></path>
        <path
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M55.397 115.818l-.183-.009c.005-.1.197-4.312.417-9.339l-1.533 8.433.35.419-.082.165-.48-.482 1.794-9.586c.208-4.764.43-10.039.544-13.179a363.35 363.35 0 01-2.198-7.99c-.695-1.203-1.3-2.138-1.383-2.27-.23-.158-2.838-2.015-5.45-5.956-1.599-2.408-2.887-5.115-3.832-8.042-1.18-3.659-1.82-7.674-1.904-11.94-.183-9.218-2.393-22.59-3.336-28.303-.335-2.032-.472-3.066-.412-3.103a.218.218 0 01.174-.026c.155.042.266.237.284.276l.01.018v.021c.317 4.965 4.257 14.038 4.297 14.13l.004.012v.011c1.311 9.423 4.6 16.64 4.633 16.712l.007.014v.014a66.555 66.555 0 002.919 13.48c1.063 3.209 1.99 5.073 2 5.091l.006.014v.016c.271 2.193 1.156 5.935 2.152 9.742 1.038 1.797 2.272 4.182 2.286 5.405.005.343-.023 1.297-.072 2.649.774 2.7 1.34 4.585 1.358 4.64l.007.023-1.909 8.564c-.238 5.474-.459 10.269-.463 10.378l-.005-.002zm.987-23.01c-.109 2.943-.296 7.382-.477 11.58l1.672-7.503c-.065-.213-.54-1.801-1.195-4.077zm-2.008-7.938a382.05 382.05 0 001.864 6.77 63.68 63.68 0 00.051-2.072c-.011-1.04-.98-3.02-1.915-4.698zM37.839 24.793c.02.278.206 1.406.456 2.918.945 5.715 3.155 19.097 3.338 28.33.083 4.242.723 8.238 1.895 11.878.938 2.911 2.219 5.602 3.806 7.994 2.703 4.078 5.395 5.905 5.422 5.923l.017.012.009.016c.05.079.498.764 1.066 1.718-.927-3.584-1.73-7.042-1.99-9.121-.088-.18-.982-2.028-2-5.099a66.717 66.717 0 01-2.925-13.507c-.164-.361-3.352-7.491-4.64-16.737-.162-.375-3.982-9.24-4.304-14.158a.454.454 0 00-.15-.164v-.003z"
        ></path>
        <path
          fill="url(#paint6_linear_861_4222)"
          d="M58.916 106.227l1.457 8.806a2.225 2.225 0 01-1.897 2.57l-4.624.63a1.634 1.634 0 01-2.082-1.297l-1.48-8.901 8.628-1.808h-.002z"
        ></path>
        <path
          fill="url(#paint7_linear_861_4222)"
          d="M58.26 115.776l1.402 10.08-4.964 2.818-.895-2.737 3.08-2.408-.638-7.473 2.015-.28z"
        ></path>
        <path
          fill="url(#paint8_linear_861_4222)"
          d="M53.653 129.313a1.815 1.815 0 10.002-3.63 1.815 1.815 0 00-.002 3.63z"
        ></path>
        <path
          fill="url(#paint9_linear_861_4222)"
          d="M63.521 111.384s1.883 5.094 0 5.796c-1.883.701-2.977-1.348-4.677 2.776-1.7 4.124-2.467-8.239-2.467-8.239l-6.21-2.438s-1.668-.558-1.72-2.158c-.147-4.583 10.075-6.294 12.222-2.872 1.196 1.904-1.612 3.578-1.612 3.578l-.391 4.893 4.855-1.334v-.002z"
        ></path>
        <path
          fill="url(#paint10_linear_861_4222)"
          d="M52.428 109.012s-2.894-.02-3.026-1.322c-.132-1.301 6.424-3.221 10.246-2.304 2.372.567-4.557 3.93-7.223 3.626h.003z"
        ></path>
        <path
          fill="#1BA6FB"
          d="M89.59 130.344H76.558a.676.676 0 00-.676.676v9.242c0 .373.302.676.676.676H89.59a.676.676 0 00.677-.676v-9.242a.676.676 0 00-.677-.676z"
        ></path>
        <path
          fill="url(#paint11_linear_861_4222)"
          d="M87.85 107.887c-1.329 1.519-6.224 1.549-7.572.046-.52-.581-.02-3.306-.02-3.306l7.572-.047s.535 2.721.02 3.307z"
        ></path>
        <path
          fill="#1BA6FB"
          d="M87.809 107.107c-1.33 1.345-6.227 1.375-7.572.046-.522-.514-.019-2.929-.019-2.929l7.572-.047s.533 2.409.019 2.93z"
        ></path>
        <path
          fill="#D6CCB1"
          d="M89.009 125.013H77.232a1.42 1.42 0 01-1.42-1.419v-11.122h14.62v11.122a1.42 1.42 0 01-1.42 1.419h-.003z"
        ></path>
        <path
          fill="url(#paint12_linear_861_4222)"
          d="M75.81 112.472s7.519 9.987 14.619 0h-14.62z"
        ></path>
        <path
          fill="#1BA6FB"
          d="M122.732 107.755c1.329 1.345 6.226 1.375 7.572.046.522-.514.019-2.929.019-2.929l-7.573-.046s-.532 2.408-.018 2.929zM133.133 129.529h-13.906a.679.679 0 00-.679.678v9.886c0 .374.304.678.679.678h13.906a.678.678 0 00.678-.678v-9.886a.678.678 0 00-.678-.678z"
        ></path>
        <path
          fill="#1BA6FB"
          d="M133.811 130.603v9.094c0 .592-.481 1.074-1.074 1.074h-8.335c-1.17 0-1.499-1.602-.424-2.061 3.255-1.392 6.759-3.239 6.722-4.738-.023-.917-1.343-1.711-3.108-2.364-1.121-.414-.818-2.079.377-2.079h4.765c.593 0 1.075.481 1.075 1.074h.002z"
        ></path>
        <path
          fill="url(#paint13_linear_861_4222)"
          d="M67.723 128.8c1.532-.702 2.038-2.875 1.13-4.852-.907-1.977-2.884-3.011-4.415-2.309-1.532.703-2.038 2.875-1.13 4.852.907 1.977 2.884 3.011 4.415 2.309z"
        ></path>
        <path
          fill="#BCAA7F"
          fillRule="evenodd"
          d="M122.899 21.98c.377-.019-18.68 12.192-24.983 14.822-10.983 5.203-16.456 5.213-21.173 7.23-3.305 1.414-9.113 3.478-12.3 4.587-.848.296-1.635-.57-1.262-1.387l6-13.132c.06-.13.09-.271.092-.415.023-2.88.07-11.492.092-14.834 0-.321.156-.622.417-.815 1.983-1.45 10.892-7.671 22.002-11.017 2.453-.741 5.006-1.341 7.623-1.716.387-.056.772.12.99.447 2.997 4.51 5.927 8.869 8.047 11.995.137.201.338.345.572.41l13.885 3.823-.002.002z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint14_linear_861_4222)"
          fillRule="evenodd"
          d="M108.652 18.057C84.476 33.237 69.27 33.903 69.27 33.903l.028-4.168c11.946-2.698 26.056-7.276 37.395-14.575l1.959 2.897z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#D6CCB1"
          d="M89.59 130.344H76.558a.676.676 0 00-.676.676v9.242c0 .373.302.676.676.676H89.59a.676.676 0 00.677-.676v-9.242a.676.676 0 00-.677-.676zM87.809 107.107c-1.33 1.345-6.227 1.375-7.572.046-.522-.514-.019-2.929-.019-2.929l7.572-.047s.533 2.409.019 2.93z"
        ></path>
        <path
          fill="url(#paint15_linear_861_4222)"
          d="M89.579 130.332s-6.783 10.842-13.188 0H89.58z"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M106.811 31.877c-1.726-4.967-6-8.225-9.074-12.12-2.376-4.06-8.753-11.479-4.463-13.086 2.003-.752 4.192-1.178 6.492-1.301a.62.62 0 01.556.292c2.982 4.798 6.359 9.19 8.393 12.435l14.1 3.867s-8.568 5.916-16.004 9.913z"
          clipRule="evenodd"
          opacity="0.1"
        ></path>
        <path
          fill="#000"
          d="M96.2 121.982c.071-3.191.854-9.209.275-10.805-.58-1.595-3.255-14.637-1.883-17.832 0 0-3.864-.57-8.076-.287 1.819.106 3.04.287 3.04.287-1.37 3.195 1.304 16.237 1.883 17.832.58 1.596-.202 7.614-.276 10.805-.072 3.191.072 13.776.29 16.024.218 2.249 1.144 2.466-.885 4.134-.484.396-1.81.604-3.549.69 3.936.129 7.695.041 8.587-.69 2.032-1.668 1.103-1.885.885-4.134-.218-2.248-.364-12.833-.29-16.024H96.2zM128.56 95.857c-1.749-3.225-6.866-3.165-9.903-2.81 2.029.303 3.929 1.085 4.865 2.81.262.482.456 5.023 4.568 9.816 0 13.241-.401 37.117-.401 37.117s-5.738.667-10.885.878c5.307.354 15.923-.878 15.923-.878s.401-23.876.401-37.117c-4.109-4.793-4.306-9.334-4.568-9.816z"
          opacity="0.1"
        ></path>
        <path
          fill="url(#paint16_linear_861_4222)"
          d="M122.704 108.623c1.332 1.561 6.227 1.6 7.573.061.521-.596.013-3.393.013-3.393l-7.572-.06s-.531 2.788-.016 3.392h.002z"
        ></path>
        <path
          fill="#D6CCB1"
          d="M122.732 107.755c1.329 1.345 6.226 1.375 7.572.046.522-.514.019-2.929.019-2.929l-7.573-.046s-.532 2.408-.018 2.929zM120.499 125.664h12.506c.593 0 1.073-.479 1.073-1.072v-11.469h-14.652v11.469c0 .593.48 1.072 1.073 1.072z"
        ></path>
        <path
          fill="url(#paint17_linear_861_4222)"
          d="M134.078 113.12s-7.536 8.526-14.652 0h14.652z"
        ></path>
        <path
          fill="#D6CCB1"
          d="M133.133 129.529h-13.906a.679.679 0 00-.679.678v9.886c0 .374.304.678.679.678h13.906a.678.678 0 00.678-.678v-9.886a.678.678 0 00-.678-.678z"
        ></path>
        <path
          fill="#D6CCB1"
          d="M133.811 130.603v9.094c0 .592-.481 1.074-1.074 1.074h-8.335c-1.17 0-1.499-1.602-.424-2.061 3.255-1.392 6.759-3.239 6.722-4.738-.023-.917-1.343-1.711-3.108-2.364-1.121-.414-.818-2.079.377-2.079h4.765c.593 0 1.075.481 1.075 1.074h.002z"
        ></path>
        <path
          fill="url(#paint18_linear_861_4222)"
          d="M133.286 129.54s-7.151 12.62-13.901 0h13.901z"
        ></path>
        <path
          fill="#E2D9DE"
          d="M104.608 160c15.33 0 27.758-2.134 27.758-4.766 0-2.631-12.428-4.765-27.758-4.765-15.33 0-27.758 2.134-27.758 4.765 0 2.632 12.428 4.766 27.758 4.766z"
          opacity="0.3"
        ></path>
        <path
          fill="#E2D9DE"
          d="M104.608 158.298c9.86 0 17.853-1.372 17.853-3.064s-7.993-3.063-17.853-3.063c-9.86 0-17.853 1.371-17.853 3.063 0 1.692 7.993 3.064 17.853 3.064z"
          opacity="0.3"
        ></path>
        <path
          fill="#E2D9DE"
          d="M104.608 157.476c7.21 0 13.055-1.004 13.055-2.242s-5.845-2.241-13.055-2.241-13.056 1.003-13.056 2.241c0 1.238 5.846 2.242 13.056 2.242z"
          opacity="0.3"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_861_4222"
          x1="132.397"
          x2="210.276"
          y1="97.865"
          y2="55.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_861_4222"
          x1="64.491"
          x2="48.518"
          y1="126.809"
          y2="153.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_861_4222"
          x1="148.053"
          x2="49.713"
          y1="168.593"
          y2="39.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_861_4222"
          x1="46.208"
          x2="152.738"
          y1="68.924"
          y2="46.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_861_4222"
          x1="63.783"
          x2="69.2"
          y1="117.893"
          y2="139.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_861_4222"
          x1="62.814"
          x2="65.359"
          y1="118.209"
          y2="117.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#B45C5A"></stop>
          <stop offset="1" stopColor="#8E4057"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_861_4222"
          x1="50.969"
          x2="59.91"
          y1="112.997"
          y2="111.755"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_861_4222"
          x1="53.342"
          x2="59.101"
          y1="122.664"
          y2="121.864"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#B45C5A"></stop>
          <stop offset="1" stopColor="#8E4057"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_861_4222"
          x1="51.846"
          x2="55.446"
          y1="127.754"
          y2="127.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#B45C5A"></stop>
          <stop offset="1" stopColor="#8E4057"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_861_4222"
          x1="49.2"
          x2="63.758"
          y1="112.668"
          y2="110.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#D7A259"></stop>
          <stop offset="1" stopColor="#DA8859"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_861_4222"
          x1="49.416"
          x2="60.211"
          y1="107.827"
          y2="106.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_861_4222"
          x1="79.812"
          x2="87.845"
          y1="106.965"
          y2="106.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_861_4222"
          x1="75.81"
          x2="90.429"
          y1="114.69"
          y2="114.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_861_4222"
          x1="59.452"
          x2="67.293"
          y1="125.426"
          y2="122.415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9211"></stop>
          <stop offset="1" stopColor="#FFAE4F"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_861_4222"
          x1="58.096"
          x2="113.295"
          y1="38.261"
          y2="14.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_861_4222"
          x1="76.391"
          x2="89.579"
          y1="132.741"
          y2="132.741"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_861_4222"
          x1="122.176"
          x2="130.21"
          y1="107.841"
          y2="107.848"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_861_4222"
          x1="119.426"
          x2="134.078"
          y1="115.017"
          y2="115.017"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_861_4222"
          x1="119.385"
          x2="133.286"
          y1="132.345"
          y2="132.345"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0088DC"></stop>
          <stop offset="0.19" stopColor="#007ECC"></stop>
          <stop offset="0.44" stopColor="#0065A3"></stop>
          <stop offset="0.78" stopColor="#003D62"></stop>
          <stop offset="1" stopColor="#012135"></stop>
        </linearGradient>
        <clipPath id="clip0_861_4222">
          <path fill="#fff" d="M0 0H193V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Phish;
