import { DependencyList, useEffect, useRef } from 'react';

/**
 * useUpdateEffect: Hook for running a function on update.
 *
 * Idea stolen from: https://stackoverflow.com/a/55075818/1526448
 * @param {Function} effect - The function to run on update.
 * @param {Array<any>} dependencies - The dependencies to run on update.
 */
const useUpdateEffect = (effect: () => void, dependencies: DependencyList) => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useUpdateEffect;
