import React from 'react';

import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { Box, FlexBox } from 'components/Containers';
import { unit } from 'theme';
import { toTitleCase } from 'utils';

import { Group } from '../GroupManagement.types';
import { GroupsTypes } from '../GroupManagementTable';
import { GroupsTypeConfig } from '../helpers';

type GroupTypeProps = {
  group: Group;
};
const GroupType = ({ group }: GroupTypeProps) => {
  const intl = useIntl();
  if (group.creationMethod === undefined) return null;
  const { formatMessage } = intl;
  if (group.creationMethod === 'scim') {
    return (
      <Box pb={unit(1)}>
        <FlexBox flexDirection="row">
          <Box pr={unit(1)}>
            {formatMessage({
              id: GroupsTypeConfig[group.creationMethod].text,
            })}
          </Box>{' '}
          <span>({toTitleCase(group.creationMethod)})</span>
        </FlexBox>
        <span>{dayjs(group.latestSyncAt).format('DD/MM/YYYY HH:mm')}</span>
      </Box>
    );
  }
  return (
    <div>
      {formatMessage({
        id: GroupsTypeConfig[group.creationMethod as GroupsTypes].text,
      })}
    </div>
  );
};

export default GroupType;
