import { BreadcrumbItem as CarbonBreadcrumbItem } from '@carbon/react';
import styled from 'styled-components';

export const BreadcrumbItem = styled(CarbonBreadcrumbItem)`
  &::after {
    color: ${({ theme }) => theme.colorPalette.grey[800]};
  }
  .cds--link,
  span {
    color: ${({ theme, isCurrentPage }) =>
      isCurrentPage
        ? theme.componentColors.breadcrumbs.activeLinkColor
        : theme.componentColors.breadcrumbs.linkColor} !important;
    font-weight: ${({ isCurrentPage }) => (isCurrentPage ? 'bold' : 'normal')};
    font-size: ${({ theme }) => theme.fontSizes.l};
    white-space: break-spaces;
    &:active,
    &:focus {
      outline-offset: 4px;
    }
  }
  .cds--overflow-menu {
    transform: rotate(90deg);
    justify-content: flex-end;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: unset;
    }
    .cds--overflow-menu__icon {
      fill: ${({ theme }) => theme.componentColors.breadcrumbs.iconColor};
    }
  }

  &:after {
    color: ${({ theme }) => theme.componentColors.breadcrumbs.iconColor};
  }
`;
