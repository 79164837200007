import React, { useLayoutEffect, useRef, useState } from 'react';

import Button from 'components/Button';

import { ShowLabelPopup } from './ShowLabel.styles';

type ShowLabelProps<T extends HTMLElement> = {
  id?: string;
  rootElement?: T;
  showEditTranslation: () => void;
};

const ShowLabel = <T extends HTMLElement>({
  id,
  rootElement,
  showEditTranslation,
}: ShowLabelProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [labelIdPos, setLabelIdPos] = useState({ x: 0, y: 0 });
  const [right, setRight] = useState(false);
  const [top, setTop] = useState(false);

  useLayoutEffect(() => {
    if (rootElement && ref.current) {
      const rect = rootElement.getBoundingClientRect();
      const width = window.innerWidth;
      const height = window.innerHeight;

      const _right = rect.left + ref.current.clientWidth > width;
      const _top = rect.bottom + ref.current.clientHeight > height;

      setRight(_right);
      setTop(_top);

      const x = _right ? rect.right : rect.left;
      const y = _top ? rect.top : rect.bottom;

      setLabelIdPos({ x, y });
    }
  }, [rootElement, setRight, setTop]);

  return (
    <>
      <ShowLabelPopup
        ref={ref}
        pos={labelIdPos}
        right={right}
        top={top}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <span>{id}</span>
          <Button onClick={showEditTranslation}>Edit</Button>
        </div>
      </ShowLabelPopup>
    </>
  );
};

export default ShowLabel;
