import styled from 'styled-components';

export const ExpandableComponent = styled.div`
  margin-left: calc(48px + 1rem);
`;

export const BehaviourList = styled.div`
  display: grid;
  gap: 8px;
  margin: 8px 0;
  a {
    font-size: 14px;
  }
`;

export const BehaviourLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
