// The core theme controls the main parts of all themes. It it used to create user selectable themes such as default, dark, high contrast etc.
// This core theme stores our core colour palette which can then be overridden later.
import { DefaultTheme } from 'styled-components';

const fontSizes = {
  xs: '0.625rem',
  s: '0.75rem',
  m: '0.875rem',
  l: '1rem',
  xl: '1.125rem',
};

const headings = {
  xs: '1.25rem',
  s: '1.375rem',
  m: '1.75rem',
  l: '2.25rem',
  xl: '3rem',
};

const colorPalette = {
  /* The below vars are set within our App sass file */
  primary: {
    100: 'var(--cs-brand-primary-100)',
    200: 'var(--cs-brand-primary-200)',
    300: 'var(--cs-brand-primary-300)',
    400: 'var(--cs-brand-primary-400)',
    500: 'var(--cs-brand-primary-500)',
  },
  secondary: {
    100: '#21837F',
    200: '#21837F',
    300: '#21837F',
    400: '#21837F',
    500: '#21837F',
  },
  ternary: {
    100: '#21837F',
    200: '#21837F',
    300: '#21837F',
    400: '#21837F',
    500: '#21837F',
  },
  tones: {
    positive: '#21837F',
    warning: '#F7C66D',
    danger: '#A84545',
    neutral: '#6E5275',
    error: '#722220',
  },
  core: {
    black: '#000000',
    white: '#FFFFFF',
    ghostWhite: '#F9F9F9',
    whiteSmoke: '#F0F3F6',
  },
  grey: {
    50: '#FAFAFA',
    100: '#EBEBEB',
    200: '#DBDBDB',
    300: '#CCCCCC',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#333533',
  },
  ui: {
    blue: {
      50: '#F9FAFB',
      100: '#C8CCD7',
      200: '#B7BBC9',
      300: '#9299AF',
      400: '#6E7793',
      500: '#4A5579',
      600: '#3F496B',
      700: '#363F5E',
      800: '#2B3452',
      900: '#222A45',
    },
    coral: {
      50: '#FAE6E5',
      100: '#F3C5C3',
      200: '#EBA7A4',
      300: '#E48A86',
      400: '#DE6B68',
      500: '#D94F4C',
      600: '#BE3739',
      700: '#A51F29',
      800: '#8C0019',
      900: '#730007',
    },
    navy: {
      50: '#ECF2F3',
      100: '#DAE4E7',
      200: '#BACDD2',
      300: '#A1BCC3',
      400: '#7299A6',
      500: '#417787',
      600: '#005569',
      700: '#004357',
      800: '#003246',
      900: '#002235',
    },
    orange: {
      50: '#FEFBF6',
      100: '#FBE8D0',
      200: '#F9D1AA',
      300: '#F7B684',
      400: '#F39C5F',
      500: '#E0761F',
      600: '#C56002',
      700: '#AC4D00',
      800: '#923A00',
      900: '#7A2700',
    },
    pink: {
      50: '#FFF3FA',
      100: '#FFE1ED',
      200: '#FCC8DE',
      300: '#F0A6C3',
      400: '#D77EA5',
      500: '#B65E85',
      600: '#9F4B72',
      700: '#88375F',
      800: '#72244D',
      900: '#5C0F3B',
    },
    purple: {
      50: '#FDF5FF',
      100: '#F9E1FE',
      200: '#EBC4F8',
      300: '#CB9DDB',
      400: '#AB7DB9',
      500: '#8B5E97',
      600: '#764C84',
      700: '#603A70',
      800: '#4C285D',
      900: '#38174A',
    },
    cyan: {
      50: '#ecfeff',
      100: '#e2f4ff',
      200: '#c5e9ff',
      300: '#a8deff',
      400: '#8bd3ff',
      500: '#6fc8ff',
      600: '#52bdff',
      700: '#35b2ff',
      800: '#18a7ff',
      900: '#009bfa',
    },
    green: {
      50: '#ecfeff',
      100: '#e2f7f6',
      200: '#c6efec',
      300: '#a9e8e3',
      400: '#8ce0da',
      500: '#70d8d0',
      600: '#53d0c7',
      700: '#36c8be',
      800: '#2faba2',
      900: '#07655e',
    },
  },
};

type CoreTheme = Pick<DefaultTheme, 'fontSizes' | 'headings' | 'colorPalette'>;

export const coreTheme: CoreTheme = {
  fontSizes,
  headings,
  colorPalette,
};
