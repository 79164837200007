import React from 'react';

import Translation from 'components/Content/Translation/Translation';
import { Modal } from 'components/Modal';

type RemoveGroupModalProps = {
  open: boolean;
  onRequestClose: () => void;
  groupsToRemove: {
    id: number;
    displayName: string;
  }[];
  onRequestSubmit: () => void;
  loading: boolean;
};

const RemoveGroupModal = ({
  open,
  onRequestClose,
  groupsToRemove,
  onRequestSubmit,
  loading,
}: RemoveGroupModalProps) => (
  <Modal
    data-testid="remove-group-modal"
    open={open}
    modalHeading={<Translation id="label_areYouSure" />}
    primaryButtonText={
      <Translation
        id="label_removeGroup"
        values={{ rowsSelected: groupsToRemove.length }}
      />
    }
    onRequestSubmit={onRequestSubmit}
    secondaryButtonText={<Translation id="common.cancel" />}
    onSecondarySubmit={onRequestClose}
    onRequestClose={onRequestClose}
    danger
    loadingStatus={loading ? 'active' : 'inactive'}
  >
    <Translation
      id="label_groupsToBeRemoved"
      values={{
        groupsToRemove: groupsToRemove
          .map(({ displayName }) => displayName)
          .join(', '),
      }}
    />
  </Modal>
);

export default RemoveGroupModal;
