import { matchPath, useLocation, useMatch } from 'react-router-dom';

import { useFeatureFlag } from '../../feature-flags';
import { withLayoutRoutes } from '../../routes/RoutesWithLayout';
import { useAppSelector } from '../../store/createStore';
import {
  selectClientFeatures,
  selectClientPackages,
  selectPackages,
  selectUrlFeatureMap,
} from '../../store/features/mainSlice';

const getLayoutRouteEntity = (pathName?: string) => {
  if (!pathName) return undefined;

  return withLayoutRoutes
    .map(({ path, altPaths, features }) => ({
      path,
      altPaths,
      features,
    }))
    .find(({ path }) => matchPath(path, pathName));
};

const getFeaturesArray = (
  pathName: string,
  urlFeaturesMap: Record<string, Array<string>>,
  altPaths?: Array<string>,
) => {
  if (!altPaths || urlFeaturesMap[pathName]) {
    const path = urlFeaturesMap[pathName];
    if (!path || path.length === 0) return undefined;
    return path;
  }
  const altPathName = altPaths.find((pathName) => urlFeaturesMap[pathName]);
  return urlFeaturesMap[altPathName];
};

const defaultResponse = {
  level: undefined,
  hasNoSubscription: false,
};

/**
 *
 * 1) calculate features array. This array come either from menu pages, either from the routes array which exist inside RoutesWithLayout or as input to this hook.
 * 2) check if the features array is part of the client package features array.
 * 3) if it is part of the client package features array then it means that page is not in demo mode.
 *
 * @return level: the level of the subscription that the page/component has access to.
 * @return hasNoSubscription: if the page has no subscription to the page/component.
 * @param componentFeatures
 */
export const useCalculateSubscription = (componentFeatures?: string[]) => {
  const useSymposium = useFeatureFlag('use-symposium');

  const location = useLocation();

  const packages = useAppSelector(selectPackages);
  const clientPackages = useAppSelector(selectClientPackages);
  const clientFeatures = useAppSelector(selectClientFeatures);
  const urlFeaturesMap = useAppSelector(selectUrlFeatureMap);

  const pathname = useMatch(location.pathname)?.pathname;
  const routeEntity = getLayoutRouteEntity(pathname);
  const altPaths = routeEntity?.altPaths;

  if (
    !useSymposium ||
    !urlFeaturesMap ||
    !packages ||
    clientFeatures.length === 0
  ) {
    return defaultResponse;
  }

  /* Get the list of features for the current page from the different areas */
  const menuFeatures = getFeaturesArray(pathname, urlFeaturesMap, altPaths);
  const nonMenuFeatures = routeEntity?.features;
  const currentPageFeatures =
    componentFeatures || menuFeatures || nonMenuFeatures;

  if (!currentPageFeatures || currentPageFeatures.length === 0) {
    return defaultResponse;
  }

  /* Get the packages associated to the features on the current page */
  const currentPagePackages = packages.filter((productPackage) =>
    productPackage.features.some((feature) =>
      currentPageFeatures.includes(feature),
    ),
  );

  /* Does the client have access to the features on the current page */
  const isPartOfClientFeatures = currentPageFeatures.some((feature) =>
    clientFeatures.includes(feature),
  );

  if (currentPagePackages.length > 0) {
    /* If the client has the feature then find the package that they are subscribed to and that the feature is associated to
     * else if they have not subscribed to the package then use the initial package associated to the feature by default */
    let level = currentPagePackages[0].package;
    if (isPartOfClientFeatures) {
      const subscribedPackage = currentPagePackages.find((m) =>
        clientPackages
          .map((p) => p.package.toUpperCase())
          .includes(m.package.toUpperCase()),
      );
      level = subscribedPackage.package;
    }
    return {
      level,
      hasNoSubscription: !isPartOfClientFeatures,
    };
  }
  return defaultResponse;
};
