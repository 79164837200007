import React from 'react';

import {
  FilterableMultiSelect as CarbonFilterableMultiSelect,
  FilterableMultiSelectProps as CarbonFilterableMultiSelectProps,
} from '@carbon/react';

import { Item } from '../Dropdowns.types';
import DropdownSkeleton from '../DropdownSkeleton';

type FilterableMultiSelectProps = CarbonFilterableMultiSelectProps<Item> & {
  loading?: boolean;
};

const FilterableMultiSelect = ({
  loading,
  ...rest
}: FilterableMultiSelectProps) =>
  loading ? <DropdownSkeleton /> : <CarbonFilterableMultiSelect {...rest} />;

export { type FilterableMultiSelectProps };

export default FilterableMultiSelect;
