import React, { createContext, useContext, useState } from 'react';
// create  PlatformFeedback context that holds blockIds and setBlockIds states
type PlatformFeedbackType = {
  blockIds: number[];
  setBlockIds: React.Dispatch<React.SetStateAction<number[]>>;
};

const PlatformFeedbackContext = createContext<PlatformFeedbackType>(null);

export const usePlatformFeedbackContext = () => {
  const context = useContext(PlatformFeedbackContext);
  if (!context) {
    throw new Error(
      'usePlatformFeedbackContext must be used within a PlatformFeedbackProvider',
    );
  }

  return context;
};

interface PlatformFeedbackProviderProps {
  children: React.ReactNode;
}

const PlatformFeedbackProvider: React.FC<PlatformFeedbackProviderProps> = ({
  children,
}) => {
  const [blockIds, setBlockIds] = useState<number[]>([]);
  return (
    <PlatformFeedbackContext.Provider value={{ blockIds, setBlockIds }}>
      {children}
    </PlatformFeedbackContext.Provider>
  );
};

export default PlatformFeedbackProvider;
