import { createSelector, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storageSession from 'redux-persist/lib/storage/session';

import { RootState } from 'store/createStore';

import { updateTranslationConfig } from './configSlice';

type Translations = Record<string, string>;

export interface TranslationsStateInterface {
  locales: Record<string, Translations>;
}

const initialState: TranslationsStateInterface = {
  locales: {},
};

const __DEV__ = process.env.NODE_ENV !== 'production';
const manualTranslations = __DEV__
  ? require('../../manual-translations.json')
  : {};

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setTranslations: (state, action) => {
      state.locales = {
        ...state.locales,
        [action.payload.locale]: action.payload.translations,
      };
    },
  },
});

/* Actions */
export const { setTranslations } = translationsSlice.actions;

/* Thunks */
export const setTranslationsAndUpdateConfig =
  (locale, direction, translations) => (dispatch) => {
    dispatch(setTranslations({ locale, translations }));
    dispatch(updateTranslationConfig({ locale, direction }));
  };

/* Selectors */
export const selectTranslationsState = (
  state: RootState,
): TranslationsStateInterface =>
  state.translations as unknown as TranslationsStateInterface;

export const selectTranslationsForLocale = createSelector(
  selectTranslationsState,
  (_, locale: string) => locale,
  (translationsState, locale) => {
    const storedTranslations =
      translationsState?.locales[locale.toLowerCase()] ?? undefined;
    const loaded = storedTranslations !== undefined;

    if (!loaded) {
      return undefined;
    }

    if (__DEV__ && (locale === 'en-gb' || locale === 'en')) {
      return { ...manualTranslations, ...storedTranslations };
    }

    return storedTranslations;
  },
);

/* Persist */
const translationsConfig = {
  key: 'translations',
  storage: storageSession,
  stateReconciler: autoMergeLevel1,
};

export default persistReducer(translationsConfig, translationsSlice.reducer);
