import React from 'react';

const IntegrationsIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1601_253)">
      <path d="M18.6051 16.7987C18.4414 16.635 18.2156 16.5391 17.9842 16.5391C17.7527 16.5391 17.5269 16.6294 17.3632 16.7987L14.5352 19.6267L12.3846 17.4761L15.2126 14.6481C15.5569 14.3038 15.5569 13.7449 15.2126 13.4006L14.2304 12.4184C14.0667 12.2547 13.8409 12.1588 13.6095 12.1588C13.3781 12.1588 13.1523 12.2491 12.9886 12.4184L10.1606 15.2464L8.55181 13.6377C8.38812 13.474 8.16233 13.378 7.93089 13.378C7.69946 13.378 7.47367 13.4683 7.30997 13.6377L5.89879 15.0489C5.55446 15.3932 5.55446 15.952 5.89879 16.2963L6.23183 16.6294C6.09071 16.7253 5.96088 16.8326 5.8367 16.9568L1.72733 21.0661C1.21366 21.5798 0.93142 22.2628 0.93142 22.9966C0.93142 23.7305 1.21366 24.4135 1.72733 24.9271L3.30785 26.5077L0.457262 29.3583C-0.146724 29.9622 -0.146724 30.9331 0.457262 31.5371C0.756433 31.8363 1.15156 31.9887 1.5467 31.9887C1.94183 31.9887 2.33696 31.8363 2.63613 31.5371L5.48672 28.6865L7.07854 30.2784C7.59221 30.792 8.27522 31.0743 9.00904 31.0743C9.74285 31.0743 10.4259 30.792 10.9395 30.2784L15.0489 26.169C15.1731 26.0448 15.2803 25.915 15.3763 25.7739L15.7093 26.1069C15.873 26.2706 16.0988 26.3665 16.3303 26.3665C16.5617 26.3665 16.7875 26.2762 16.9512 26.1069L18.3624 24.6957C18.5261 24.532 18.622 24.3062 18.622 24.0748C18.622 23.8434 18.5317 23.6176 18.3624 23.4539L16.7536 21.8451L19.5816 19.0171C19.926 18.6728 19.926 18.1139 19.5816 17.7696L18.5994 16.7874L18.6051 16.7987Z" />
      <path d="M31.5484 0.451579C30.9444 -0.152408 29.9735 -0.152408 29.3695 0.451579L26.4907 3.33039L24.9102 1.74987C24.3965 1.2362 23.7135 0.95396 22.9797 0.95396C22.2459 0.95396 21.5629 1.2362 21.0492 1.74987L16.9398 5.85923C16.8156 5.98342 16.7084 6.11325 16.6124 6.25437L16.2794 5.92133C16.1157 5.75763 15.8899 5.66167 15.6585 5.66167C15.427 5.66167 15.2012 5.75198 15.0375 5.92133L13.6264 7.33251C13.4627 7.49621 13.3667 7.722 13.3667 7.95343C13.3667 8.18486 13.457 8.41065 13.6264 8.57435L23.4369 18.3849C23.6006 18.5486 23.8264 18.6446 24.0578 18.6446C24.2893 18.6446 24.5151 18.5542 24.6787 18.3849L26.0899 16.9737C26.4343 16.6294 26.4343 16.0706 26.0899 15.7262L25.7569 15.3932C25.898 15.2972 26.0278 15.19 26.152 15.0658L30.2614 10.9564C30.7751 10.4428 31.0573 9.75975 31.0573 9.02593C31.0573 8.29211 30.7751 7.6091 30.2614 7.09543L28.6696 5.50362L31.5484 2.6248C32.1524 2.02081 32.1524 1.04992 31.5484 0.445934V0.451579Z" />
    </g>
    <defs>
      <clipPath id="clip0_1601_253">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IntegrationsIcon;
