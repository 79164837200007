import { InlineNotification } from '@carbon/react';
import styled from 'styled-components';

export const StyledNotification = styled(InlineNotification)<{
  $maxWidth: number | string;
}>`
  margin: 1rem 0;
  max-width: ${({ $maxWidth }) => $maxWidth || 100}%;
  .cds--inline-notification__details,
  .cds--inline-notification__text-wrapper {
    align-items: center;
  }
  .cds--inline-notification__close-button {
    svg {
      fill: ${({ theme }) => theme.colorPalette.grey[800]} !important;
    }
    &:focus {
      outline: 2px solid ${({ theme }) => theme.colorPalette.tones.positive};
    }
  }

  &.cds--inline-notification--success {
    border-color: ${({ theme }) =>
      theme.colorPalette.tones.positive} !important;
    border: 1px solid;
    border-left: 3px solid;
    background: ${({ theme }) =>
      theme.componentColors.inlineNotification.backgroundColor};
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
    svg {
      fill: ${({ theme }) => theme.colorPalette.tones.positive};
      margin-top: 0;
    }
  }
  &.cds--inline-notification--warning {
    border-color: ${({ theme }) => theme.colorPalette.tones.warning} !important;
    border: 1px solid;
    border-left: 6px solid;
    background: ${({ theme }) =>
      theme.componentColors.inlineNotification.backgroundColor};
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
    svg {
      fill: ${({ theme }) => theme.colorPalette.tones.warning};
      margin-top: 0;
    }
  }
  &.cds--inline-notification--error {
    border-color: ${({ theme }) => theme.colorPalette.tones.danger} !important;
    border: 1px solid;
    border-left: 6px solid;
    background: ${({ theme }) =>
      theme.componentColors.inlineNotification.backgroundColor};
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
    svg {
      fill: ${({ theme }) => theme.colorPalette.tones.danger};
      margin-top: 0;
    }
  }
  &.cds--inline-notification--info {
    border-color: ${({ theme }) => theme.colorPalette.ui.navy[500]} !important;
    border: 1px solid;
    border-left: 6px solid;
    background: ${({ theme }) =>
      theme.componentColors.inlineNotification.backgroundColor};
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
    svg {
      fill: ${({ theme }) => theme.colorPalette.ui.navy[500]};
      margin-top: 0;
    }
  }
  &.cds--inline-notification__action-button.cds--btn--ghost:active,
  .cds--inline-notification__action-button.cds--btn--ghost:hover {
    background-color: ${({ theme }) => theme.colorPalette.core.white};
  }
  .cds--inline-notification__action-button.cds--btn--ghost:focus {
    outline: 2px solid ${({ theme }) => theme.colorPalette.primary[300]};
  }
  &.cds--inline-notification:not(.cds--inline-notification--low-contrast)
    .cds--inline-notification__action-button.cds--btn--ghost {
    color: ${({ theme }) => theme.colorPalette.grey[700]};
    text-decoration: underline;
  }
`;
