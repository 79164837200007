import React, { useCallback } from 'react';

import qs from 'qs';
import { useIntl } from 'react-intl';

import { useApiData } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { FallbackErrorComponent } from 'components/Error';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import CybDataTable, { useDataTableState } from 'components/Table/CybDataTable';
import { Text } from 'components/Text';
import { convertToSnakeCase } from 'utils';

import { Group } from '../GroupManagement.types';

type AddExistingGroupTableProps = {
  groupId: string;
  selectedGroups: Array<Group>;
  setSelectedGroups: (groups: Array<Group>) => void;
};

export default function AddExistingGroupTable({
  groupId,
  setSelectedGroups,
  selectedGroups,
}: AddExistingGroupTableProps) {
  const intl = useIntl();

  const [params, setParams] = useDataTableState({
    column: 'displayName',
    direction: 'ASC',
  });

  const {
    data: groupList,
    loading: groupListLoading,
    error: groupListError,
  } = useApiData<Array<Group>>(
    useCallback(
      ({ getData }) => {
        const queryStringParams: Record<string, string | number> = {
          page: params.page,
          page_size: params.pageSize,
          ordering: `${
            params.direction === 'ASC' ? '' : '-'
          }${convertToSnakeCase(params.column)}`,
        };
        if (params.searchValue && params.searchValue !== '') {
          queryStringParams.search = params.searchValue;
        }
        const queryString = qs.stringify(queryStringParams);
        return getData(`/api/v1/categories/r1/?${queryString}`);
      },
      [
        params.pageSize,
        params.page,
        params.column,
        params.direction,
        params.searchValue,
      ],
    ),
  );

  if (groupListLoading || !groupList) {
    return <LoaderBlock />;
  }

  if (groupListError) {
    return <FallbackErrorComponent error={groupListError} />;
  }

  const onChangeSearchValue = (searchValue: string) => {
    setParams({
      ...params,
      searchValue,
    });
  };

  /* Filter existing group from the list */
  const filteredGroupList = groupList.filter(
    (group) => group.id !== Number(groupId),
  );
  return (
    <CybDataTable
      columns={[
        {
          name: <Translation id="label_groupName" />,
          selector: (row) => row.displayName,
          cell: (row) => <Text>{row.displayName}</Text>,
          sortField: 'displayName',
          sortable: true,
        },
        {
          name: <Translation id="label_numberOfPeople" />,
          selector: (row) => row.usersCount,
          cell: (row) => <Text>{row.usersCount}</Text>,
          sortField: 'usersCount',
          sortable: true,
        },
      ]}
      defaultSortAsc={params.direction === 'ASC'}
      defaultSortFieldId={params.column}
      onSort={(column) => {
        setParams({
          ...params,
          column: column.sortField,
          direction: params.direction === 'ASC' ? 'DESC' : 'ASC',
        });
      }}
      search={{
        value: params.searchValue,
        onSubmit: onChangeSearchValue,
        placeholder: intl.formatMessage({
          id: 'page.groupManagement.subgroup.add.existing.search.placeholder',
        }),
      }}
      paginationServer
      paginationPerPage={params.pageSize}
      onChangeRowsPerPage={(newPerPage) =>
        setParams({ ...params, pageSize: newPerPage })
      }
      selectableRows={true}
      onSelectedRowsChange={(selected) => {
        const { selectedRows } = selected;
        if (selectedRows.length > 0) {
          const selectedRow = selectedRows[0];
          const isSelected = selectedGroups.find(
            (group) => group.id === selectedRow.id,
          );
          if (!isSelected) {
            setSelectedGroups([...selectedGroups, selectedRow]);
          } else {
            setSelectedGroups(
              selectedGroups.filter((group) => group.id !== selectedRow.id),
            );
          }
        } else {
          setSelectedGroups([]);
        }
      }}
      onChangePage={(page) => setParams({ ...params, page: page })}
      data={filteredGroupList}
    />
  );
}
