import styled from 'styled-components';

import { unit } from 'theme';

export const GroupsItemContainer = styled.span<{ $level: number }>`
  svg {
    transform: scaleX(-1);
    margin-left: ${({ $level }) => unit($level * 2)};
    margin-right: 4px;
  }
`;
