import { useApiPost } from 'api';
import { useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import {
  selectDelegatedAccessClientId,
  selectUserInfo,
} from 'store/features/mainSlice';

import { Event, LogAuditEventProps } from './eventLog.types';

export const useLogAuditEvent = () => {
  const userInfo = useAppSelector(selectUserInfo);
  const [postEvent] = useApiPost('/api/v1/audit-events/r1/');
  const useCreateFrontendEventLogs = useFeatureFlag(
    'use-create-frontend-event-logs',
  );
  const delegatedAccessClientId = useAppSelector(selectDelegatedAccessClientId);

  const logAuditEvent = ({ event_type, event_details }: LogAuditEventProps) => {
    const event: Event = {
      event_type: event_type,
      target_id: delegatedAccessClientId || userInfo.client,
      target_content_type: 'client',
      event_details: {
        ...event_details,
        report_page_url: window.location.href,
      },
    };
    if (useCreateFrontendEventLogs) {
      postEvent(event);
    }
  };

  return logAuditEvent;
};
