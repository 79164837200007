import React from 'react';

import { Toggle as CarbonToggle } from '@carbon/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import Translation from 'components/Content/Translation/Translation';
import { MoreLink } from 'components/Links';
import { unit } from 'theme';

export const ContentContainer = styled.p``;
export const FieldContainer = styled.div`
  margin: ${unit(2)} 0;
`;
export const SubGroup = styled.div<{ depth: number }>`
  padding-left: ${({ depth }) => unit(depth * 2)};
`;

export const SubGroupLink = styled(MoreLink)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.m};
`;
const IconContainer = styled.div`
  svg {
    margin-right: ${unit(1)};
    color: ${({ theme }) => theme.colorPalette.ui.navy[500]};
  }

  padding-right: ${unit(1)};
`;
export const ManagedByADSyncOrSSO = ({
  translation,
}: {
  translation: string;
}) => (
  <IconContainer>
    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
    <Translation id={translation} />
  </IconContainer>
);
export const InheritedByParent = () => (
  <IconContainer>
    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
    <Translation id="label_inheritedByParent" />
  </IconContainer>
);

export const GroupOverviewContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
export const GroupNameContainer = styled.div`
  width: 100%;
`;
export const StatsContainer = styled.div`
  display: flex;
  flex-wrap: unset;
  justify-content: space-between;
  margin-left: auto;
  width: 100%;
  align-items: center;
  padding-right: 7rem;

  @media only screen and (max-width: 500px) {
    padding: 1rem 0rem;
  }
`;
const NumberValue = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${unit(1)};

  span {
    font-size: 2.25rem;
    font-weight: 600;
    margin-right: ${unit(1)};
  }

  svg {
    font-size: 2.25rem;
    color: ${({ theme }) => theme.colorPalette.grey[100]};
  }
`;
export const Toggle = styled(CarbonToggle)`
  .cds--toggle__text--off,
  .cds--toggle__text--on {
    min-width: 200px;
  }
`;

export const PeopleTablesContainer = styled.div<{
  managedByADSyncOrSSO: boolean;
}>`
  display: flex;

  .people-table {
    width: ${({ managedByADSyncOrSSO }) =>
      managedByADSyncOrSSO ? '100%' : '50%'};

    &:first-of-type {
      margin-right: ${unit(1)};
    }

    &:last-of-type {
      margin-left: ${unit(1)};
    }
  }
`;
export const NumberWithIcon = ({ value, icon, label }) => (
  <div>
    <NumberValue>
      <span>{value}</span>
      <FontAwesomeIcon icon={icon} />
    </NumberValue>
    <span>{label}</span>
  </div>
);
export const PeopleHeading = styled.h4`
  margin-top: ${unit(1)};
  font-weight: 600;
`;
export const NoPeople = styled.div`
  text-align: center;
  margin: ${unit(8)} 0;
`;
export const RootGroupViewMore = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  a {
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToggleContainer = styled.div`
  min-width: 200px;
`;

export const FilterContainer = styled.div`
  width: 30%;
`;

export const TableSectionHeaderContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    gap: 1rem;
  }
`;

export const TitlesSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.5rem;

  @media only screen and (max-width: 800px) {
    flex-basis: 100%;
  }
`;

export const FilterGroupSection = styled.div`
  width: 30%;
`;

export const GroupTypeOverviewTitle = styled.span`
  color: ${({ theme }) => theme.colorPalette.grey[500]};
`;

export const AddExistingGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const LearningCampaignsRedirect = styled.div`
  text-align: center;
  padding: ${unit(4)};
  p {
    margin: ${unit(2)} 0;
  }
`;

export const GroupManagementTableContainer = styled.div`
  .view-more-row {
    input {
      display: none;
    }
  }
`;

export const DropdownContainer = styled.div`
  flex-grow: 1;
`;
