import { createGlobalStyle } from 'styled-components';

import { FILTERS_WIDTH } from '../components/Filters/PageFilters/FiltersSidePanel/FiltersSidePanel.styles';

const INTERCOM_DEFAULT_POSITION = 20;

const GlobalStyle = createGlobalStyle`

body {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  background-color: ${({ theme }) => theme.componentColors.bodyBg};
  &.report {
    background-color: #f5faf8;
  }
  color: ${({ theme }) => theme.componentColors.bodyTextColor};
}

#react-root {
  height: 100%;
}

.svg-black-text {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.875rem;
  fill: #000;
}

br {
  content: ' ';
  height: 24px;
  display: block;
}

i {
  font-style: italic;
}

a {
  color: ${({ theme }) => theme.componentColors.bodyTextLinkColor};
}

b,
strong {
  font-weight: 600;
}

.intercom-lightweight-app-launcher {
  background: #428f8a !important;
  right: ${INTERCOM_DEFAULT_POSITION}px;
  &.filters-open {
    right: ${FILTERS_WIDTH + INTERCOM_DEFAULT_POSITION}px;
    transition: right 0.1s ease-in-out;
  }
}
  `;

export default GlobalStyle;
