import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Add } from '@carbon/icons-react';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useApiFetcher } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { FallbackErrorComponent, withErrorBoundary } from 'components/Error';
import CybDataTable, { useDataTableState } from 'components/Table/CybDataTable';
import { Text } from 'components/Text';
import { convertToSnakeCase } from 'utils';

import { GroupManagementTableContainer } from './components/GroupManagement.styles';
import GroupName from './components/GroupName';
import { TabOrder } from './components/GroupOverview';
import GroupType from './components/GroupType';
import { Group } from './GroupManagement.types';
import { RemoveModal } from './groupManagementTableComponents/RemoveModal';
import { DOTS, flatData } from './helpers';

export type GroupsTypes = 'all' | 'manual' | 'sso' | 'scim';

type GroupManagementProps = {
  countState?: ReturnType<typeof useState<number>>;
  groupTypeFilter?: GroupsTypes;
  button?: ReactElement;
};

export const GroupManagementTable = withErrorBoundary(
  ({ countState, button, groupTypeFilter }: GroupManagementProps) => {
    const [selectedGroups, setSelectedGroups] = React.useState<Array<Group>>(
      [],
    );
    const navigate = useNavigate();
    const intl = useIntl();
    const [params, setParams] = useDataTableState({
      column: 'displayName',
      direction: 'ASC',
    });
    const { page, pageSize, direction, column, searchValue } = params;
    const [groupsList, setGroupsList] = useState([]);
    const removeGroupState = useState({
      displayModal: false,
      groupsToRemove: [],
      success: false,
      error: false,
    });
    const [removeGroup, setRemoveGroup] = removeGroupState;
    const [getGroups, { data, loading, error }] = useApiFetcher();
    const { groupId } = useParams<'groupId'>();
    useEffect(() => {
      const filterByGroupType =
        groupTypeFilter && groupTypeFilter !== 'all'
          ? groupTypeFilter
          : undefined;

      const queryStringParams: Record<string, string | number> = {
        page,
        page_size: pageSize,
        ordering: `${direction === 'ASC' ? '' : '-'}${convertToSnakeCase(
          column,
        )}`,
      };

      if (filterByGroupType) {
        queryStringParams[convertToSnakeCase('creationMethod')] =
          filterByGroupType;
      }

      if (searchValue && searchValue !== '') {
        queryStringParams.search = searchValue;
      }

      if (groupId && groupId !== 'root') {
        queryStringParams.parent = groupId;
      }

      queryStringParams.paginate = 'true';
      queryStringParams[convertToSnakeCase('numChildren')] = 3;

      const queryString = qs.stringify(queryStringParams);
      const url = `/api/v1/categories/r1?${queryString}`;
      getGroups('GET', url);
    }, [
      searchValue,
      getGroups,
      page,
      pageSize,
      groupTypeFilter,
      direction,
      groupId,
      column,
    ]);

    useEffect(() => {
      if (removeGroup.success) {
        getGroups(
          'GET',
          '/api/v1/categories/r1/?fields=id,display_name,children',
        );
      }
    }, [removeGroup.success, getGroups]);

    useEffect(() => {
      if (data) {
        setGroupsList(data.results || data);
        if (countState) {
          const [_, setCount] = countState;
          setCount(data.length);
        }
      }
    }, [data, countState]);
    const flatGroupsObject = useMemo(
      () =>
        groupsList?.reduce(
          (groupObject, group) => {
            groupObject = {
              ...groupObject,
              [group.id]: group,
            };
            if (group.children && group.children.length > 0) {
              group.children.forEach((child) => {
                groupObject = {
                  ...groupObject,
                  [child.id]: child,
                };
              });
            }
            return groupObject;
          },
          {} as Record<string, Group>,
        ),
      [groupsList],
    );

    const flatGroups = useMemo(
      () => flatData(groupsList, flatGroupsObject),
      [groupsList, flatGroupsObject],
    );
    const removeAction = {
      id: 'remove-group',
      label: intl.formatMessage(
        { id: 'label_removeGroup' },
        {
          rowsSelected: selectedGroups.length,
        },
      ),
      onClick: () => {
        const selectedIds = selectedGroups.map((row) => row.id);
        setRemoveGroup({
          groupsToRemove: selectedIds,
          displayModal: true,
          success: false,
          error: false,
        });
      },
    };
    const navigateToCreatePage = () => {
      const params = searchValue.length > 0 ? `?search=${searchValue}` : '';
      navigate(`/settings/group-management/create${params}`);
    };

    const onChangeSearchValue = (searchValue: string) => {
      setParams({
        ...params,
        searchValue,
      });
    };

    const tableColums = [
      {
        id: 'displayName',
        name: <Translation id="label_groupName" />,
        selector: (row) => row.displayName,
        cell: (row) => (
          <GroupName
            group={row}
            isChild={row.parentId !== null && row.parentId !== Number(groupId)}
          />
        ),
        sortField: 'displayName',
        sortable: true,
      },
      {
        id: 'usersCount',
        name: <Translation id="label_numberOfPeople" />,
        selector: (row) => row.usersCount,
        cell: (row) => {
          if (row.usersCount === null) {
            return <div />;
          }
          return <Text>{row.usersCount}</Text>;
        },
        sortField: 'usersCount',
        sortable: true,
      },
      {
        id: 'creationMethod',
        name: <Translation id="page.groupManagement.table.header.groupType" />,
        selector: (row) => row.creationMethod,
        cell: (row) => <GroupType group={row} />,
        sortField: 'creationMethod',
        sortable: true,
      },
      {
        id: 'viewGroup',
        name: <Translation id="label_viewGroup" />,
        cell: (row) => {
          if (row.usersCount === null) {
            return <div />;
          }
          return (
            <Link
              to={`/settings/group-management/${row.id}?selected=${TabOrder.Modules}`}
            >
              <Translation id="label_viewGroup" />
            </Link>
          );
        },
      },
    ];

    if (error) {
      return <FallbackErrorComponent error={error} />;
    }

    return (
      <GroupManagementTableContainer>
        <RemoveModal
          removeGroupState={removeGroupState}
          groupsList={groupsList}
        />
        <CybDataTable
          loading={loading}
          columns={tableColums}
          data={flatGroups}
          defaultSortAsc={params.direction === 'ASC'}
          defaultSortFieldId={params.column}
          onSort={(column) => {
            setParams({
              ...params,
              column: column.sortField,
              direction: params.direction === 'ASC' ? 'DESC' : 'ASC',
            });
          }}
          search={{
            value: params.searchValue,
            onSubmit: onChangeSearchValue,
            placeholder: intl.formatMessage({
              id: 'page.groupManagement.subgroup.add.existing.search.placeholder',
            }),
          }}
          paginationServer
          paginationTotalRows={data?.count || 0}
          paginationPerPage={params.pageSize}
          onChangeRowsPerPage={(newPerPage) =>
            setParams({ ...params, pageSize: newPerPage })
          }
          onChangePage={(page) => setParams({ ...params, page })}
          selectableRows
          onSelectedRowsChange={(selected) => {
            setSelectedGroups(selected.selectedRows);
          }}
          clearSelectedRows={removeGroup.success}
          actions={
            button
              ? button
              : [
                  {
                    renderIcon: Add,
                    label: 'label_addAGroup',
                    onClick: navigateToCreatePage,
                    id: 'add-group-button',
                  },
                ]
          }
          batchActions={[
            {
              ...removeAction,
              buttonType: 'danger',
              type: 'button',
            },
          ]}
          selectableRowDisabled={(row) => row.displayName.includes(DOTS)}
          conditionalRowStyles={[
            {
              when: (row) => row.displayName.includes(DOTS),
              classNames: ['view-more-row'],
            },
          ]}
        />
      </GroupManagementTableContainer>
    );
  },
);
