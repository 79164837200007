import React from 'react';

import { Subdirectory } from '@carbon/icons-react';

import { GroupItem } from '../../Dropdowns.types';

import { GroupsItemContainer } from './GroupsDropdown.styles';

type GroupsItemProps = Pick<GroupItem, 'label' | 'path'> & {
  searchActive?: boolean;
};

const GroupsItem = ({ label, path, searchActive }: GroupsItemProps) =>
  searchActive ? (
    <span title={label}>{path.join(' > ')}</span>
  ) : (
    <GroupsItemContainer title={label} $level={path.length - 1}>
      {path.length > 1 && <Subdirectory />}
      {label}
    </GroupsItemContainer>
  );

export default GroupsItem;
