import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import shallowequal from 'shallowequal';

import ApiDataContext from './ApiDataContext';

type Options = {
  reduxAction?: (payload: unknown) => AnyAction;
};
const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_START': {
      return { ...INITIAL_STATE, data: state.data, loading: true };
    }

    case 'REQUEST_SUCCESS': {
      return {
        data: action.data,
        error: null,
        loading: false,
      };
    }

    case 'REQUEST_ERROR': {
      return {
        data: null,
        error: action.error,
        loading: false,
      };
    }

    default:
      // TODO
      throw new Error();
  }
}

export default function useApiMutation(
  method: string,
  endpoint: string,
  _options: Options = {
    reduxAction: null,
  },
) {
  // Because the options object is created inside a render method it will
  // change on every render. We need to wrap it in state and then only change it
  // if the values inside the options object actually changes.
  const [options, setOptions] = useState(_options);
  useEffect(() => {
    if (!shallowequal(options, _options)) {
      setOptions(_options);
    }
  }, [options, _options]);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const apiData = useContext(ApiDataContext);
  const applyMutation = useCallback(
    (params) => {
      dispatch({
        type: 'REQUEST_START',
      });
      return apiData
        .fetch(method, endpoint, params, options)
        .then((data) => {
          dispatch({
            type: 'REQUEST_SUCCESS',
            data,
          });
          return data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          dispatch({
            type: 'REQUEST_ERROR',
            error,
          });
          throw error;
        });
    },
    [method, endpoint, apiData, options],
  );
  return [applyMutation, state] as [typeof applyMutation, typeof state];
} // Create partial helper functions

export const useApiGet = useApiMutation.bind(null, 'GET');
export const useApiPost = useApiMutation.bind(null, 'POST');
export const useApiDelete = useApiMutation.bind(null, 'DELETE');
export const useApiPut = useApiMutation.bind(null, 'PUT');
export const useApiPatch = useApiMutation.bind(null, 'PATCH');
