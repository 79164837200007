import React from 'react';

import { useGetSymposiumColours } from 'components/Subscription/getColors';
import { useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import { selectDelegatedAccessClientId } from 'store/features/mainSlice';
import { SymposiumLevels } from 'store/features/mainSliceTypes';

import SubscriptionTitle from '../SubscriptionTitle/SubscriptionTitle';

import { DemoContainer } from './SubscriptionBanner.styles';

type DemoBannerProps = {
  level: SymposiumLevels;
};

export const SubscriptionBanner = ({ level }: DemoBannerProps) => {
  const delegatedAccessClientId = useAppSelector(selectDelegatedAccessClientId);
  const isDelegatedAccessFlagEnabled = useFeatureFlag(
    'use-delegated-access-header',
  );
  const hasDelegatedAccess =
    !!delegatedAccessClientId && isDelegatedAccessFlagEnabled;

  const symposiumColours = useGetSymposiumColours(level);
  return (
    <DemoContainer
      {...symposiumColours}
      $hasDelegatedAccessBanner={hasDelegatedAccess}
    >
      <SubscriptionTitle level={level} />
    </DemoContainer>
  );
};

export default SubscriptionBanner;
