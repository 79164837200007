import React, { FC, useState } from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

import { InputText } from 'components/Form/Inputs/TextInput/TextInput';

import { SearchContainer } from '../CybDataTable.styles';

export interface SearchProps {
  onSubmit: (searchValue: string) => void;
  onChange?: (searchValue: string) => void;
  value?: string;
  placeholder?: string;
}

export const SearchInput: FC<SearchProps> = ({
  onChange,
  onSubmit,
  value = '',
  placeholder,
}) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(value);
  return (
    <SearchContainer data-testid="search-input">
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <InputText
        id="data-table-search"
        data-testid="data-table-search"
        aria-label={intl.formatMessage({
          id: placeholder || 'component.dataTable.searchInput',
        })}
        placeholder={intl.formatMessage({
          id: placeholder || 'component.dataTable.searchInput',
        })}
        value={searchValue}
        onChangeHandler={(newValue: string) => {
          /* if custom onChange is passed then pass the value to that method,
          otherwise update the local component state with the new value */
          if (onChange) {
            onChange(newValue);
          } else {
            setSearchValue(newValue);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            /* if custom onChange is passed then also use the value passed,
            otherwise use the value stored in the local component state */
            e.preventDefault();
            e.stopPropagation();
            onSubmit(onChange ? value : searchValue);
          }
        }}
      />
      {searchValue && (
        <button
          data-testid="clear-data-table-search"
          type="button"
          onClick={() => {
            /* Clear search value */
            if (onChange) {
              onChange('');
            } else {
              setSearchValue('');
            }
            onSubmit('');
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}
    </SearchContainer>
  );
};

export default SearchInput;
