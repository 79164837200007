import React, { useState } from 'react';

import { ArrowDown } from '@carbon/icons-react';
import { OverlayProvider } from 'react-aria';
import { useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';

import { PopoverButton } from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { InlineNotification } from 'components/Notifications';
import { Heading } from 'components/Text';

import { GroupManagementTable } from '../GroupManagementTable';

import { LinkList, LinkListItem, SubgroupLink } from './SubgroupsTab.styles';

export default function SubgroupsTab() {
  const countState = useState<number>(0);
  const intl = useIntl();
  const [groupsCount, _] = countState;
  const { groupId } = useParams<'groupId'>();
  const [urlParams] = useSearchParams();
  const successMessage = urlParams.get('successMessage');
  const button = (
    <PopoverButton
      id="add-subgroup"
      label="page.groupManagement.subgroup.add.subgroup"
      renderIcon={ArrowDown}
      data-testid="add-sub-group-button"
      aria-label={intl.formatMessage({
        id: 'page.groupManagement.subgroup.addSubGroupButton.ariaLabel',
      })}
    >
      <LinkList>
        <LinkListItem>
          <SubgroupLink
            data-testid="add-sub-group-link"
            to={`/settings/group-management/${groupId}/subgroups/create`}
          >
            <Translation id="page.groupManagement.subgroup.add.subgroup" />
          </SubgroupLink>
        </LinkListItem>
        <LinkListItem>
          <SubgroupLink
            to={`/settings/group-management/${groupId}/add/existing/groups`}
            data-testid="add-existing-groups-link"
          >
            <Translation id="page.groupManagement.subgroup.add.existing.groups" />
          </SubgroupLink>
        </LinkListItem>
      </LinkList>
    </PopoverButton>
  );

  return (
    <OverlayProvider>
      <Heading variant="h4" as="h3">
        {groupsCount} <Translation id="page.groupManagement.subgroups.header" />
      </Heading>
      {successMessage && (
        <InlineNotification
          type="success"
          titleLabel={intl.formatMessage({
            id: 'page.createNotification.successNotification.title',
          })}
          subtitleLabel={intl.formatMessage({
            id: 'page.groupManagement.subgroup.add.existing.submit.success',
          })}
        />
      )}
      <GroupManagementTable countState={countState} button={button} />
    </OverlayProvider>
  );
}
