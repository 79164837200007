import React, { ReactNode, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { useAppSelector } from 'store/createStore';

import EditTranslation from './components/EditTranslation';
import ShowTranslation from './components/ShowLabel';
import { BreakLine } from './Translation.styles';

const richTextFormatters = {
  br: <BreakLine />,
  b: (...str) => <strong>{str}</strong>,
  em: (...str) => <em>{str}</em>,
  i: (...str) => <i>{str}</i>,
};

export type TranslationProps = Pick<
  FormattedMessageProps,
  'id' | 'tagName' | 'defaultMessage' | 'children'
> & {
  values?: Record<string, any>;
};

const Translation = ({
  id,
  values,
  tagName,
  defaultMessage,
  children,
}: TranslationProps) => {
  const [showLabel, setShowLabel] = useState(false);
  const [showEditTranslation, setShowEditTranslation] = useState(false);

  const { editableLabelsEnabled } = useAppSelector(
    (state) => state.userSettings,
  );
  const ref = useRef<HTMLDivElement>(null);

  const formattedMessage = (
    <FormattedMessage
      id={id || 'unknown-ID'}
      values={{
        ...richTextFormatters,
        ...values,
      }}
      tagName={tagName}
      defaultMessage={defaultMessage}
    >
      {children}
    </FormattedMessage>
  );

  if (editableLabelsEnabled) {
    return (
      <span
        ref={ref}
        onMouseEnter={() => setShowLabel(true)}
        onMouseLeave={() => setShowLabel(false)}
      >
        {formattedMessage}
        {showLabel &&
          (ReactDOM.createPortal(
            <ShowTranslation
              id={id}
              rootElement={ref.current}
              showEditTranslation={() => setShowEditTranslation(true)}
            />,
            document.getElementById('react-root') || document.body,
          ) as ReactNode)}
        {showEditTranslation && (
          <EditTranslation
            id={id}
            closeModal={() => {
              setShowEditTranslation(false);
              setShowLabel(false);
            }}
          />
        )}
      </span>
    );
  }

  return formattedMessage;
};

export default Translation;
