import styled, { DefaultTheme } from 'styled-components';

import { SymposiumLevels } from 'store/features/mainSliceTypes';
import { unit } from 'theme';

export const getThemeColorForLevel = (
  level: SymposiumLevels,
  theme: DefaultTheme,
) => {
  switch (level) {
    case 'GUIDE':
    case 'GUIDE+':
      return theme.colorPalette.ui.purple[600];
    case 'PHISH':
    case 'PHISH+':
      return theme.colorPalette.ui.cyan[700];
    case 'RESPOND':
    case 'RESPOND+':
      return theme.colorPalette.ui.green[800];
    default:
      return theme.colorPalette.ui.orange[500];
  }
};
export const CallOutContainer = styled.div<{ level: SymposiumLevels }>`
  padding: 1rem;
  border-left: 4px solid
    ${({ level, theme }) => getThemeColorForLevel(level, theme)};
  max-width: 650px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 2rem 0;
  align-items: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${unit(0.5)};
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colorPalette.secondary[500]};
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${unit(0.5)};
`;

// TODO - remove this when we have the insights icon - https://cybsafe.atlassian.net/browse/PLAT-1182
export const TempIconPlaceholder = styled.div`
  height: 100px;
  width: 100px;
`;
