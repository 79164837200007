import React, { useMemo, useState } from 'react';

import { Star, StarFilled } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useApiData, useApiDelete } from 'api';
import { useFeatureFlag } from 'feature-flags';
import CreateLinkModal from 'pages/Reports/ReportLibrary/AllReports/Components/CreateLinkModal';
import DeleteReportFavouriteModal from 'pages/Reports/ReportLibrary/AllReports/Components/DeleteReportFavouriteModal';

import { FavouriteStarWrapper } from './ReportFavouriteStar.styles';

const ReportFavouriteStar = () => {
  const intl = useIntl();
  const location = useLocation();
  const useReportLibrary = useFeatureFlag('use-report-library');
  const [displayCreateLinkModal, setDisplayCreateLinkModal] = useState(false);
  const [displayDeleteFavouriteModal, setDisplayDeleteFavouriteModal] =
    useState(false);

  const { pathname, search } = location;
  const combinedPath = `${pathname}${search}`;

  const { data, refetch } = useApiData(
    `/api/v1/reports/report-favourites/r1/is-favourite/?pathname=${encodeURIComponent(combinedPath)}`,
  );
  const isFavourite = !!data?.favouriteId;
  const [deleteReportLink] = useApiDelete(
    `/api/v1/reports/report-favourites/r1/${data?.favouriteId}/`,
  );

  const clickHandler = () => {
    if (isFavourite) {
      setDisplayDeleteFavouriteModal(true);
    } else {
      setDisplayCreateLinkModal(true);
    }
  };

  const tooltipLabel = useMemo(
    () =>
      isFavourite
        ? 'page.reports.reportFavouriteStar.unfavourite'
        : 'page.reports.allReports.addToFavourites',
    [isFavourite],
  );

  if (!useReportLibrary || !data) {
    return null;
  }
  return (
    <>
      <CreateLinkModal
        open={displayCreateLinkModal}
        closeModal={() => setDisplayCreateLinkModal(false)}
        refetch={refetch}
        initialURLValue={window.location.href}
        addingFavourite
      />
      <DeleteReportFavouriteModal
        open={displayDeleteFavouriteModal}
        onRequestClose={() => setDisplayDeleteFavouriteModal(false)}
        onRequestSubmit={async () => {
          await deleteReportLink();
          refetch();
          setDisplayDeleteFavouriteModal(false);
        }}
      />
      {data && (
        <FavouriteStarWrapper>
          <Tooltip
            align="top"
            label={intl.formatMessage({
              id: tooltipLabel,
            })}
          >
            {isFavourite ? (
              <StarFilled
                data-testid="favourited-page-icon"
                size={20}
                onClick={clickHandler}
              />
            ) : (
              <Star
                data-testid="not-favourited-page-icon"
                size={20}
                onClick={clickHandler}
              />
            )}
          </Tooltip>
        </FavouriteStarWrapper>
      )}
    </>
  );
};

export default ReportFavouriteStar;
